export const throttleFunc = <T extends unknown[], R = void>(
  cb: (...param: T) => R,
  time: number = 200
): ((...param: T) => R | void) => {
  let ticking = false;

  return (...param: T): R | void => {
    if (!ticking) {
      ticking = true;
      setTimeout(() => {
        ticking = false;
      }, time);
      return cb(...param);
    }
  };
};
