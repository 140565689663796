import { EventUI } from "cf-types";
import { UPDATE_BASKET_ITEMS } from "../types";

type Action = {
  type: string;
  payload: Array<{
    item: EventUI;
    quantity: number;
  }>;
};

export const initialState = {
  items: [],
};

const BasketReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_BASKET_ITEMS:
      return { ...state, items: action.payload };
    default:
      return { ...state };
  }
};

export default BasketReducer;
