import { formUtils } from "src/utils";
import { CheckoutActions, CheckoutPayload, CheckoutState } from "../actions";
import { DeliveryAddressFields, PaymentFields } from "../types";

type Action = {
  type: string;
  payload: CheckoutPayload;
};

export const initialState: CheckoutState = {
  billing: {
    billingAddress: {
      error: false,
      required: true,
      value: "",
    },
    cardholderName: {
      error: false,
      required: true,
      validator: formUtils.isValidName,
      value: "",
    },
    cvv: { error: false, required: true, value: null },
    expirationDate: { error: false, required: true, value: null },
    number: { error: false, required: true, value: null },
    postalCode: { error: false, required: true, value: null },
    state: {
      error: false,
      required: false,
      validator: formUtils.isValidName,
      value: "",
    },
    townCity: {
      error: false,
      required: true,
      validator: formUtils.isValidName,
      value: "",
    },
  },
  delivery: {
    address: {
      error: false,
      required: true,
      value: "",
    },
    recipientName: {
      error: false,
      required: true,
      validator: formUtils.isValidName,
      value: "",
    },
    state: {
      error: false,
      required: true,
      validator: formUtils.isValidName,
      value: "",
    },
    townCity: {
      error: false,
      required: true,
      validator: formUtils.isValidName,
      value: "",
    },
    zipcode: {
      error: false,
      required: true,
      value: "",
    },
  },
};

const CheckoutReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case CheckoutActions.BULK_UPDATE_BILLING: {
      const { fields } = payload;

      return {
        ...state,
        billing: {
          ...state.billing,
          ...fields,
        },
      };
    }
    case CheckoutActions.BULK_UPDATE_DELIVERY: {
      const { fields } = payload;

      return {
        ...state,
        delivery: {
          ...state.delivery,
          ...fields,
        },
      };
    }
    case CheckoutActions.UPDATE_BILLING_ADDRESS: {
      const { field, value } = payload;
      const paymentField = field as PaymentFields;
      const billingField = state.billing[paymentField];
      const validator = payload.validator || billingField.validator;

      return {
        ...state,
        billing: {
          ...state.billing,
          [paymentField]: {
            ...billingField,
            error: formUtils.getFieldError(
              { ...billingField, validator },
              value
            ),
            value,
          },
        },
      };
    }
    case CheckoutActions.UPDATE_DELIVERY_ADDRESS: {
      const { field, value } = payload;
      const deliveryAddressField = field as DeliveryAddressFields;
      const deliveryField = state.delivery[deliveryAddressField];

      return {
        ...state,
        delivery: {
          ...state.delivery,
          [deliveryAddressField]: {
            ...deliveryField,
            error: formUtils.getFieldError(deliveryField, value),
            value,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default CheckoutReducer;
