import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import { Colour, ImplementedStatus, TransitionStyles } from "../../../types";
import { RTGTransition } from "../transition/transition";
import type { Level, Notification, OnToast } from "./types";

const levelColourMap: { [k in Level]: Colour } = {
  error: Colour.ERROR,
  success: Colour.SUCCESS,
  warning: Colour.WARNING,
};

const defaultTransitions: TransitionStyles = {
  entering: { transform: "translateY(-100%)" },
  entered: { transform: "translateY(0)" },
  exiting: { transform: "translateY(-100%)" },
  exited: { transform: "translateY(-100%)" },
};

const animationDuration = 300;
const toastExpiration = 5000;

const renderToasts = (
  state: ImplementedStatus,
  toasts?: Notification[],
  className?: string
) => {
  return toasts?.map(({ body, id, level = "warning" }) => {
    return (
      <div
        key={id}
        data-qa={`${id}-Toast`}
        children={body}
        className={`${className}-toast`}
        style={{
          ...defaultTransitions[state],
          backgroundColor: levelColourMap[level],
        }}
      />
    );
  });
};

export interface Props {
  className?: string;
  duration?: number;
  expiration?: number;
}

const UnstyledToast = forwardRef<OnToast, Props>(
  (
    { className, duration = animationDuration, expiration = toastExpiration },
    ref
  ) => {
    const [toasts, setToasts] = useState<Notification[]>([]);
    const [render, setRender] = useState(false);
    const [timeout, setTimer] = useState<number | null>(null);
    const nodeRef = useRef<HTMLDivElement>(null);

    const onToast = (data: Notification[]) => {
      setToasts((state) => [...data, ...state]);
      setRender(true);
      typeof timeout === "number" && window.clearTimeout(timeout);
      setTimer(window.setTimeout(setRender, expiration, false));
    };

    useImperativeHandle(ref, () => ({ onToast }));

    const onExited = useCallback(() => {
      setToasts([]);
    }, []);

    return (
      <RTGTransition
        duration={duration}
        handlers={{ onExited }}
        inProp={render}
        nodeRef={nodeRef}
      >
        {(state: ImplementedStatus) => {
          return (
            <div
              className={className}
              ref={nodeRef}
              style={{ ...defaultTransitions[state] }}
            >
              {renderToasts(state, toasts, className)}
            </div>
          );
        }}
      </RTGTransition>
    );
  }
);

export const Toast = styled(UnstyledToast)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background-color: transparent;

  transform: translateY(-100%);
  transition: ${({ duration = animationDuration }) =>
    `all ${duration}ms cubic-bezier(0.77, 0, 0.175, 1)`};

  div[class$="toast"] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 64px;
    margin-bottom: 2px;
    padding: 20px;

    font-family: "Roboto";
    font-size: 32px;
    color: white;

    transform: translateY(-100%);
    transition: ${({ duration = animationDuration }) =>
      `all ${duration}ms cubic-bezier(0.77, 0, 0.175, 1)`};
  }
`;
