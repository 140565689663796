import styled from "styled-components";
import React from "react";

interface ApplePayProps {
  className?: string;
  onClick: any;
}

const unstyledApplePayButton: React.FC<ApplePayProps> = ({
  className,
  onClick,
}) => <div className={className} onClick={onClick} />;

export const ApplePayButton = styled(unstyledApplePayButton)`
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy; /* Use any supported button type. */
  -apple-pay-button-style: black;
  height: 50px;
  width: 345px;
  border-radius: 7pt;
  margin-bottom: -20px;
  cursor: pointer;
`;
