import React from "react";
import styled from "styled-components";
import { Error } from "../../error/error";
import { InputField } from "../../input-field/input-field";

interface Props {
  className?: string;
  disabled: boolean;
  error: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const UnstyledEmail: React.FC<Props> = ({
  className,
  disabled,
  error,
  onChange,
  value,
}: Props) => {
  return (
    <div className={className}>
      <label htmlFor={"email"}>Email</label>
      <InputField
        disabled={disabled}
        type={"email"}
        id={"email"}
        error={error}
        value={value}
        name={"email"}
        onChange={onChange}
      />
      {error && <Error>Enter a valid email address</Error>}
    </div>
  );
};

export const Email = styled(UnstyledEmail)`
  display: flex;
  flex-direction: ${({ disabled }) => (disabled ? "row" : "column")};

  label {
    display: flex;
    align-items: ${({ disabled }) => (disabled ? "center" : "unset")};
    margin-bottom: ${({ disabled }) => (disabled ? "" : "5px")};
    width: ${({ disabled }) => (disabled ? "70px" : "unset")};

    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  input {
    flex: ${({ disabled }) => (disabled ? "1" : "unset")};
    height: 44px;

    background-color: #fff;
  }
`;
