import { combineReducers } from "redux";
import AppReducer from "./app-reducer";
import AuthReducer from "./auth-reducer";
import BasketReducer from "./basket-reducer";
import BookingReducer from "./booking-reducer";
import CardReducer from "./card-reducer";
import CheckoutReducer from "./checkout-reducer";
import EventReducer from "./event-reducer";
import MarketReducer from "./market-reducer";
import ModalReducer from "./modal-reducer";
import NotificationReducer from "./notification-reducer";
import OptInReducer from "./opt-in-reducer";
import UserReducer from "./user-reducer";
import PageReducer from "./page-reducer";

const rootReducer = combineReducers({
  AppState: AppReducer,
  AuthData: AuthReducer,
  BasketItems: BasketReducer,
  Booking: BookingReducer,
  Card: CardReducer,
  Checkout: CheckoutReducer,
  EventData: EventReducer,
  Market: MarketReducer,
  Modal: ModalReducer,
  Notification: NotificationReducer,
  OptIn: OptInReducer,
  User: UserReducer,
  Page: PageReducer,
});

export default rootReducer;
