import { useRef } from "react";
import styled from "styled-components";
import { Divider } from "../../divider";
import { ImageComponent } from "../../image-component-product/image-component";
import { Item, RendererHOC } from "./renderer";
import { Summary } from "./summary";

export interface Props {
  className?: string;
  currency: string;
  discount?: string | undefined | 0;
  email: string;
  imageSrc: string | undefined;
  orders: Array<Item>;
  orderReference: number;
  title?: string;
  total: string;
}

const UnstyledOrderConfirmation: React.FC<Props> = ({
  className,
  currency,
  discount,
  email,
  imageSrc,
  orders = [],
  orderReference,
  title,
  total,
}) => {
  const OrderRenderer = useRef(RendererHOC(currency));

  const emailTag = <span className={`${className}-email`}>{email}</span>;
  return (
    <div className={className}>
      <h3>Thank you for your order!</h3>
      <span className={`${className}-subtitle`}>
        A confirmation email is on its way to {emailTag}
      </span>
      <Divider />
      <div className={`${className}-orders-container`}>
        <ImageComponent href={imageSrc} alt={title} />
        <div className={`${className}-orders`}>
          {orders.map((order, index) => {
            return (
              <OrderRenderer.current
                key={`order-renderer-${index}`}
                title={order.title}
                unit={order.unit}
                price={order.price}
              />
            );
          })}
        </div>
      </div>
      <Summary
        currency={currency}
        discount={discount}
        orderReference={orderReference}
        total={total}
      />
    </div>
  );
};

export const OrderConfirmation = styled(UnstyledOrderConfirmation)`
  box-sizing: border-box;

  width: 100%;
  padding: 30px;

  color: #1a1a1a;

  background-color: #ffffff;

  h3 {
    margin-top: 0;
    margin-bottom: 15px;

    font-family: "FranklinGothicLTPro-DmCm";
    font-size: 38px;
  }

  img {
    width: 120px;
    height: inherit;
  }

  span[class$="subtitle"] {
    display: block;
    margin-bottom: 30px;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.63;
  }

  span[class$="email"] {
    font-weight: bold;
  }

  hr {
    border-color: #d8d8d8;
  }

  div[class$="orders-container"] {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 92px;
  }

  div[class$="orders"] {
    flex: 1;
    overflow-y: auto;
  }
`;
