import { ContactFields } from "redux/types";
import { formUtils } from "src/utils";
import { User, UserActions, UserPayload } from "../actions";

type Action = {
  type: string;
  payload: UserPayload;
};

export const initialState: User = {
  email: {
    error: false,
    required: true,
    validator: formUtils.isValidEmail,
    value: "",
  },
  firstName: {
    error: false,
    required: true,
    validator: formUtils.isValidName,
    value: "",
  },
  lastName: {
    error: false,
    required: true,
    validator: formUtils.isValidName,
    value: "",
  },
  phone: {
    error: false,
    required: false,
    validator: formUtils.isValidPhone,
    value: "",
  },
};

const UserReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case UserActions.BULK_UPDATE: {
      const { fields } = payload;

      return {
        ...state,
        ...fields,
      };
    }
    case UserActions.UPDATE_USER: {
      const { field, value } = payload;
      const userField = state[field as ContactFields];

      return {
        ...state,
        [field as ContactFields]: {
          ...userField,
          error: formUtils.getFieldError(userField, value),
          value,
        },
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
