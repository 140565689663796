import React from "react";
import styled from "styled-components";

type FullScreenToggle = {
  className?: string;
  venueName: string;
  fullAddress: string;
};

const unStyledFullScreenToggle = ({
  className,
  venueName,
  fullAddress,
}: FullScreenToggle) => (
  <div
    onClick={() =>
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${venueName}, ${fullAddress}`
      )
    }
    className={className}
  />
);

export const FullScreenToggle = styled(unStyledFullScreenToggle)`
  height: 30px;
  width: 30px;
  position: absolute;
  top: 110px;
  right: 10px;
  z-index: 2;
  cursor: pointer;

  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiByeD0iNSIgZmlsbD0iI0ZDRkJGOSIvPgo8cGF0aCBkPSJNMjUuNjI1IDguNzVIMzEuMjVWMTQuMzc1IiBzdHJva2U9IiMxQTFBMUEiIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0zMS4yNSAyNS42MjVWMzEuMjVIMjUuNjI1IiBzdHJva2U9IiMxQTFBMUEiIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNC4zNzUgMzEuMjVIOC43NVYyNS42MjUiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTguNzUgMTQuMzc1VjguNzVIMTQuMzc1IiBzdHJva2U9IiMxQTFBMUEiIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
  background-size: cover;
`;
