import {
  Basket,
  Currency,
  CustomError,
  EventUI,
  Market,
  ThirdPartyOptIn,
  UserSessionResponse,
} from "cf-types";
import {
  Field,
  HostedFieldsHostedFieldsFieldName,
  OptIn,
  Token,
} from "../src/components/to-components";
import {
  BookingConfirmed,
  BookingStages,
  CheckoutState,
  Device,
  ModalPayload,
  NotificationPayload,
  User,
} from "./actions";

export const UPDATE_EVENT_DATA = "update_event_data";
export const UPDATE_BASKET_ITEMS = "update_basket_items";

export const USER_LOGIN_ERROR = "user_login_error";

export const USER_REGISTER = "user_register";

export const UPDATE_PAGE_DATA = "update_page_data";

export interface Store {
  AppState: {
    authModalOpen: boolean;
    device: Device;
    featureFlags: {
      guestCheckout: boolean;
    };
    isLoading: boolean;
  };
  AuthData: {
    access_token: string;
    api_key: string;
    customer: UserSessionResponse["customer"];
    loggedIn: boolean;
    loggedInError: boolean;
    registered: boolean;
    registeredOrigin: string;
  };
  Booking: {
    basket: Basket;
    confirmed: BookingConfirmed;
    error: CustomError;
    stage: BookingStages;
    thirdPartyDataShareConsent: boolean;
    thirdPartyOptIn: ThirdPartyOptIn;
  };
  BasketItems: {
    items: Array<{
      item: EventUI;
      quantity: number;
    }>;
  };
  Card: Token;
  Checkout: CheckoutState;
  EventData: {
    currencyCode: keyof typeof Currency;
    eventGroupID: number;
    imageSrc: string;
    purchaseType: string;
    shippable: boolean;
    termsText: string;
    title: string;
  };
  Market: {
    currentMarket: Market[];
    markets: Market[];
    selectedMarket: Market[];
  };
  Modal: ModalPayload;
  Notification: NotificationPayload;
  OptIn: {
    optInData: OptIn[];
  };
  Page: {
    pageName: string;
  };
  User: User;
}

export type ContactFields = "email" | "firstName" | "lastName" | "phone";

export type DeliveryAddressFields =
  | "address"
  | "recipientName"
  | "state"
  | "townCity"
  | "zipcode";

export type PaymentFields =
  | "billingAddress"
  | "cardholderName"
  | "state"
  | "townCity";

export type SecurePaymentFields = Extract<
  HostedFieldsHostedFieldsFieldName,
  "cvv" | "number" | "expirationDate" | "postalCode"
>;

export type FormFields<T> = {
  [k in keyof T]: Field<T[k]>;
};
