import React from "react";
import { Colour } from "../../../types";
import styled from "styled-components";
import { ProductTags } from "./types";

export interface Props {
  className?: string;
  tag: ProductTags;
}

const UnstyledTag: React.FC<Props> = ({ className, tag }: Props) => {
  return <span className={className}>{tag}</span>;
};

export const Tag = styled(UnstyledTag)`
  padding: 5px;

  background-color: ${({ tag }) =>
    tag === "SOLD OUT" ? Colour.ERROR : Colour.SUCCESS};
  color: #fff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
`;
