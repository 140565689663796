import React from "react";
import styled from "styled-components";
import { Error } from "../../error/error";
import { InputField } from "../../input-field/input-field";

interface Props {
  className?: string;
  error?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const UnstyledBillingAddress: React.FC<Props> = ({
  className,
  error,
  onChange,
  value,
}: Props) => {
  return (
    <div className={className}>
      <label htmlFor={"billing-address"}>Billing Address</label>
      <InputField
        error={error}
        id={"billing-address"}
        name={"billingAddress"}
        onChange={onChange}
        type={"text"}
        value={value}
      />
      {error && <Error>Enter the address</Error>}
    </div>
  );
};

export const BillingAddress = styled(UnstyledBillingAddress)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  label {
    margin-bottom: 4px;

    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.5;
  }

  input {
    height: 44px;

    background-color: #fff;
  }
`;
