import { RefObject, useEffect, useRef, useState } from "react";

export const useElementInView = <T extends HTMLElement>(
  options: IntersectionObserverInit = { threshold: 1.0 }
): [boolean, RefObject<T>] => {
  const elementRef = useRef<T>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onObserve = ([entry]: IntersectionObserverEntry[]) => {
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onObserve, options);
    elementRef.current && observer.observe(elementRef.current);

    return () => {
      elementRef.current && observer.unobserve(elementRef.current);
    };
  }, [elementRef, options]);

  return [isVisible, elementRef];
};
