export const YoutubeLogo = () => (
  <svg
    width={24}
    height={18}
    viewBox="0 0 24 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"yt"}</title>
    <path
      d="M0 13.5c0 1.25.438 2.313 1.313 3.188S3.25 18 4.5 18h15c1.25 0 2.313-.438 3.188-1.313S24 14.75 24 13.5v-9c0-1.25-.438-2.313-1.313-3.188S20.75 0 19.5 0h-15C3.25 0 2.187.438 1.312 1.313S0 3.25 0 4.5v9zm1.5 0v-9c0-.813.297-1.516.89-2.11.594-.593 1.297-.89 2.11-.89h15c.813 0 1.516.297 2.11.89.593.594.89 1.297.89 2.11v9c0 .813-.297 1.516-.89 2.11-.594.593-1.297.89-2.11.89h-15c-.813 0-1.516-.297-2.11-.89-.593-.594-.89-1.297-.89-2.11zM9 15l7.5-6L9 3v12z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
