export interface ReviewStarProps {
  className?: string;
  rating: number;
}

export const FullReviewStar = () => (
  <svg
    width={16}
    height={15}
    viewBox="0 0 32 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"FullStar"}</title>
    <path
      d="M15.772 23.217L6.024 30l3.44-11.367L0 11.46l11.873-.242L15.772 0l3.899 11.217 11.873.242-9.463 7.174L25.52 30z"
      fill="#D31A22"
      fillRule="evenodd"
    />
  </svg>
);

export const HalfReviewStar = () => (
  <svg
    width={16}
    height={15}
    viewBox="0 0 32 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"HalfStar"}</title>
    <g fillRule="nonzero" fill="none">
      <path
        fill="#D31A22"
        d="M15.772 23.217L6.024 30l3.44-11.367L0 11.46l11.873-.242L15.772 0z"
      />
      <path
        fill="#AAA"
        d="M15.728 23.217L25.476 30l-3.44-11.367L31.5 11.46l-11.873-.242L15.728 0z"
      />
    </g>
  </svg>
);

export const FullRating = ({ className, rating }: ReviewStarProps) => {
  const totalStarIcons = Array(Math.floor(rating)).fill(rating);
  const halfStar = rating % 1 !== 0;

  return (
    <div className={className}>
      {totalStarIcons.map((_, index) => (
        <FullReviewStar key={index} />
      ))}
      {halfStar && <HalfReviewStar />}
    </div>
  );
};
