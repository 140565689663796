import React from "react";
import { Action, BasketItem, Event } from "../../../types";
import { TicketBookingWidget } from "./ticket-booking-widget";
import { VoucherBookingWidget } from "./voucher-booking-widget";
import { OffsiteBookingWidget } from "./offsite-booking-widget";
import { WaitingListBookingWidget } from "./waiting-list-booking-widget";

export interface BookingWidgetProps {
  action: Action;
  actionLink?: string;
  ctaText?: string;
  locale?: string;
  previewOnsaleTime: string;
  purchaseType: string;
  events: Event[];
  currency: string;
  name: string;
  closeButtonCB?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onUpdateBasket: (data: BasketItem[]) => void;
  onChangeQuantity: (id: number, quantity: number) => void;
  termsUrl?: string;
  timeZone?: string;
}

export const BookingWidget = ({
  action,
  previewOnsaleTime,
  actionLink,
  ctaText,
  locale = "en-GB",
  name,
  purchaseType,
  events,
  currency,
  closeButtonCB = () => {},
  onClick,
  onUpdateBasket,
  onChangeQuantity = () => {},
  termsUrl,
  timeZone = "Europe/London",
}: BookingWidgetProps) => {
  const props = {
    action,
    currency,
    closeButtonCB,
    events,
    locale,
    name,
    onClick,
    onChangeQuantity,
    onUpdateBasket,
    previewOnsaleTime,
    termsUrl,
    timeZone,
  };

  if (action === "book" || action === "preview") {
    return purchaseType === "attendance" ? (
      <TicketBookingWidget {...props} />
    ) : (
      <VoucherBookingWidget {...props} />
    );
  }

  if (action === "waitlist") {
    return <WaitingListBookingWidget ctaText={ctaText} onClick={onClick} />;
  }

  return (
    <OffsiteBookingWidget
      onClick={onClick}
      purchaseLink={actionLink}
      bookingTitle={name}
      ctaText={ctaText}
    />
  );
};
