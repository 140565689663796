import { gql } from "@apollo/client";

export const GetCustomerDataQuery = gql`
  query GetCustomerDataQuery($api_key: String!) {
    getCustomerData(api_key: $api_key) {
      error {
        fields
        message
        name
        stack
        status
      }
      email
      first_name
      last_name
      phone_number
      setting_engagement_emails
    }
  }
`;
