import React from "react";
import styled from "styled-components";
import { Colour, Market } from "../../../types";
import { AnimatedChevron } from "../../../global/iconography/animated-chevron/animated-chevron";
import { List, ListItem } from "../list";
import { MarketRenderer } from "./market-renderer";

export type Props = {
  anchor: `${number}px`;
  className?: string;
  markets: Market[];
  onSelectMarket: (e: { value: Array<string> }) => void;
  portal?: HTMLElement | null;
  selectedMarket: string[];
};

const unstyledMarketDropdown: React.FC<Props> = ({
  anchor,
  className,
  markets = [],
  portal,
  onSelectMarket,
  selectedMarket,
}: Props) => {
  const [isMarketDropdownOpen, setIsMarketDropdownOpen] = React.useState(false);

  const handleMarketDropdown = () => {
    setIsMarketDropdownOpen((prev) => !prev);
  };

  const [marketName] = selectedMarket;

  return (
    <div className={className}>
      <span
        data-qa={"market-label"}
        className={`${className}-market-wrapper`}
        onClick={handleMarketDropdown}
      >
        <span className={`${className}-market`}>{marketName}</span>
        <AnimatedChevron
          rotation={isMarketDropdownOpen ? "180deg" : "0deg"}
          size={"small"}
        />
      </span>
      <List
        backgroundColor={Colour.WHITE}
        bottomSpacing={"12px"}
        id={"market-desktop"}
        onClose={handleMarketDropdown}
        onSelect={onSelectMarket}
        padding={"30px"}
        portal={portal}
        render={isMarketDropdownOpen}
        selected={selectedMarket}
        showArrow={true}
        tether={{
          left: anchor,
          top: "52px",
        }}
        width={"200px"}
      >
        {markets.map((market) => (
          <ListItem
            disableGutters
            divider={false}
            key={market.name}
            isSelected={marketName === market.name}
            uid={market.name}
          >
            <MarketRenderer>{market.name}</MarketRenderer>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export const MarketDropdown = styled(unstyledMarketDropdown)`
  span[class$="market-wrapper"] {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  span[class$="market"] {
    color: ${Colour.WHITE};
    font-family: "FranklinGothicLTPro-Dm", sans-serif;
    text-transform: uppercase;
  }
`;
