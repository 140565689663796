import styled from "styled-components";
import { FacebookLogo } from "../../../global/iconography/social-media/facebook";
import { InstagramLogo } from "../../../global/iconography/social-media/instagram";
import { PinterestLogo } from "../../../global/iconography/social-media/pinterest";
import { TwitterLogo } from "../../../global/iconography/social-media/twitter";
import { YoutubeLogo } from "../../../global/iconography/social-media/youtube";

export interface SharingSitesLinksProps {
  facebook?: string;
  instagram?: string;
  pinterest?: string;
  twitter?: string;
  youtube?: string;
}

export interface SharingButtonsProps {
  className?: string;
  shareLinks: SharingSitesLinksProps;
}

const SharingButtonsUnstyled = ({
  className,
  shareLinks,
}: SharingButtonsProps) => (
  <div className={className}>
    <p>Follow us</p>
    <div>
      <a href={shareLinks.facebook}>
        <FacebookLogo />
      </a>
      <a href={shareLinks.instagram}>
        <InstagramLogo />
      </a>
      <a href={shareLinks.pinterest}>
        <PinterestLogo />
      </a>
      <a href={shareLinks.twitter}>
        <TwitterLogo />
      </a>
      <a href={shareLinks.youtube}>
        <YoutubeLogo />
      </a>
    </div>
  </div>
);

export const SharingButtons = styled(SharingButtonsUnstyled)`
  display: block;
  margin-top: 30px;
  p {
    width: 69px;
    height: 24px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }
  a {
    margin-right: 30px;
  }

  @media (min-width: 768px) {
    margin-top: 0;
    p {
      margin-top: 0;
    }
  }
`;
