export interface CloseButtonProps {
  width?: number;
  height?: number;
  onClickCB?: any;
  darkMode?: boolean;
}

export const CloseButtonIcon = ({
  width = 23,
  height = 18,
  onClickCB,
  darkMode = true,
}: CloseButtonProps) => (
  <svg
    onClick={onClickCB}
    width={width}
    height={height}
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Atoms/Icons/Close"}</title>
    <path
      d="M2.121 0L0 2.121l5.303 5.303L0 12.728l2.121 2.121 5.304-5.303 5.303 5.303 2.121-2.121-5.303-5.304 5.303-5.303L12.728 0 7.425 5.303 2.121 0z"
      fill={darkMode ? "#FFF" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
