import {
  EventGroup,
  GoogleAnalytics,
  GoogleTagManager,
  Market,
  MetaPixel,
} from "cf-types";
import { BasketItem } from "src/components/to-components/types";
import { dimensionsFacade, slugify, getEventPriceValues } from "src/utils";

export const AWINtracker = {
  onSuccess: (data: { [key: string]: string }) => {
    window.AWIN = window?.AWIN ?? {
      Tracking: {
        run: () => {},
        Sale: {},
      },
    };

    // Reset Sale object
    window.AWIN.Tracking.Sale = {};

    window.AWIN.Tracking.Sale = data;
  },
  run: () => {
    window?.AWIN?.Tracking?.run();
  },
};

export const googleAnalytics = (
  eventParams: Gtag.CustomParams,
  eventName: Gtag.EventNames,
  event: GoogleAnalytics.GtagEvent = "event"
) => {
  window.gtag && window.gtag(event, eventName, eventParams);
};

export const gtmDataLayerPush = (
  data: GoogleTagManager.DataLayerParam,
  menu?: Market["categories"],
  router?: GoogleTagManager.Route
) => {
  const payload = router && menu ? dimensionsFacade(menu, router) : {};
  window.dataLayer &&
    window.dataLayer.push({
      ...data,
      payload: Object.assign(payload, data?.payload),
    });
};

export const metaTrack = (data: [MetaPixel.Event, MetaPixel.Payload?]) => {
  window.fbq && window.fbq("track", ...data);
};

export const permutiveEventData = (
  {
    location,
    name,
    purchaseType,
    category,
    overrideMetaTitle,
    events,
  }: EventGroup,
  eventGroupID: string
) => {
  const eventData = {
    name: name,
    eventId: eventGroupID,
    eventType: purchaseType,
    categorySlug: slugify(category),
    offerText: overrideMetaTitle,
  };

  const venueData = location?.venueAddress
    ? {
        venue: {
          name: location.venueName,
          address1: location.venueAddress,
          address2: location.postalCode,
          city: location.venueCity,
          country: location.venueCountry,
        },
      }
    : null;

  const eventPriceData = events?.length
    ? {
        eventPriceValues: getEventPriceValues(events),
      }
    : null;

  return {
    page: {
      event: {
        ...eventData,
        ...(venueData && venueData),
      },
    },
  };
};

export const permutivePrePaymentData = (
  { partnerId = null, category, title }: any,
  basket: BasketItem[],
  total: number
) => {
  const quantity = basket.reduce((acc, { quantity }) => (acc += quantity), 0);

  const products = basket.map(({ item, quantity }) => ({
    id: item.sellableItemId,
    price: item.discountPrice,
    quantity,
  }));

  const data = {
    basket: {
      partnerId: partnerId,
      category: category,
      name: title,
      uniqueTypes: basket.length,
      quantity,
      total,
    },
    products,
  };
  return { page: { ...data } };
};

export const permutivePostPaymentData = (
  eventData: any,
  basket: BasketItem[],
  total: number,
  promocodeDetails: any
) => {
  const prePaymentData = permutivePrePaymentData(eventData, basket, total);

  const postPaymentData = {
    discount: promocodeDetails ? promocodeDetails.discountAmount : 0,
    revenue: total,
    currency: eventData.currency,
  };

  return {
    page: {
      transaction: { ...prePaymentData.page.basket, ...postPaymentData },
      products: { ...prePaymentData.page.products },
    },
  };
};
