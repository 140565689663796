export const debounceFunc = <T extends unknown[], R = void>(
  cb: (...param: T) => R,
  timeout: number = 200
): ((...param: T) => R | void) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: T): R | void => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(cb, timeout, ...args);
  };
};
