import React from "react";
import styled from "styled-components";
import { device } from "../newsletter-signup-widget/device-size";
import parse from "html-react-parser";

type Props = {
  content: string;
  className?: string;
};

const unstyledHighlights: React.FC<Props> = ({
  content,
  className,
}): JSX.Element => {
  return <div className={className}>{parse(content)}</div>;
};

unstyledHighlights.defaultProps = {
  content: "<p id='notFound'><u> No Content found!</u> </p>",
};

export const Highlights = styled(unstyledHighlights)`
  ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  width: 100%;
   {
    font-size: 16px;
    line-height: 1.5;
    font-family: Roboto;
  }
  #notFound {
    font-family: Roboto;
    font-size: 16px;
  }
  h1 {
    font-size: 48px !important;
    font-family: FranklinGothicLTPro-DmCm;
  }
  h2 {
    font-size: 38px !important;
    font-family: FranklinGothicLTPro-DmCm;
  }
  h3 {
    font-size: 32px !important;
    font-family: FranklinGothicLTPro-DmCm;
  }
  h4 {
    font-size: 28px !important;
    font-family: FranklinGothicLTPro-DmCm;
    margin-bottom: 30px;
  }
  a {
    color: #d31a22 !important;
    &:hover {
      color: #bc161e !important;
    }
  }
  @media ${device.mobileL} {
    h1 {
      font-size: 38px !important;
    }
  }
`;

export default Highlights;
