import React from "react";
import styled from "styled-components";
import { CardIcon } from "../../../../global/iconography/card/card-icon";
import { Error } from "../../error/error";

interface Props {
  className?: string;
  error?: boolean;
}

const UnstyledCardNumber: React.FC<Props> = ({ className, error }: Props) => {
  return (
    <div className={className}>
      <label htmlFor={"number"}>Card Number</label>
      <span>
        <div id="number" /> <CardIcon />
      </span>
      {error && <Error>Enter a valid card number</Error>}
    </div>
  );
};

export const CardNumber = styled(UnstyledCardNumber)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  label {
    margin-bottom: 4px;

    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.5;
  }

  span {
    display: flex;
    justify-content: space-between;

    border-width: 1px;
    border-style: solid;
    border-color: ${({ error }) => (error ? "#d31a22" : "#aaa")};
  }

  #number {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    height: 44px;

    background-color: #fff;
  }

  span svg {
    margin: 14px 14px 14px 4px;
  }
`;
