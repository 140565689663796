import React from "react";
import styled from "styled-components";

export interface ProgressBarProps {
  className?: string;
  width: number;
}

const UnstyledProgressBar = ({ className, width }: ProgressBarProps) => (
  <div className={className}>
    <div
      className={`${className}-progress-bar`}
      style={{ width: `${width}%` }}
    />
  </div>
);

export const ProgressBar = styled(UnstyledProgressBar)`
  height: 2px;
  width: 189px;
  background-color: #d8d8d8;

  div[class$="-progress-bar"] {
    height: 100%;
    background-color: #7d7d7d;
  }
`;
