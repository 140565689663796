import { Analytics } from "cf-constants";
import { HttpStatusCode } from "cf-types";

const routePageTypeMap: { [k: string]: string } = {
  [HttpStatusCode.NOT_FOUND]: Analytics.PageType.NOT_FOUND,
  checkout: `/${Analytics.PageType.CHECKOUT}`,
  details: Analytics.PageType.DETAIL,
  orders: Analytics.PageType.TICKET,
  search: `${Analytics.PageType.SEARCH} pages`,
};

export const getPageType = (
  subMenu: string,
  market: string,
  cleanRoute: string[]
): Analytics.PageType | string => {
  const { length } = cleanRoute;
  const [firstRoute] = cleanRoute;

  if (!length || (length === 1 && firstRoute === market)) {
    return Analytics.PageType.HOME;
  }

  if (subMenu) {
    return `${Analytics.PageType.USE_CASE} pages`;
  }

  return (
    routePageTypeMap?.[firstRoute] ??
    routePageTypeMap?.[cleanRoute?.[1]] ??
    routePageTypeMap?.[cleanRoute?.[2]] ??
    Analytics.PageType.EVENT
  );
};
