export const PinterestLogo = () => (
  <svg
    width={15}
    height={19}
    viewBox="0 0 15 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"pi"}</title>
    <path
      d="M.007 6.42c.032-1.7.674-3.137 1.926-4.308C3.184.941 4.613.26 6.216.066c1.99-.224 3.755.12 5.295 1.035 1.54.915 2.439 2.255 2.696 4.02.32 2.181-.08 4.139-1.204 5.872-1.123 1.732-2.68 2.519-4.669 2.358a3.872 3.872 0 01-.746-.168c-.24-.08-.425-.16-.553-.241l-.578-.361-.577-.385c-.61 3.209-1.557 5.279-2.84 6.21-.193-.707-.225-1.605-.097-2.696.129-1.091.25-1.91.361-2.455.113-.546.305-1.316.578-2.31.273-.995.457-1.765.554-2.31-.578-.9-.594-1.974-.049-3.226.546-1.251 1.348-1.652 2.407-1.203.61.257.89.754.842 1.492-.048.738-.24 1.5-.577 2.286-.337.786-.482 1.525-.433 2.214.048.69.457 1.116 1.227 1.276.834.16 1.58-.08 2.238-.722.658-.642 1.115-1.436 1.372-2.383a8.025 8.025 0 00.217-2.984c-.113-1.043-.442-1.837-.987-2.382A4.27 4.27 0 007.9 1.727c-1.09-.08-2.094.12-3.008.602-.915.481-1.653 1.203-2.214 2.166a4.616 4.616 0 00-.602 3.08c.032.257.169.578.41.963.24.385.376.682.408.89.032.209-.08.618-.337 1.228C.761 10.27-.089 8.859.008 6.42z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
