import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { InputField } from "../../input-field/input-field";

interface Props {
  className?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  value: boolean;
}

const UnstyledOptInRenderer: React.FC<Props> = ({
  className,
  name,
  onChange,
  text,
  value,
}: Props) => {
  return (
    <div className={className}>
      <InputField
        checked={value}
        id={`${name}-opt-in-renderer`}
        name={name}
        onChange={onChange}
        type={"checkbox"}
      />
      <FormText>{parse(text)}</FormText>
    </div>
  );
};

const FormText = styled.span`
  margin-left: 10px;

  color: #4a4a4a;

  font-family: "Roboto";
  font-size: 14px;

  a {
    color: #d31a22;
    text-decoration: none;
  }
`;

export const OptInRenderer = styled(UnstyledOptInRenderer)`
  display: flex;
  align-items: center;

  input {
    margin: 0;

    border-radius: 2px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
`;
