import { gql } from "@apollo/client";

export const PostOutingQuery = gql`
  query PostOutingQuery($ticketData: String!) {
    postOuting(ticketData: $ticketData) {
      error {
        fields
        message
        name
        stack
        status
      }
      eventGroup {
        currencyCode
        name
      }
      _extra {
        purchaseId
      }
      ticketGroups {
        event {
          discountPrice
          ticketIncrement
          seatDescription
        }
        tickets {
          currencyCode
          price
          reference
        }
      }
      ticketCount
    }
  }
`;
