import { gql } from "@apollo/client";

export const PatchCustomerDataQuery = gql`
  query CustomerDataQuery($user: String!) {
    patchCustomerData(user: $user) {
      error {
        fields
        message
        name
        stack
        status
      }
      email
      first_name
      last_name
      phone_number
      setting_receive_newsletter_emails
    }
  }
`;
