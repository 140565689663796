import { UserSession } from "cf-types";
import { AuthActions } from "../actions";
import { USER_REGISTER, USER_LOGIN_ERROR } from "../types";

type Action = {
  type: string;
  payload?: UserSession & {
    loggedIn: boolean;
    loggedInError: boolean;
  };
};

export const initialState = {
  api_key: "",
  loggedIn: false,
  loggedInError: false,
  registered: false,
  registeredOrigin: "",
  customer: {},
};

const AuthReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case AuthActions.LOGIN_USER:
      return { ...state, ...payload, loggedIn: true };
    case AuthActions.LOGOUT_USER:
      return { ...state, customer: {}, loggedIn: false };
    case USER_LOGIN_ERROR:
      return { ...state, ...payload };
    case USER_REGISTER:
      return { ...state, ...payload };
    case AuthActions.BULK_UPDATE_USER_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default AuthReducer;
