import { BasketItem, Event } from "../../../../types";

export const mapQuantityToItems = (items: Event[]) => {
  return items.reduce<{
    [key: number]: BasketItem;
  }>((acc, item) => {
    acc[item.sellableItemId] = { item, quantity: 0 };
    return acc;
  }, {});
};
