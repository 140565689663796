import { Layers } from "cf-types";
import { ModalActions, ModalPayload } from "../actions";

type Action = {
  type: string;
  payload: ModalPayload;
};

export const initialState = {
  component: null,
  layer: Layers.DIALOG_LAYER,
  onClose: null,
  render: false,
};

const ModalReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case ModalActions.RENDER_MODAL:
      return {
        ...state,
        component: payload.component,
        onClose: payload.onClose,
        render: payload.render,
      };
    case ModalActions.DISMISS_MODAL:
      return {
        ...state,
        component: null,
        onClose: null,
        render: false,
      };
    default:
      return state;
  }
};

export default ModalReducer;
