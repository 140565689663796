import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ErrorMessages, OptInDataSource } from "cf-constants";
import { AuthSession } from "cf-types";
import { GetGuestAccessTokenQuery } from "pages/api/queries";
import { Store } from "redux/types";

const { latestOffers } = OptInDataSource;

export const getGuestAccessToken = async (
  store: Store["OptIn"] & Store["User"] & { deviceId: string },
  { client_id, client_secret }: { client_id: string; client_secret: string },
  client: ApolloClient<NormalizedCacheObject>
): Promise<AuthSession> => {
  try {
    const { email, firstName, lastName, optInData, phone } = store;
    const {
      data: { getGuestAccessToken },
      errors,
    } = await client.query<{
      getGuestAccessToken: AuthSession;
    }>({
      query: GetGuestAccessTokenQuery,
      variables: {
        creds: JSON.stringify({
          client_id,
          client_secret,
          deviceId: store.deviceId,
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
          newsletter: optInData.find(
            ({ field }) => field === latestOffers.field
          )?.value,
          password: "mockPassword",
          phone_number: phone.value || "not provided",
        }),
      },
    });

    if (errors) {
      throw errors[0].message;
    }

    return getGuestAccessToken;
  } catch (e) {
    throw new Error(ErrorMessages.Checkout.GET_GUEST_ACCESS_TOKEN);
  }
};
