import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ErrorMessages } from "cf-constants";
import { GetAuthQuery } from "pages/api/queries";

export const getAuth = async (client: ApolloClient<NormalizedCacheObject>) => {
  try {
    const {
      data: { getAuth },
      errors,
    } = await client.query<{
      getAuth: { client_id: string; client_secret: string };
    }>({ query: GetAuthQuery });

    if (errors) {
      throw errors[0].message;
    }

    return getAuth;
  } catch (e) {
    throw new Error(ErrorMessages.Checkout.GET_AUTH);
  }
};
