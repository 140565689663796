import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Action, BasketItem, Event } from "../../../types";
import { calculateBookingFee } from "../../../utils";
import { Dropdown } from "../dropdown";
import { BookingWidgetMobileHeader } from "./mobile-header";
import { PreviewText } from "./preview-text";
import { calculateTotal, mapQuantityToItems, updatedSelections } from "./utils";
import { Summary } from "./summary";

export interface VoucherBookingWidgetProps {
  action: Action;
  className?: string;
  closeButtonCB: (e: React.MouseEvent | React.KeyboardEvent) => void;
  currency: string;
  events: Event[];
  name: string;
  previewOnsaleTime: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onUpdateBasket: (data: BasketItem[]) => void;
  onChangeQuantity: (id: number, quantity: number) => void;
  termsUrl?: string;
}

const UnstyledVoucherBookingWidget = ({
  action,
  previewOnsaleTime,
  className,
  currency,
  name,
  events,
  closeButtonCB,
  onClick,
  onUpdateBasket,
  onChangeQuantity,
  termsUrl,
}: VoucherBookingWidgetProps) => {
  const [itemQuantities, setItemQuantities] = useState(
    mapQuantityToItems(events)
  );
  const [selectedItems, setSelections] = useState<BasketItem[]>([]);

  useEffect(() => {
    onUpdateBasket(selectedItems);
  }, [selectedItems]);

  const { bookingFee, bookingFeeNumber, bookingFeeTotal } = useMemo(
    () => calculateBookingFee(selectedItems),
    [selectedItems]
  );

  return (
    <div className={className}>
      <h2>Get Vouchers</h2>
      <BookingWidgetMobileHeader
        type={"vouchers"}
        name={name}
        onClickCB={closeButtonCB}
      />
      <hr />
      <form>
        {events.map(
          ({
            availableTickets,
            initialPrice,
            priceWithoutFee,
            seatDescription,
            sellableItemId,
          }) => {
            const selected = `${itemQuantities[sellableItemId].quantity}`;
            const price = `${currency}${priceWithoutFee.toFixed(2)}`;
            const originalPrice = `${currency}${initialPrice.toFixed(2)}`;

            return (
              <div
                className={`${className}-ticket-selector`}
                key={sellableItemId}
              >
                <div className={`${className}-ticket-selector--wrapper`}>
                  <span className={`${className}-ticket-selector--label`}>
                    {seatDescription}
                  </span>
                  <div className={`${className}-ticket-selector--prices`}>
                    <span
                      className={`${className}-ticket-selector--price-without-fee`}
                    >
                      {price}
                    </span>
                    {initialPrice > 0 && priceWithoutFee < initialPrice && (
                      <span
                        className={`${className}-ticket-selector--initial-price`}
                      >
                        {originalPrice}
                      </span>
                    )}
                  </div>
                </div>
                <Dropdown
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    const quantity = parseInt(event.target.value, 10);
                    const newItem = {
                      ...itemQuantities[sellableItemId],
                      quantity,
                    };
                    const updatedItems = updatedSelections(
                      selectedItems,
                      newItem
                    );
                    setItemQuantities({
                      ...itemQuantities,
                      [sellableItemId]: newItem,
                    });
                    setSelections(updatedItems);
                    onChangeQuantity(sellableItemId, quantity);
                  }}
                  options={Array.from(
                    { length: availableTickets + 1 },
                    (_, i) => `${i}`
                  )}
                  selected={selected}
                />
              </div>
            );
          }
        )}
        <hr />
        {action === "book" && (
          <Summary
            bookingFee={`${currency}${bookingFee.toFixed(2)}`}
            bookingFeeNumber={bookingFeeNumber}
            bookingFeeTotal={`${currency}${bookingFeeTotal.toFixed(2)}`}
            disabled={!selectedItems.length}
            onClick={onClick}
            qa={"voucher"}
            termsUrl={termsUrl}
            totalPrice={`${currency}${calculateTotal(itemQuantities)}`}
          />
        )}
        {action === "preview" && (
          <PreviewText previewOnsaleTime={previewOnsaleTime} />
        )}
      </form>
    </div>
  );
};

export const VoucherBookingWidget = styled(UnstyledVoucherBookingWidget)`
  form {
    margin: 20px;

    hr {
      margin-left: 0;
      margin-right: 0;
    }
  }

  border: solid 1px #aaa;

  background-color: #fff;

  h2 {
    display: none;
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #1a1a1a;
    margin: 0;
    margin-top: 15px;
  }

  div[class$="date-selector"],
  div[class$="time-selector"],
  div[class$="ticket-selector"] {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  div[class$="date-selector"],
  div[class$="time-selector"] {
    margin-bottom: 20px;
  }

  div[class$="date-selector"] select,
  div[class$="time-selector"] select {
    width: 225px;
  }

  span[class$="label"] {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #1a1a1a;
  }

  div[class$="ticket-selector"] {
    margin-bottom: 32px;
  }

  div[class$="ticket-selector--wrapper"] {
    margin-right: 10px;
  }

  div[class$="ticket-selector--prices"] {
    display: flex;
  }

  span[class$="ticket-selector--price-without-fee"] {
    font-family: Roboto;
    font-size: 12px;
    color: #4a4a4a;
    margin-right: 5px;
  }

  span[class$="ticket-selector--initial-price"] {
    font-family: Roboto;
    font-size: 12px;
    color: #aaa;
    text-decoration: line-through;
  }

  hr {
    border: 0px solid #d8d8d8;
    border-bottom-width: 1px;
    margin: 8px 20px 20px;
    display: none;
  }

  @media (min-width: 768px) {
    width: 298px;
    h2 {
      display: inherit;
    }

    hr {
      display: inherit;
    }
  }
`;
