export const genericMessage = "Oops Something went wrong";

export enum Checkout {
  API_ERROR = "API ERROR",
  GET_AUTH = "Something’s gone wrong, please try again",
  GET_GUEST_ACCESS_TOKEN = "Something’s gone wrong, please try again",
  GET_SECURE_SESSION = "Something’s gone wrong, please try again",
  GET_AUTH_USER_PROFILE = "Oops! It seems your login session has expired. Please log back in...",
  REQUIRED_FIELD_VALIDATION_ERROR = "REQUIRED FIELD VALIDATION ERROR",
}
