import { BasketItem } from "../types";

export const calculateBookingFee = (selectedItems: BasketItem[]) => {
  return selectedItems.reduce(
    (acc, { item, quantity }) => {
      if (item.bookingFee) {
        acc.bookingFee = item.bookingFee;
        acc.bookingFeeNumber += quantity;
        acc.bookingFeeTotal += item.bookingFee * quantity;
      }

      return acc;
    },
    {
      bookingFee: 0,
      bookingFeeNumber: 0,
      bookingFeeTotal: 0,
    }
  );
};
