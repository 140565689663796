import { gql } from "@apollo/client";

export const GetDeviceDataQuery = gql`
  query DeviceDataQuery($deviceData: String!) {
    getDeviceData(deviceData: $deviceData) {
      deviceId
      currentMarket {
        categories {
          description
          slug
          title
        }
        currencyCode
        formattedSupportPhone
        latitude
        localeName
        longitude
        name
        pages {
          checkoutTerms
          cookiePolicy
          frequentlyAskedQuestions
          privacyPolicy
          separateSubscription
          termsAndConditions
          website
        }
        resourceUri
        siteId
        slug
        supportEmail
        supportPhone
        tier
        timezone
        twitter
      }
      selectedMarket {
        categories {
          description
          slug
          title
        }
        currencyCode
        formattedSupportPhone
        latitude
        localeName
        longitude
        name
        pages {
          checkoutTerms
          cookiePolicy
          frequentlyAskedQuestions
          privacyPolicy
          separateSubscription
          termsAndConditions
          website
        }
        resourceUri
        siteId
        slug
        supportEmail
        supportPhone
        tier
        timezone
        twitter
      }
      session {
        api_key
        customer {
          email
          first_name
          last_name
          phone_number
          setting_receive_newsletter_emails
        }
        device
        resource_uri
      }
    }
  }
`;
