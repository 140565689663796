// @ts-nocheck
import { CMP_FRAME_NAME } from "../constants";

/**
 * adds an iframe in the provided window container; this iframe will act as a
 * confirmation element for any SSP that asks whether consent module is present
 * @param windowContainer
 */

export const addCMPIframe = (windowContainer: Window): void => {
  if (!windowContainer.frames[CMP_FRAME_NAME]) {
    const docBody = windowContainer.document?.body;
    if (docBody) {
      const iframe = windowContainer.document.createElement("iframe");
      iframe.style.cssText = "display: none";
      iframe.name = CMP_FRAME_NAME;
      docBody.appendChild(iframe);
    } else {
      setTimeout(addCMPIframe, 5, windowContainer);
    }
  }
};
