import { Analytics } from "cf-constants";
import {
  Currency,
  EventGroup,
  EventPageMetaData,
  PurchaseType,
} from "cf-types";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBooking } from "redux/actions";
import { UPDATE_PAGE_DATA } from "redux/types";
import {
  Button,
  OrderConfirmation,
} from "../../../src/components/to-components";
import { selectors } from "src/utils";

import styles from "./checkout-confirmation.module.scss";
import { Event } from "../to-components/types";

interface Props {
  eventData: EventPageMetaData & {
    currency: Currency;
    currencyCode: keyof typeof Currency;
    eventGroupID: number;
    eventType: EventGroup["eventType"];
    purchaseType: PurchaseType;
    shippable: boolean;
    title: string;
    events: Event[];
  };
}

const ConfirmationPage: React.FC<Props> = ({ eventData }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    chargeAmount,
    discount,
    email,
    name,
    reference,
    selectedMarket,
    tickets,
  } = useSelector(selectors.orderConfirmation);

  useEffect(() => {
    dispatch({
      type: UPDATE_PAGE_DATA,
      payload: { pageName: Analytics.PageName.BOOKING_SUCCESS },
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetBooking());
    };
  }, []);

  const onClick = () => {
    router.push(`/${selectedMarket[0].slug ?? "london"}`);
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <OrderConfirmation
          currency={eventData.currency}
          discount={discount && discount.toFixed(2)}
          email={email}
          imageSrc={eventData.images?.[0]?.url}
          orderReference={reference}
          orders={tickets}
          title={name}
          total={chargeAmount.toFixed(2)}
        />
        <div className={styles.button}>
          <Button onClick={onClick} title={"Continue Shopping"} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
