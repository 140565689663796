import { CardActions } from "../actions";
import { Token } from "../../src/components/to-components";

type Action = {
  type: string;
  payload?: Token;
};

export const initialState = {
  details: {},
  nonce: "",
};

const CardReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case CardActions.SET_TOKEN:
      return {
        ...state,
        details: action.payload?.details,
        nonce: action.payload?.nonce,
      };
    default:
      return state;
  }
};

export default CardReducer;
