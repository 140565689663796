import styled from "styled-components";
import { FullRating } from "../../../global/iconography/review-stars/review-star-icons";

export interface ReviewProps {
  className?: string;
  rating: number;
  reviews: number;
}

const UnstyledReview = ({ className, rating, reviews }: ReviewProps) => (
  <div className={className}>
    <FullRating rating={rating} />
    <span>{reviews} reviews</span>
  </div>
);

export const Review = styled(UnstyledReview)`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a;
  }
`;
