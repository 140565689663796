import { Store } from "redux/types";
import { createSelector } from "reselect";

const appState = ({ AppState }: Store) => AppState;
const auth = ({ AuthData }: Store) => AuthData;
const basket = ({ BasketItems }: Store) => BasketItems;
const booking = ({ Booking }: Store) => Booking;
const checkout = ({ Checkout }: Store) => Checkout;
const market = ({ Market }: Store) => Market;
const optIn = ({ OptIn }: Store) => OptIn;
const page = ({ Page }: Store) => Page;
const user = ({ User }: Store) => User;

export const checkoutReview = createSelector(
  appState,
  auth,
  basket,
  checkout,
  user,
  booking,
  optIn,
  page,
  market,
  (
    appState,
    auth,
    basket,
    checkout,
    user,
    booking,
    optIn,
    { pageName },
    { selectedMarket }
  ) => {
    return {
      auth: {
        customer: auth.customer,
        email: auth.customer.email,
        firstName: auth.customer.first_name,
        lastName: auth.customer.last_name,
        latestOffers: auth.customer.setting_receive_newsletter_emails,
        loggedIn: auth.loggedIn,
        phone: auth.customer.phone_number,
      },
      basket: basket.items,
      billing: checkout.billing,
      booking,
      delivery: checkout.delivery,
      optInData: optIn.optInData,
      pageName,
      selectedMarket,
      supportEmail: appState.device.supportEmail,
      user: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      },
    };
  }
);

export const orderConfirmation = createSelector(
  user,
  booking,
  basket,
  market,
  (user, { basket, confirmed }, { items }, { selectedMarket }) => {
    const { _extra, eventGroup } = confirmed;

    return {
      chargeAmount: basket.chargeAmount,
      discount: basket.promoCode?.amount,
      email: user.email.value,
      name: eventGroup.name,
      reference: _extra.purchaseId,
      selectedMarket,
      tickets: items.map(({ item, quantity }) => {
        return {
          imgAlt: eventGroup.name,
          price: item.priceWithoutFee.toFixed(2),
          title: item.seatDescription,
          unit: quantity,
        };
      }),
    };
  }
);
