import { ContactFields, FormFields } from "../types";

export enum UserActions {
  BULK_UPDATE = "bulk_update",
  UPDATE_USER = "update_user",
}

export interface BulkUpdate {
  type: UserActions.BULK_UPDATE;
  payload: {
    fields: Partial<User>;
  };
}

interface UserUpdate {
  type: UserActions.UPDATE_USER;
  payload: UserPayload;
}

export const updateBulkUser = (payload: {
  fields: Partial<User>;
}): BulkUpdate => {
  return {
    type: UserActions.BULK_UPDATE,
    payload,
  };
};

type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export type User = FormFields<Contact>;

export type UserPayload = {
  value?: string | boolean;
  field?: ContactFields;
  fields?: Partial<User>;
};

export const updateUser = (payload: UserPayload): UserUpdate => {
  return {
    type: UserActions.UPDATE_USER,
    payload,
  };
};
