import styled from "styled-components";
import { Colour } from "../../../types";
import { Button } from "../button/button";

export interface Props {
  className?: string;
  ctaText?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const UnstyledWaitingListBookingWidget: React.FC<Props> = ({
  className,
  ctaText,
  onClick,
}) => {
  return (
    <div className={className}>
      <Button onClick={onClick} qa={"waitinglist"} title={ctaText} />
    </div>
  );
};

export const WaitingListBookingWidget = styled(
  UnstyledWaitingListBookingWidget
)`
  background-color: #fff;

  button {
    width: 100%;

    font-size: 18px;
    font-weight: bold;
    text-align: center;

    background-color: #1a1a1a;
    color: #fff;

    border: none;

    cursor: pointer;
  }

  button:hover,
  button:focus {
    background-color: ${Colour.ERROR};
  }

  @media (min-width: 768px) {
    width: 298px;
  }
`;
