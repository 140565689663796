import axios from "axios";
import { NetworkConfig } from "cf-types";
import { getAPIBaseURL } from "cf-utils";

const baseURL = getAPIBaseURL();

export const instance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-YPlan-Subdomain": "",
    "X-YPlan-Origin": "yplan",
    "x-yplan-api-version": 1,
  },
  timeout: 10000,
});

instance.interceptors.response.use(({ data }) => data);

/**
 * axios HTTP client helper for standardizing network requests.
 *
 * @param {[key: string]: string | number} initHeaders additional headers applied before each request.
 * @returns {(config: NetworkConfig) => Promise<T>}
 */
export const networkClient = async <T>(config: NetworkConfig = {}) => {
  const { baseURL, body, headers, params, method, timeout, url } = config;

  return instance.request<unknown, T>({
    baseURL,
    data: body,
    headers,
    method,
    params,
    timeout,
    url,
  });
};
