import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { RTGTransition } from "../transition/transition";
import { Props as ListItemProps } from "./list-item";
import { ImplementedStatus, TransitionStyles } from "../../../types";

const offset = -20;
const arrowDimnesion = 14;

const defaultTransitions: TransitionStyles = {
  entering: {
    opacity: 0,
    transform: `translateY(${offset}px)`,
  },
  entered: {
    opacity: 1,
    transform: `translateY(0px)`,
  },
  exiting: {
    opacity: 0,
    transform: `translateY(${offset}px)`,
  },
  exited: {
    opacity: 0,
    transform: `translateY(${offset}px)`,
  },
};

type Edge = "bottom" | "left" | "right" | "top";

export interface Props {
  backgroundColor?: string;
  bottomSpacing?: string;
  children: Array<React.ReactElement>;
  className?: string;
  id: string;
  multiSelect?: boolean;
  onClose?: () => void;
  onDestroy?: () => void;
  onSelect?: (selected: { value: Array<string> }) => void;
  padding?: `${number}px`;
  portal?: HTMLElement | null;
  render: boolean;
  selected?: Array<string>;
  showArrow?: boolean;
  tether?: { [k in Edge]?: `${number}px` };
  transitionStyles?: TransitionStyles;
  width?: `${number}px`;
  top?: number;
}

const UnstyledList: React.FC<Props> = ({
  bottomSpacing,
  children,
  className,
  id,
  multiSelect,
  onClose = () => {},
  onDestroy,
  onSelect,
  portal,
  render,
  selected = [],
  transitionStyles = defaultTransitions,
}) => {
  const nodeRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (render && !multiSelect) {
        window.addEventListener("click", onClose);
      } else {
        window.removeEventListener("click", onClose);
      }
    }, 0);

    return () => {
      window.removeEventListener("click", onClose);
    };
  }, [render]);

  useEffect(() => {
    const uids = children.map(({ props }) => props.uid);
    if (uids.length !== new Set(uids).size) {
      throw "All List items must have a unique uid";
    }
  }, [children.length]);

  const getValue = (uid: string) => {
    if (!multiSelect) {
      return [uid];
    }

    const item = selected.find((item) => item === uid);
    return item ? selected.filter((item) => item !== uid) : [...selected, uid];
  };

  const onClick =
    ({ disabled, uid }: ListItemProps) =>
    (_: React.MouseEvent<HTMLButtonElement>) => {
      onSelect && !disabled && onSelect({ value: getValue(uid) });
    };

  const Component = (
    <RTGTransition
      inProp={render}
      duration={100}
      handlers={{ onExited: onDestroy }}
      nodeRef={nodeRef}
    >
      {(state: ImplementedStatus) => (
        <ul
          id={`list-${id}`}
          className={className}
          ref={nodeRef}
          style={{
            ...transitionStyles[state],
          }}
        >
          {React.Children.map(children, (child: React.ReactElement) => {
            if (!child.props.uid) {
              throw "All List items must have a uid";
            }

            return React.cloneElement(child, {
              bottomSpacing,
              onClick: onClick(child.props),
            });
          })}
        </ul>
      )}
    </RTGTransition>
  );

  if (portal) {
    return ReactDOM.createPortal(Component, portal);
  }

  return Component;
};

export const List = styled(UnstyledList)`
  position: absolute;

  ${({ tether }) => {
    return {
      bottom: tether?.bottom ?? "auto",
      left: tether?.left ?? "auto",
      right: tether?.right ?? "auto",
      top: tether?.top ?? "auto",
    };
  }};

  width: ${({ width }) => width ?? "auto"};
  padding: ${({ padding }) => padding ?? "0px"};
  margin: 0;

  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};

  pointer-events: all;

  transition: all ${300}ms ease-out;
  transition-property: transform, opacity;

  &:after {
    position: absolute;
    left: 15px;
    top: ${arrowDimnesion * -0.5}px;

    display: ${({ showArrow }) => (showArrow ? "block" : "none")};
    width: ${arrowDimnesion}px;
    height: ${arrowDimnesion}px;

    background: inherit;
    transform: rotate(45deg);
    content: "";
  }

  @media (max-width: 375px) {
    right: 0;

    width: 100%;
  }
`;
