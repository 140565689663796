import { TCFData } from "../../../../types";
import { loadScript } from "./load-script";

export const loadCMP = async (
  onConsent: (p: TCFData) => void
): Promise<void> => {
  try {
    await loadScript({
      src: "https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js",
      parent: "head",
      timeout: 0,
      load: "true",
      attributes: {
        async: "false",
        defer: "false",
      },
    });

    window.__tcfapi("addEventListener", 2, (tcData: TCFData) => {
      onConsent(tcData);
    });
  } catch (e) {
    console.error(e);
    throw new Error("cannot download sourcepoint");
  }
};
