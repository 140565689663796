import React, { useState } from "react";
import styled from "styled-components";
import { InputField } from "../input-field/input-field";
import { SearchIcon } from "../../../global/iconography/search/search-icon";
import { Button } from "../button/button";

export interface Props {
  className?: string;
  formHeight?: string;
  placeholderText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  searchTerm?: string;
}

const UnstyledSearchBar = ({
  className,
  placeholderText,
  onChange,
  onSubmit,
  searchTerm,
}: Props) => {
  const [isFocused, setFocus] = useState(false);

  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };

  const style = `${isFocused ? "focused" : ""}`;
  return (
    <div className={className}>
      <form className={style} onSubmit={onSubmit}>
        <SearchIcon darkMode={!isFocused} />
        <InputField
          className={style}
          placeholder={placeholderText}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={searchTerm}
        />
        {searchTerm && <Button type="submit">Search</Button>}
      </form>
    </div>
  );
};

export const SearchBar = styled(UnstyledSearchBar)`
  form {
    display: flex;
    align-items: center;
    padding: 15px;
    height: ${({ formHeight = "100%" }) => formHeight};

    background-color: #2b2b2b;

    border: 1px solid #aaaaaa;
    border-radius: 5px;
  }

  input {
    flex: 1;
    padding-left: 20px;

    color: #aaaaaa;
    background-color: inherit;
  }

  input {
    height: 100%;

    font-family: Roboto;
    font-size: 16px;

    border: none;
  }

  input::placeholder {
    color: #aaaaaa;
  }

  form.focused {
    background-color: #ffffff;
  }

  input.focused::placeholder {
    color: #7d7d7d;
  }

  input:focus {
    outline: none;
    color: #1a1a1a;
  }
`;
