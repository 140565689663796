import React from "react";
import styled from "styled-components";

export type InputType = "checkbox" | "email" | "number" | "tel" | "text";

interface Props {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  name?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  qa?: string;
  type?: InputType;
  value?: string;
}

const UnstyledInputField: React.FC<Props> = ({
  checked,
  error,
  qa,
  type = "text",
  value,
  ...rest
}) => {
  const props = {
    ...(type === "checkbox" ? { checked } : { value }),
    ...((type === "text" || type === "number" || type === "email") && {
      "data-private": "lipsum",
    }),
    ...rest,
    "data-qa": `${qa}-InputField`,
    type,
  };

  return <input {...props} />;
};

export const InputField = styled(UnstyledInputField)`
  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px;

  border-color: ${({ error }) => (error ? "#d31a22" : "#aaa")};
  border-width: 1px;
  border-style: ${({ disabled }) => (disabled ? "none" : "solid")};
`;
