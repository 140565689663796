import React from "react";
import { Transition } from "react-transition-group";

export interface Props {
  children: React.ReactNode;
  duration?: number;
  handlers?: {
    onEnter?: () => void;
    onEntered?: () => void;
    onEntering?: () => void;
    onExit?: () => void;
    onExited?: () => void;
    onExiting?: () => void;
  };
  inProp: boolean;
  mountOnEnter?: boolean;
  nodeRef: React.Ref<HTMLElement>;
  unmountOnExit?: boolean;
}

const noop = () => {};

export const RTGTransition: React.FC<Props> = ({
  children,
  duration = 300,
  handlers = {
    onEnter: noop,
    onEntered: noop,
    onEntering: noop,
    onExit: noop,
    onExited: noop,
    onExiting: noop,
  },
  inProp,
  mountOnEnter = true,
  nodeRef = null,
  unmountOnExit = true,
}) => {
  return (
    <Transition
      in={inProp}
      mountOnEnter={mountOnEnter}
      nodeRef={nodeRef}
      onEnter={handlers.onEnter}
      onEntered={handlers.onEntered}
      onEntering={handlers.onEntering}
      onExit={handlers.onExit}
      onExited={handlers.onExited}
      onExiting={handlers.onExiting}
      timeout={duration}
      unmountOnExit={unmountOnExit}
    >
      {/* @ts-ignore */}
      {children}
    </Transition>
  );
};
