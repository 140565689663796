import { useLazyQuery } from "@apollo/client";
import { getClient } from "apollo-client";
import { Analytics } from "cf-constants";
import { gtmDataLayerPush } from "cf-utils";
import { Newsletter } from "cf-types";
import { useRouter } from "next/router";
import { PostNewsletterQuery } from "pages/api/queries";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux/types";
import { Field } from "../../src/components/to-components";
import { formUtils } from "../utils";
import { useDeviceId } from "./use-device-id";
import { useRouterEvent } from "./use-router-event";

type Return = [
  {
    email: Field;
    isSubscribed: boolean;
  },
  {
    handleEmailInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubscribe: (e: React.FormEvent<HTMLButtonElement>) => void;
  }
];

export const useNewsletterSubscription = (): Return => {
  const router = useRouter();
  const [deviceId] = useDeviceId();
  const [currentRoute, setCurrentRoute] = useState<string>(router.asPath);
  const selectedMarket = useSelector(
    ({ Market }: Store) => Market?.selectedMarket[0]
  );
  const { pageName } = useSelector(({ Page }: Store) => Page);
  const [isSubscribed, setSubscribed] = useState<boolean>(false);
  const [email, setEmail] = useState<Field>({
    error: false,
    value: "",
  });
  const onRouteChangeStart = (url: string) => {
    setCurrentRoute(url);
  };
  useRouterEvent("routeChangeStart", onRouteChangeStart, [pageName]);

  const route = {
    query: {
      event: router.query?.event,
      market: selectedMarket.slug,
    },
    url: currentRoute,
  };

  const responseMap: { [k in Newsletter["success"]]: (p: string) => void } = {
    False: () => {},
    True: (crmHashedEmail: string) => {
      setSubscribed(true);
      gtmDataLayerPush(
        {
          event: Analytics.CustomGTMEvent.TO_NEWSLETTER_SIGN_UP,
          payload: {
            crmHashedEmail,
          },
        },
        selectedMarket.categories,
        route
      );
    },
  };

  const [postNewsLetter] = useLazyQuery<{
    postNewsletter: Newsletter;
  }>(PostNewsletterQuery, {
    client: getClient(""),
    fetchPolicy: "no-cache",
    onCompleted: ({ postNewsletter }) => {
      if (!postNewsletter) {
        setEmail({
          error: true,
          value: email.value,
        });
        return;
      }
      const { hashedEmail, success } = postNewsletter;
      responseMap[success](hashedEmail);
    },
    onError: console.error,
  });

  const handleSubscribe = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    postNewsLetter({
      variables: {
        newsletterData: JSON.stringify({
          deviceId,
          email: email.value,
          locale: selectedMarket.localeName,
          platform: "s=checkout",
          referrer: window.location.href,
          site: selectedMarket.siteId,
        }),
      },
    });
  };

  const handleEmailInput = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setEmail({
      error: !formUtils.isValidEmail(value),
      value,
    });
  };

  return [
    {
      isSubscribed,
      email,
    },
    {
      handleEmailInput,
      handleSubscribe,
    },
  ];
};
