import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  termsUrl?: string;
}

const UnstyledBookingFeeToolTip: React.FC<Props> = ({
  className,
  termsUrl,
}) => (
  <div className={className}>
    <div>The booking fees we charge cover the costs we incur</div>
    <a className={`${className}-read-more`} href={termsUrl} target={"_blank"}>
      Read more here
    </a>
  </div>
);

export const BookingFeeToolTip = styled(UnstyledBookingFeeToolTip)`
  padding: 10px;
  width: 200px;

  line-height: 1.5;

  background-color: #ffffff;

  a[class$="read-more"] {
    color: #1a1a1a;
    text-decoration: underline;
  }
`;
