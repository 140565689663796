import React from "react";
import styled from "styled-components";

import { CollectionWidget, CollectionWidgetProps } from "../collection-widget";
import { Collection } from "cf-types";

export interface CollectionListProps {
  className?: string;
  items: CollectionWidgetProps[];
}

const unstyledCollectionList = ({ className, items }: CollectionListProps) => {
  // This is to stop NextJS complaining about mutating state
  const collections = [...items].reverse();

  return (
    <div className={className}>
      <h1 className={`${className}-title`}>
        <span>Offer Collections</span>
      </h1>
      <div className={`${className}-widget-list`}>
        {collections.map((item, idx) => (
          // @ts-ignore
          <CollectionWidget {...item} key={idx} />
        ))}
      </div>
    </div>
  );
};

export const CollectionList = styled(unstyledCollectionList)`
    margin: 0 15px 50px;

    h1[class$="title"] {
        text-align: center;
        margin: 0 0 20px;
        font-family: FranklinGothicLTPro-DmCm;
        font-size: 32px;
        line-height: 1.13;
        color: #1a1a1a;
    }
    
    div[class$="widget-list"] {
        display: flex;
        flex-direction: column;
    }

    div[class$="widget-list"] > div:first-child {
        margin-bottom: 30px;
    }

    @media(min-width: 700px) {
        div[class$="widget-list"] {
            align-items: center;
        }
    }

    @media(min-width: 1438px) {
        margin: 0 80px 80px;

        h1[class$="title"] {
            position: relative;
        }

        h1[class$="title"] > span {
            background: white;
            position: relative;
            padding 0 21px;
        }

        h1[class$="title"]:before  {
            border-top: 8px solid black;
            content: "";
            display: block;
            margin-top: -.3rem;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            width: 100%;
        }

        div[class$="widget-list"] {
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;
            column-gap: 30px;
        }
    }
`;
