export enum OptInActions {
  UPDATE_OPT_IN = "update_opt_in",
}

export type OptInPayload = {
  field: string;
  value: boolean;
};

interface UpdateOptIn {
  type: OptInActions.UPDATE_OPT_IN;
  payload: OptInPayload;
}

export const updateOptIn = (payload: OptInPayload): UpdateOptIn => {
  return {
    type: OptInActions.UPDATE_OPT_IN,
    payload,
  };
};
