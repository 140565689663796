export const InstagramLogo = () => (
  <svg
    width={19}
    height={19}
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"ig"}</title>
    <path
      d="M0 15.846c0 .866.31 1.608.928 2.226.618.619 1.36.928 2.226.928h12.692c.866 0 1.608-.31 2.226-.928.619-.618.928-1.36.928-2.226V3.154c0-.866-.31-1.608-.928-2.226A3.037 3.037 0 0015.846 0H3.154C2.288 0 1.546.31.928.928A3.037 3.037 0 000 3.154v12.692zm2.375-.409V8.314H4.23c-.098.494-.148.89-.148 1.187 0 1.484.532 2.758 1.596 3.822s2.338 1.596 3.822 1.596 2.758-.532 3.822-1.596 1.596-2.338 1.596-3.822c0-.297-.05-.693-.148-1.188h1.855v7.126c0 .346-.111.63-.334.853-.223.223-.507.334-.854.334H3.563c-.347 0-.631-.111-.854-.334-.223-.223-.334-.507-.334-.854zM5.863 9.5c0-.42.074-.816.223-1.188h6.828c.149.372.223.767.223 1.188 0 .99-.359 1.843-1.076 2.56-.718.718-1.571 1.077-2.561 1.077s-1.843-.359-2.56-1.076c-.718-.718-1.077-1.571-1.077-2.561zm8.387-5.344V2.97c0-.396.198-.594.594-.594h1.187c.396 0 .594.198.594.594v1.187c0 .396-.198.594-.594.594h-1.187c-.396 0-.594-.198-.594-.594z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
