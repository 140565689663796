import React from "react";
import styled from "styled-components";
import { InputField } from "../../input-field/input-field";

interface Props {
  className?: string;
  disabled: boolean;
  error: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const UnstyledPhone: React.FC<Props> = ({
  className,
  disabled,
  error,
  onChange,
  value,
}: Props) => {
  return (
    <div className={className}>
      <div>
        <label htmlFor={"phone"}>
          Phone{!disabled && <Optional>(optional)</Optional>}
        </label>
      </div>
      <InputField
        disabled={disabled}
        error={error}
        type={"tel"}
        id={"phone"}
        name={"phone"}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const Optional = styled.span`
  margin-left: 5px;

  color: #7d7d7d;

  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`;

export const Phone = styled(UnstyledPhone)`
  display: flex;
  flex-direction: ${({ disabled }) => (disabled ? "row" : "column")};

  label {
    display: flex;
    align-items: ${({ disabled }) => (disabled ? "center" : "unset")};
    width: ${({ disabled }) => (disabled ? "70px" : "unset")};
    height: 100%;

    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  input {
    flex: ${({ disabled }) => (disabled ? "1" : "unset")};
    height: 44px;

    background-color: #fff;
  }
`;
