import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./category-page.module.css";

const containerData = [
  "headerPlaceholder",
  "searchbarPlaceholder",
  "totalcountPlaceholder",
];

const productListData = [
  { className: "productHeroPlaceholder" },
  { className: "productTitlePlaceholder" },
  {
    className: "productTextPlaceholder",
    props: { count: 2 },
  },
];

interface Props {
  placeholderCount?: number;
}

const CategoryPagePlaceholder: React.FC<Props> = ({
  placeholderCount = 40,
}) => (
  <div className={styles.containerPlaceholder}>
    {containerData.map((className) => {
      return (
        <div className={styles[className]} key={className}>
          <Skeleton />
        </div>
      );
    })}
    <div className={styles.productsListPlaceholder}>
      {Array.from({ length: placeholderCount }, (_, key) => (
        <div key={`key-${key}`} className={styles.productPlaceholder}>
          {productListData.map(({ className, props }) => {
            return (
              <div className={styles[className]} key={className}>
                <Skeleton {...props} />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  </div>
);

export default CategoryPagePlaceholder;
