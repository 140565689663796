import styled from "styled-components";

interface Props {
  className?: string;
  currency: string;
  discount?: string | 0;
  orderReference: number;
  total: string;
}

const UnstyledSummary: React.FC<Props> = ({
  className,
  currency,
  discount,
  orderReference,
  total,
}) => {
  return (
    <div className={className}>
      {discount && (
        <div className={`${className}-item`}>
          <span>Discount</span>
          <span
            className={`${className}-price`}
          >{`${currency}${discount}`}</span>
        </div>
      )}
      <div className={`${className}-item`}>
        <span>Order Total</span>
        <span className={`${className}-price`}>{`${currency}${total}`}</span>
      </div>
      <div className={`${className}-item`}>
        <span>Order reference</span>
        <span>{orderReference}</span>
      </div>
    </div>
  );
};

export const Summary = styled(UnstyledSummary)`
  display: flex;
  flex-direction: column;

  div[class$="item"] {
    display: flex;
    justify-content: space-between;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.63;
  }

  div[class$="item"]:nth-of-type(2) {
    margin-bottom: 16px;
  }

  div[class$="item"]:last-child {
    color: #4a4a4a;
  }

  span[class$="price"] {
    font-weight: 700;
  }
`;
