import styled from "styled-components";
import { Colour } from "../../../types";

export interface Props {
  className?: string;
  links: React.ReactElement[];
}

const UnstyledNavigationBar = ({ className, links }: Props) => {
  return <nav className={className}>{links}</nav>;
};

export const NavigationBar = styled(UnstyledNavigationBar)`
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: #1a1a1a;

  a {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;

    text-decoration: none;
    font-family: Roboto;
    font-size: 16px;
    color: #ffffff;

    border-bottom: 3px solid transparent;
  }

  a:hover,
  a:focus,
  a:active {
    border-bottom-color: ${Colour.ERROR};
  }

  a:active {
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
