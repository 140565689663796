import { IconsProps } from "../icons-types";

export const MapPinIcon = ({
  darkMode,
  width = 22,
  height = 30,
}: IconsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/Map Pin/Outline"}</title>
    <path
      d="M10.882 15.882a4.412 4.412 0 100-8.823 4.412 4.412 0 000 8.823zm0-15.882c6.01 0 10.883 5.004 10.883 11.176 0 4.116-3.628 10.39-10.883 18.824C3.627 21.566 0 15.292 0 11.176 0 5.004 4.872 0 10.882 0zm0 2.353c-4.696 0-8.53 3.937-8.53 8.823 0 3.062 2.824 8.221 8.53 15.169 5.707-6.948 8.53-12.107 8.53-15.169 0-4.886-3.833-8.823-8.53-8.823zm0 7.059a2.059 2.059 0 110 4.117 2.059 2.059 0 010-4.117z"
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
