import React from "react";
import styled from "styled-components";
import { BookingFee } from "../view/booking-fee/booking-fee";
import { Button } from "../button/button";

export interface Props {
  bookingFee: string;
  bookingFeeNumber: number;
  bookingFeeTotal: string;
  className?: string;
  disabled: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  qa: string;
  termsUrl?: string;
  totalPrice?: string;
}

const UnstyledSummary = ({
  bookingFee,
  bookingFeeNumber,
  bookingFeeTotal,
  className,
  disabled,
  termsUrl = "https://checkout.timeout.com",
  totalPrice,
  onClick,
  qa,
}: Props) => {
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <div className={className}>
      <div className={`${className}-total-section`}>
        {!!bookingFeeNumber && (
          <div className={`${className}-booking-fee`}>
            <BookingFee
              bookingFee={bookingFee}
              bookingFeeNumber={bookingFeeNumber}
              bookingFeeTotal={bookingFeeTotal}
              termsUrl={termsUrl}
            />
          </div>
        )}
        <div className={`${className}-total-price`}>
          <span className={`${className}-total-price--text`}>Total</span>
          <span className={`${className}-total-price--value`}>
            {totalPrice}
          </span>
        </div>
      </div>
      <Button disabled={disabled} onClick={onClickHandler} qa={`${qa}-booking`}>
        Buy Now
      </Button>
    </div>
  );
};

export const Summary = styled(UnstyledSummary)`
  div[class$="total-price--text"] {
    font-size: 18px;
  }

  div[class$="booking-fee"] {
    margin-bottom: 12px;
  }

  span[class$="tool-tip"] {
    cursor: default;

    text-decoration: underline;
  }

  div[class$="total-price"] {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  span[class$="total-price--text"] {
    font-family: Roboto;
    color: #1a1a1a;
  }

  span[class$="total-price--value"] {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    color: #1a1a1a;
  }

  button {
    width: 100%;
    height: 50px;

    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #fff;

    background-color: #1a1a1a;
    border: none;

    cursor: pointer;
  }

  button:not(:disabled):hover,
  button:not(:disabled):focus {
    background-color: #d31a22;
  }
`;
