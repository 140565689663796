import styled from "styled-components";
import { TicketIcon } from "../../../../global/iconography/ticket/ticket-icon";
import { MapPinIcon } from "../../../../global/iconography/map/map-pin-icon";
import { CalendarIcon } from "../../../../global/iconography/calendar/calendar-icon";
import { getReadableDate } from "../../../../utils/category-widget-utils";
import { Button } from "../../button/button";

type Location = {
  venueName?: string;
  address1?: string;
  address2?: string;
  area?: string;
  postCode?: string;
};

export interface OutingWidgetProps {
  className?: string;
  name?: string;
  ticketCount?: number;
  purchaseType?: string;
  location?: Location;
  startTime?: number;
  resourceID?: string;
  onViewOrderClickCB?: (
    e: React.FormEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
}

const purchaseTypes = {
  attendance: "ticket",
  voucher: "voucher",
} as { [key: string]: string };

const unstyledOutingWidget = ({
  className,
  name,
  ticketCount = 0,
  purchaseType = "",
  location = {},
  startTime,
  resourceID,
  onViewOrderClickCB,
}: OutingWidgetProps) => {
  return (
    <div className={className}>
      <h2>{name}</h2>
      <div className={`${className}-container`} id={resourceID}>
        <div className={`${className}-details`}>
          <div className={`${className}-ticket-count`}>
            <TicketIcon height={18} width={18} />
            <span>{`${ticketCount} ${purchaseTypes[purchaseType]}${
              ticketCount > 1 ? "s" : ""
            }`}</span>
          </div>
          <div className={`${className}-location`}>
            <MapPinIcon height={18} width={13} />
            <span>{`${location.venueName}, ${location.area}`}</span>
          </div>
          <div className={`${className}-date`}>
            <CalendarIcon height={14} width={16} />
            <span>{getReadableDate(startTime, true)}</span>
          </div>
        </div>
        <Button onClick={onViewOrderClickCB}>View order</Button>
        <a onClick={onViewOrderClickCB}>View order</a>
      </div>
      <hr />
    </div>
  );
};

export const OutingWidget = styled(unstyledOutingWidget)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 15px;

  div[class$="container"] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: normal;
    color: #1a1a1a;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  div[class$="details"] {
  }

  span {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a;
  }

  div[class$="ticket-count"],
  div[class$="location"],
  div[class$="date"] {
    display: flex;
    align-items: center;
  }

  div[class$="ticket-count"],
  div[class$="location"] {
    margin-bottom: 10px;
  }

  div[class$="ticket-count"] > span {
    margin-left: 9px;
  }

  div[class$="location"] > span {
    margin-left: 12px;
  }

  div[class$="date"] > span {
    margin-left: 10px;
  }

  a {
    margin: 15px 0 20px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #d31a22;
    text-decoration: underline;
    cursor: pointer;
  }

  button {
    align-self: end;
    width: 187px;
    height: 50px;
    padding: 13px 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #1a1a1a;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: none;
  }

  hr {
    background-color: #d8d8d8;
    height: 1px;
    width: 100%;
    margin: 30px 0 0 0;
    display: none;
  }

  @media (min-width: 1000px) {
    margin-bottom: 30px;
    padding: 0;

    div[class$="container"] {
      display: flex;
      flex-direction: unset;
      justify-content: space-between;
    }

    a {
      display: none;
    }

    button {
      display: unset;
    }

    hr {
      display: unset;
    }
  }
`;
