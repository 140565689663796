import React from "react";
import ReactDOM from "react-dom";
import { Mask } from "../mask/mask";

export interface Props {
  children: React.ReactElement | null;
  onClose?: () => void;
  onEntered?: () => void;
  onExited?: () => void;
  portal?: HTMLElement | null;
  render: boolean;
}

export const Modal: React.FC<Props> = ({
  children,
  onEntered,
  onExited,
  onClose,
  portal,
  render = false,
}) => {
  const Component = (
    <Mask
      children={children}
      onEntered={onEntered}
      onExited={onExited}
      onClose={onClose}
      render={render}
    />
  );

  if (portal) {
    return ReactDOM.createPortal(Component, portal);
  }

  return Component;
};
