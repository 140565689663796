import { useState } from "react";
import styled from "styled-components";
import { Colour } from "../../../types";
import * as Icons from "../../../global/iconography";
import { Accordion, AccordionItem } from "../accordion";
import { Button } from "../button/button";
import { List, ListItem } from "../list";
import { useProfileDropdown } from "./hooks";
import { ListRenderer } from "./list-renderer";
import { Props as MastheadProps } from "./mast-header";

const headerHeight = 60;
const sideBarWidth = 300;
const marginRight = 12;

interface Props extends MastheadProps {
  isMenuOpen: boolean;
  onMenuSelect: ({ value }: { value: string[] }) => void;
  onNavMenu: () => void;
  selectedMenu: string[];
}

const isReactElementTypeGuard = (
  elem: React.ReactElement | { [k: string]: string | boolean } | null
): elem is React.ReactElement => !!(elem as React.ReactElement)?.props;

const UnStyledMobile = ({
  className,
  displayMarkets,
  isLoggedIn,
  isMenuOpen,
  markets,
  navLinks,
  onLoginClick,
  onMenuSelect,
  onNavMenu,
  onSelectMarket,
  portal,
  profileLinks,
  selectedMarket,
  selectedMenu,
}: Props) => {
  const [profileDropdown, onProfileDropdownOpen, onClose] = useProfileDropdown(
    isLoggedIn,
    onLoginClick
  );
  const [active, setActiveState] = useState<{ [key: string]: boolean }>({
    [`list-${navLinks.length}`]: false,
  });
  const onToggle = (id: string) => {
    setActiveState((prev) => {
      return {
        ...prev,
        [id]: !prev[id],
      };
    });
  };

  const marketSection = {
    id: `list-${navLinks.length}`,
    isActive: false,
    title: "Cities",
  };
  const sideMenu = [...navLinks, displayMarkets ? marketSection : null].filter(
    Boolean
  );

  const [marketName] = selectedMarket;

  return (
    <div className={className}>
      <div className={`${className}-mobile-top-bar`}>
        <div className={`${className}-left-items`}>
          <Button onClick={onNavMenu} qa={"nav-open-mobile"}>
            {isMenuOpen && <Icons.CloseButtonIcon />}
            {!isMenuOpen && <Icons.DropdownIcon />}
          </Button>
          <List
            backgroundColor={Colour.CULTURED}
            id={"nav-menu-mobile"}
            onSelect={onMenuSelect}
            portal={portal}
            selected={selectedMenu}
            render={isMenuOpen}
            tether={{
              left: "0px",
              top: `${headerHeight}px`,
            }}
            width={`${sideBarWidth}px`}
          >
            {sideMenu.map((children, index) => {
              const uid = `list-${index}`;
              const isSelected = selectedMenu?.[0] === uid;

              if (isReactElementTypeGuard(children)) {
                return (
                  <ListItem
                    disableGutters
                    disablePadding
                    isSelected={isSelected}
                    key={uid}
                    uid={uid}
                    backgroundColor={Colour.WHITE}
                  >
                    <ListRenderer
                      color={Colour.BACKGROUND}
                      isSelected={isSelected}
                    >
                      {children}
                    </ListRenderer>
                  </ListItem>
                );
              }

              return (
                <ListItem
                  disablePadding
                  disableGutters
                  divider={false}
                  backgroundColor={Colour.WHITE}
                  isSelected={isSelected}
                  key={uid}
                  marginTop={`${children?.id === marketSection.id ? 30 : 0}px`}
                  uid={uid}
                >
                  <Accordion disablePadding onCollapse={onToggle}>
                    <AccordionItem
                      children={
                        <List
                          id={"market-dropdown-mobile"}
                          onSelect={onSelectMarket}
                          render={active[children?.id ?? ""]}
                          selected={selectedMarket}
                          tether={{
                            left: "0px",
                            top: "56px",
                          }}
                          width={`${sideBarWidth}px`}
                        >
                          {markets.map((children, index) => {
                            const uid = `link-${index}`;
                            const isSelected = marketName === children.name;

                            return (
                              <ListItem
                                disablePadding
                                disableGutters
                                backgroundColor={Colour.CULTURED}
                                isSelected={isSelected}
                                key={uid}
                                uid={children.name}
                              >
                                <ListRenderer
                                  isSelected={isSelected}
                                  color={Colour.WHITE}
                                >
                                  {children.name}
                                </ListRenderer>
                              </ListItem>
                            );
                          })}
                        </List>
                      }
                      focusBackground={Colour.WHITE}
                      id={uid}
                      isActive={active[children?.id ?? ""]}
                      title={children?.title ?? ""}
                    />
                  </Accordion>
                </ListItem>
              );
            })}
          </List>
          <a className={`${className}-logo`} href="/">
            <Icons.TimeOutOfferLogo />
          </a>
          <h1 className={`${className}-market-name`}>{marketName}</h1>
        </div>
        <div className={`${className}-right-items`}>
          <div className={`${className}-icons-wrapper`}>
            <a
              className={`${className}-icon`}
              data-track="subscribe-icon"
              href="#footer-newsletter"
            >
              <Icons.EmailIcon darkMode={true} />
            </a>
            <Button
              className={`${className}-icon`}
              onClick={onProfileDropdownOpen}
            >
              <Icons.AccountIcon darkMode={true} />
            </Button>
            <List
              backgroundColor={Colour.BACKGROUND}
              id={"profile-menu-mobile"}
              onClose={onClose}
              portal={portal}
              render={profileDropdown}
              tether={{
                right: "15px",
                top: `${headerHeight}px`,
              }}
              width={"130px"}
            >
              {profileLinks.map((children, index) => {
                const uid = `link-${index}`;
                return (
                  <ListItem key={uid} uid={uid}>
                    <ListRenderer color={Colour.WHITE}>{children}</ListRenderer>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Mobile = styled(UnStyledMobile)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

    div[class$="mobile-top-bar"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: ${headerHeight}px;
    }

    div[class$="right-items"] {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    div[class$="left-items"] {
      display: flex;
      flex-direction: ${({ isMenuOpen }) =>
        isMenuOpen ? "row-reverse" : "flex-end"};
      flex-shrink: 0;
      align-items: center;
      width: ${({ isMenuOpen }) =>
        isMenuOpen ? `${sideBarWidth - marginRight}px` : "auto"};
      justify-content: ${({ isMenuOpen }) =>
        isMenuOpen ? "space-between" : "row"};
    }

    div[class$="left-items"] button,
    button[class$="icon"] {
      padding: 0;

      background-color: transparent;
    }

    a[class$="logo"] svg,
    div[class$="mobile-search-wrapper"] {
      width: 100%;
    }

    a[class$="logo"] {
      display: ${({ isMenuOpen }) => (isMenuOpen ? "none" : "block")};
      margin-left: 20px;
    }

    h1[class$="market-name"] {
      display: ${({ isMenuOpen }) => (isMenuOpen ? "block" : "none")};
      margin: 0px;

      font-size: 38px;
      font-family: FranklinGothicLTPro-DmCm;
      color: ${Colour.WHITE};
    }

    div[class$="mobile-search-bar"] {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    div[class$="icons-wrapper"] svg {
      width: 100%;
      height: 100%;
    }

    div[class$="icons-wrapper"] {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a[class$="icon"],
    button[class$="icon"] {
      height: inherit;
      width: 24px;
      margin-left: 12px;
    }

    a[class$="icon"],
    div[class$="left-items"],
    button[class$="icon"]:not(:last-of-type) {
      margin-right: ${marginRight}px;
    }
  }

  @media (max-width: 375px) {
    div[class$="left-items"] {
      justify-content: ${({ isMenuOpen }) =>
        isMenuOpen ? "space-between" : "unset"};
      width: ${({ isMenuOpen }) => (isMenuOpen ? "100%" : "unset")};
      margin-right: ${({ isMenuOpen }) => (isMenuOpen ? "0px" : "unset")};
    }

    div[class$="right-items"] {
      display: ${({ isMenuOpen }) => (isMenuOpen ? "none" : "unset")};
    }
  }
`;
