import { gql } from "@apollo/client";

export const GetThirdPartyOptInQuery = gql`
  query ThirdPartyOptInQuery($eventGroupData: String!) {
    getThirdPartyOptIn(eventGroupData: $eventGroupData) {
      isEnabled
      frontendDisplayLabel
    }
  }
`;
