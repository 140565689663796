export const FacebookLogo = () => (
  <svg
    width={8}
    height={17}
    viewBox="0 0 8 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Fb"}</title>
    <path
      d="M0 8.5V5.578h2.125V3.852c0-1.306.243-2.275.73-2.906C3.342.316 4.25 0 5.578 0h2.358v2.922H6.475c-.51 0-.83.088-.963.265-.133.178-.2.487-.2.93v1.461h2.624l-.3 2.922H5.313V17H2.126V8.5H0z"
      fill="#FFFFFF"
      fillRule="evenodd"
    />
  </svg>
);
