import { clsx } from "clsx";
import React, { useRef } from "react";
import styled from "styled-components";
import { ArrowRightIcon } from "../../../global/iconography/arrows/arrow-icons";
import { Button } from "../button/button";

export interface Props {
  className?: string;
  disableHover?: boolean;
  disablePadding?: boolean;
  divider?: boolean;
  focusBackground?: string;
  id: string;
  isActive: boolean;
  onToggle?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  verticalPadding?: string;
}

const UnstyledAccordionItem: React.FC<Props> = ({
  children,
  className,
  id,
  isActive,
  onToggle = () => {},
  title,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const maxHeight = isActive ? `${contentRef?.current?.scrollHeight}px` : "0px";

  return (
    <li className={clsx(className, isActive && "active")}>
      <Button
        className={`${className}-accordion`}
        onClick={onToggle}
        qa={`${id}-accordion`}
      >
        <span className={`${className}-title`}>{title}</span>
        <ArrowRightIcon width={6} />
      </Button>
      <div
        {...{
          children,
          className: `${className}-content`,
          ref: contentRef,
          style: { maxHeight },
        }}
      />
    </li>
  );
};

export const AccordionItem = styled(UnstyledAccordionItem)`
  display: flex;
  flex-direction: column;
  ${({ verticalPadding }) => {
    return (
      verticalPadding && {
        "padding-top": verticalPadding,
        "padding-bottom": verticalPadding,
      }
    );
  }}

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ divider }) =>
    `${divider ? "#d8d8d8" : "transparent"}`};

  list-style: none;

  button[class$="accordion"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ disablePadding }) => (disablePadding ? "0" : "16px")};

    color: #1a1a1a;
    cursor: pointer;

    background-color: ${({ isActive }) =>
      `${isActive ? "#d8d8d8" : "transparent"}`};

    transition: background-color 0.3s ease;
  }

  button[class$="accordion"]:focus {
    background-color: ${({ focusBackground = "#d8d8d8" }) => focusBackground};
  }

  button[class$="accordion"]:hover {
    background-color: ${({ disableHover }) =>
      `${disableHover ? "#d8d8d8" : "transparent"}`};
  }

  div[class$="content"] {
    background-color: white;
    overflow-y: auto;

    transition: max-height 0.3s ease;
  }

  span[class$="title"] {
    font: inherit;
    font-size: 16px;
  }

  svg {
    transition: transform 0.3s ease;
    transform: ${({ isActive }) => `rotate(${isActive ? "90" : "0"}deg)`};
  }
`;
