import React from "react";
import { Divider } from "../../components/to-components";
import styles from "./profile-sidebar-menu.module.scss";
import Link from "next/link";
import { profileLinks } from "static-data";

export interface ProfileSidebarMenuProps {
  title: string;
  active: string;
}

const [orders, details] = profileLinks;
const ProfileSidebarMenu = ({ title, active }: ProfileSidebarMenuProps) => (
  <div className={styles.container}>
    <Divider />
    <h2>{title}</h2>
    <ul>
      <Divider />
      {orders.link && (
        <Link href={orders.link} passHref>
          <li>
            <a className={active === "orders" ? styles.active : ""}>
              My orders
            </a>
          </li>
        </Link>
      )}
      <Divider />
      {details.link && (
        <Link href={details.link} passHref>
          <li>
            <a className={active === "details" ? styles.active : ""}>
              My details
            </a>
          </li>
        </Link>
      )}
      <Divider />
    </ul>
  </div>
);

export default ProfileSidebarMenu;
