import { ErrorMessages } from "cf-constants";
import { CustomError } from "cf-types";
import {
  BraintreeError,
  Button,
  Divider,
} from "../../../src/components/to-components";

import styles from "./checkout-error.module.scss";

const isCustomErrorTypeGuard = (
  error: CustomError | BraintreeError
): error is CustomError => !!(error as CustomError)?.status;

interface Props {
  buttonTitle?: string;
  error?: CustomError | BraintreeError;
  onClick?: () => void;
  supportEmail?: string;
}

const CheckoutError: React.FC<Props> = ({
  buttonTitle = "TRY AGAIN",
  error,
  onClick,
  supportEmail,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h3 className={styles.title}>Something&apos;s Wrong</h3>
        {error && isCustomErrorTypeGuard(error) && (
          <h4 className={styles.status}>{error.status}</h4>
        )}
        <p className={styles.body}>
          {error?.message || ErrorMessages.genericMessage}
        </p>
        {onClick && (
          <Button className={styles.button} onClick={onClick}>
            {buttonTitle}
          </Button>
        )}
        <Divider />
        <div className={styles.support}>
          <span>Contact us:</span>
          <a className={styles.supportContact} href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CheckoutError;
