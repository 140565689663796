import React from "react";
import styled from "styled-components";
import { Error } from "../../error/error";

interface Props {
  className?: string;
  error?: boolean;
}

const UnstyledCardExpiry: React.FC<Props> = ({ className, error }: Props) => {
  return (
    <div className={className}>
      <label htmlFor={"expirationDate"}>Expiry date</label>
      <div id="expirationDate" />
      {error && <Error>Enter the expiry date</Error>}
    </div>
  );
};

export const CardExpiry = styled(UnstyledCardExpiry)`
  display: flex;
  flex-direction: column;
  width: 160px;
  margin-right: 30px;

  label {
    margin-bottom: 4px;

    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.5;
  }

  #expirationDate {
    padding-left: 10px;
    padding-right: 10px;
    height: 44px;

    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ error }) => (error ? "#d31a22" : "#aaa")};
  }

  @media (max-width: 425px) {
    width: 50%;
  }
`;
