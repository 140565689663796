import { IconsProps } from "../icons-types";

export const EmailIcon = ({ darkMode = false }: IconsProps) => (
  <svg
    width={21}
    height={17}
    viewBox="0 0 31 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"UI/Icons/Email"}</title>
    <path
      d="M0 18.512c0 1.37.489 2.543 1.467 3.52.978.979 2.152 1.468 3.521 1.468h20.07c1.37 0 2.544-.489 3.522-1.467.978-.978 1.467-2.152 1.467-3.521V5.488c0-1.37-.49-2.543-1.467-3.52C27.602.988 26.428.5 25.059.5H4.989c-1.37 0-2.544.489-3.522 1.467C.49 2.945 0 4.12 0 5.488v13.024zm2.523 0V5.488c0-.195.079-.528.235-.997l11.503 9.39c.156.156.41.234.762.234.196 0 .45-.078.763-.235l11.62-9.272c.078.235.117.528.117.88v13.024c0 .665-.244 1.252-.733 1.76-.49.509-1.066.763-1.731.763H4.989c-.666 0-1.243-.254-1.732-.763-.489-.508-.734-1.095-.734-1.76zm2.23-15.547h20.775l-10.505 8.392-10.27-8.392z"
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
