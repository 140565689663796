import { Markets } from "cf-constants";
import { Market } from "cf-types";
import { MarketActions } from "../actions";

type Action = {
  type: MarketActions;
  payload: Market[];
};

export const initialState = {
  currentMarket: [],
  markets: [],
  selectedMarket: [Markets.london],
};

const MarketReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case MarketActions.SET_MARKETS:
      return {
        ...state,
        markets: payload,
      };
    case MarketActions.SET_CURRENT_MARKET:
      return {
        ...state,
        currentMarket: payload,
      };
    case MarketActions.SET_SELECTED_MARKET:
      return {
        ...state,
        selectedMarket: payload,
      };
    default:
      return state;
  }
};

export default MarketReducer;
