import React from "react";
import styled from "styled-components";
import { Map, Marker } from "react-mapkit";
import { FullScreenToggle } from "./full-screen-toggle";

export interface Props {
  tokenOrCallback: string;
  className?: string;
  venueAddress: string;
  center: google.maps.LatLngLiteral;
  zoom?: number;
  fullAddress: string;
}

const UnstyledMaps: React.FC<Props> = ({
  tokenOrCallback,
  center,
  className,
  venueAddress,
  fullAddress,
}) => {
  return (
    <div className={className}>
      <h1>Location</h1>
      <p>{venueAddress}</p>
      {!["multiple locations", "various venues"].includes(
        venueAddress.toLowerCase()
      ) && (
        <div
          className={`${className}-map-container`}
          data-track-once-click={JSON.stringify({
            events: ["event235"],
            pev2: "View map",
          })}
        >
          <Map
            tokenOrCallback={tokenOrCallback}
            center={[center.lat, center.lng]}
            // @ts-ignore
            cameraDistance={2500}
            showsMapTypeControl={false}
          >
            <FullScreenToggle
              venueName={venueAddress}
              fullAddress={fullAddress}
            />
            <Marker latitude={center.lat} longitude={center.lng} />
          </Map>
        </div>
      )}
    </div>
  );
};

export const Maps = styled(UnstyledMaps)`
  max-width: 735px;
  position: relative;

  div[class$="map-container"] {
    width: 100%;
    aspect-ratio: 4/3;
  }
  h1 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.14;
    color: #1a1a1a;
    margin-top: 40px;
  }

  p {
    margin-bottom: 20px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.63;
    color: #1a1a1a;
  }

  @media (min-width: 768px) {
    div[class$="map-container"] {
      aspect-ratio: 16/9;
    }
  }
`;
