import { Event } from "src/components/to-components/types";

export const getEventPriceValues = (events: Event[]) => {
  const { minPrice, maxPrice, maxDiscount, maxFractionalDiscount } =
    events.reduce(
      (acc, value: Event) => {
        const difference = value.initialPrice - value.discountPrice;

        return {
          minPrice: Math.min(acc.minPrice, value.discountPrice),
          maxPrice: Math.max(acc.maxPrice, value.discountPrice),
          maxDiscount: Math.max(acc.maxDiscount, difference),
          maxFractionalDiscount: Math.max(
            acc.maxFractionalDiscount,
            difference / value.initialPrice
          ),
        };
      },
      {
        minPrice: Infinity,
        maxPrice: 0.0,
        maxDiscount: 0.0,
        maxFractionalDiscount: 0.0,
      }
    );

  return {
    minPrice,
    maxPrice,
    maxDiscount,
    maxPercentageDiscount: maxFractionalDiscount * 100,
  };
};
