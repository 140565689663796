import { useRouter } from "next/router";
import { useEffect } from "react";

type RouterEvent = "routeChangeComplete" | "routeChangeStart";

export const useRouterEvent = <T = unknown>(
  routerEvent: RouterEvent,
  listener: (url: string) => void,
  depsArray?: T[]
) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on(routerEvent, listener);
    return () => {
      router.events.off(routerEvent, listener);
    };
  }, depsArray);
};
