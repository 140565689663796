import React from "react";
import styled from "styled-components";
import { ArrowRightIcon } from "../../../global/iconography/arrows/arrow-icons";
import { device } from "../newsletter-signup-widget/device-size";
import { INDICATOR_HEIGHT } from "./constants";

type Props = {
  clickHandler: () => void;
  hasNext: boolean;
  label?: string;
};

const SliderRightArrow: React.FC<Props> = ({ clickHandler, hasNext }) => (
  <>
    {hasNext && (
      <Nav type="next" onClick={clickHandler}>
        <ArrowRightIcon darkMode={true} />
      </Nav>
    )}
  </>
);

const Nav = styled.div`
  display: flex;
  position: absolute;
  width: 55px;
  height: 55px;
  background-color: rgba(0, 0, 0, 0.6);
  top: calc(50% - ${INDICATOR_HEIGHT * 0.5}px);
  cursor: pointer;
  left: ${({ type }: { type: string }) => (type === "next" ? "auto" : "5px")};
  right: ${({ type }: { type: string }) => (type === "next" ? "5px" : "auto")};
  align-items: center;
  justify-content: center;

  transform: translateY(-50%);

  @media ${device.mobileL} {
    display: none;
  }
`;

export default SliderRightArrow;
