import { BookingActions, BookingStages, BookingConfirmed } from "../actions";
import { Basket, CustomError, ThirdPartyOptIn } from "cf-types";

type Action = {
  type: string;
  payload?: {
    basket?: Basket;
    confirmed?: BookingConfirmed;
    error?: CustomError;
    stages?: BookingStages;
    thirdPartyOptIn?: ThirdPartyOptIn;
  };
};

export const initialState = {
  basket: {},
  confirmed: {
    _extra: {},
    eventGroup: {},
    ticketGroups: [],
  },
  error: null,
  stage: BookingStages.BOOKING_INITIAL,
  thirdPartyDataShareConsent: false,
  thirdPartyOptIn: {},
};

const BookingReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case BookingActions.SET_BASKET: {
      return {
        ...state,
        basket: payload?.basket,
      };
    }
    case BookingActions.BOOKING_CONFIRMED: {
      return {
        ...state,
        confirmed: payload?.confirmed,
        stage: BookingStages.BOOKING_SUCCESS,
      };
    }
    case BookingActions.SET_THIRD_PARTY_OPT_IN: {
      return {
        ...state,
        thirdPartyOptIn: payload?.thirdPartyOptIn,
      };
    }
    case BookingActions.BOOKING_ERROR: {
      return {
        ...state,
        error: payload?.error,
        stage: BookingStages.BOOKING_ERROR,
      };
    }
    case BookingActions.RESET_BOOKING: {
      return {
        ...state,
        stage: BookingStages.BOOKING_INITIAL,
      };
    }
    case BookingActions.RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case BookingActions.SET_THIRD_PARTY_DATA_SHARE_CONSENT: {
      return {
        ...state,
        thirdPartyDataShareConsent: true,
      };
    }
    case BookingActions.RESET_THIRD_PARTY_DATA_SHARE_CONSENT: {
      return {
        ...state,
        thirdPartyDataShareConsent: false,
      };
    }
    default:
      return state;
  }
};

export default BookingReducer;
