import { forwardRef } from "react";
import styled from "styled-components";

interface Props {
  className?: string;
}

const UnstyledArrow = forwardRef<HTMLDivElement, Props>(
  ({ className }, ref) => {
    return <div data-popper-arrow ref={ref} className={className} />;
  }
);

export const Arrow = styled(UnstyledArrow)`
  z-index: -1;

  visibility: hidden;

  &,
  &::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  &::before {
    background-color: #ffffff;
    content: "";
    transform: rotate(45deg);
    visibility: visible;
  }
`;
