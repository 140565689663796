import styled from "styled-components";
import {
  OutingWidget,
  OutingWidgetProps,
} from "../outing-widget/outing-widget";

export interface OutingWidgetListProps {
  className?: string;
  outings: OutingWidgetProps[];
  onViewOrderClickCB: (
    e: React.FormEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
}

const unstyledOutingWidgetList = ({
  className,
  outings,
  onViewOrderClickCB,
}: OutingWidgetListProps) => (
  <div className={className}>
    {outings.map((outing: OutingWidgetProps, idx: number) => (
      <OutingWidget
        key={idx}
        {...outing}
        onViewOrderClickCB={onViewOrderClickCB}
      />
    ))}
  </div>
);

export const OutingWidgetList = styled(unstyledOutingWidgetList)``;
