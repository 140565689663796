import { gql } from "@apollo/client";

export const GetMarketsQuery = gql`
  query MarketsQuery($marketData: String!) {
    getMarkets(marketData: $marketData) {
      objects {
        categories {
          description
          slug
          title
        }
        currencyCode
        formattedSupportPhone
        latitude
        localeName
        longitude
        name
        pages {
          checkoutTerms
          cookiePolicy
          frequentlyAskedQuestions
          privacyPolicy
          separateSubscription
          termsAndConditions
          website
        }
        resourceUri
        siteId
        slug
        supportEmail
        supportPhone
        tier
        timezone
        twitter
      }
    }
  }
`;
