import { useRouterEvent } from "./use-router-event";

interface Link {
  action: (url: string) => void;
  matcher: (url: string) => boolean;
}

export const useDeepLink = (links: Link[]) => {
  useRouterEvent(
    "routeChangeStart",
    (url) => {
      links.forEach(({ action, matcher }) => {
        matcher(url) && action(url);
      });
    },
    []
  );
};
