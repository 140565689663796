import { gql } from "@apollo/client";

export const GetEventGroupQuery = gql`
  query EventGroupQuery($eventGroupData: String!) {
    getEventGroupDetails(eventGroupData: $eventGroupData) {
      action
      actionLink
      name
      description
      category
      eventGroupID
      error {
        fields
        message
        name
        stack
        status
      }
      eventType {
        title
        slug
        resourceUri
        categorySlug
      }
      events {
        resourceUri
        initialPrice
        initialPriceVisible
        hasInitialPrice
        discountPrice
        bookingFee
        priceWithoutFee
        startTime
        seatDescription
        ticketIncrement
        availableTickets
        performanceId
        stockLevel
        onsaleTime
        offsaleTime
        sellableItemId
      }
      location {
        latitude
        longitude
        venueName
        venueAddress
        venueLocality
        venueCity
        venueCountry
        postalCode
      }
      images {
        url
        webpUrl
      }
      redirectTo {
        resourceUri
        websiteUrl
        websitePath
      }
      termsUrl
      url
      currency
      currencyCode
      overrideMetaTitle
      overrideMetaDescription
      socialDescription
      marketSlug
      previewOnsaleTime
      purchaseType
      ctaText
      ctaLink
      shippable
      resourceUri
      tagLine
    }
  }
`;
