import { Analytics } from "cf-constants";
import { HttpStatusCode } from "cf-types";

type Param = {
  cleanRoute: string[];
  event: string;
  market: string;
  navMenu: { [key: string]: string };
};

const routePageNameMap: { [k: string]: string } = {
  [HttpStatusCode.NOT_FOUND]: Analytics.PageType.NOT_FOUND,
  checkout: Analytics.PageName.CHECKOUT_DETAILS,
  details: Analytics.PageName.DETAILS,
  orders: Analytics.PageName.TICKETS_LIST,
  search: Analytics.PageName.SEARCH,
};

export const getPageName = ({
  event,
  cleanRoute,
  market,
  navMenu,
}: Param): string => {
  const [firstRoute, secondRoute] = cleanRoute;
  const title = navMenu?.[secondRoute];

  if (title) {
    return `${Analytics.PageName.COLLECTION} : ${title}`;
  }

  const { length } = cleanRoute;

  if (!length || (length === 1 && firstRoute === market)) {
    return Analytics.PageType.HOME;
  }

  return (
    routePageNameMap?.[firstRoute] ??
    routePageNameMap?.[secondRoute] ??
    routePageNameMap?.[cleanRoute?.[2]] ??
    `${Analytics.PageName.EVENT_DETAILS} : ${event.replace(/-/g, " ")}`
  );
};
