import { IconsProps } from "../icons-types";

export const SearchIcon = ({ darkMode }: IconsProps) => (
  <svg
    width={23}
    height={22}
    viewBox="0 0 23 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/Search"}</title>
    <path
      d="M8.975.5c2.513 0 4.645.876 6.397 2.628C17.124 4.88 18 6.996 18 9.475c0 2.042-.579 3.833-1.736 5.373l6.125 6.127a1 1 0 01-1.414 1.414L14.9 16.315C13.24 17.772 11.266 18.5 8.975 18.5c-2.48 0-4.595-.876-6.347-2.628C.876 14.12 0 11.988 0 9.475c0-2.48.876-4.595 2.628-6.347C4.38 1.376 6.496.5 8.975.5zm6.893 8.975c0-1.884-.67-3.496-2.008-4.835-1.34-1.338-2.967-2.008-4.885-2.008-1.884 0-3.496.67-4.835 2.008-1.338 1.34-2.008 2.95-2.008 4.835 0 1.918.67 3.546 2.008 4.885 1.34 1.338 2.95 2.008 4.835 2.008 1.918 0 3.546-.67 4.885-2.008 1.338-1.34 2.008-2.967 2.008-4.885z"
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
