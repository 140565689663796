export enum AppActions {
  SET_DEVICE_DATA = "set_device_data",
  SET_IS_LOADING = "set_is_loading",
}

export type Device = {
  deviceId: string;
  supportEmail: string;
};

export interface AppParams {
  type: AppActions.SET_DEVICE_DATA | AppActions.SET_IS_LOADING;
  payload: Device | { isLoading: boolean };
}

export const setDeviceData = (payload: Device): AppParams => {
  return {
    type: AppActions.SET_DEVICE_DATA,
    payload,
  };
};

export const setIsLoading = (payload: { isLoading: boolean }): AppParams => {
  return {
    type: AppActions.SET_IS_LOADING,
    payload,
  };
};
