import styled from "styled-components";

export interface LegalTextProps {
  className?: string;
  text: string;
}

const UnstyledLegalText = ({ className, text }: LegalTextProps) => (
  <div className={className}>
    <p>{text}</p>
  </div>
);

export const LegalText = styled(UnstyledLegalText)`
  p {
    width: 309px;
    height: 54px;
    margin: 30px 0 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #aaaaaa;
  }

  @media (min-width: 768px) {
    p {
      width: 515px;
    }
  }
`;
