import { CategoryWidget } from "../category-widget";
import { ProgressBar } from "../../../../global/progress-bar";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "../../button/button";

export interface CategoryProductListProps {
  className?: string;
  categoryProducts: any[];
  limit?: number;
  title?: string;
  displayCount?: boolean;
  productSelectionEventData: { [key in string]: string | string[] };
  tracker?: {
    onProductClick: (index: number) => void;
    onProductInView?: (index: number) => void;
  };
  onProductClickGTM?: (index: number) => void;
}

const unstyledCategoryProductList = ({
  className,
  categoryProducts = [],
  limit = 40,
  title = "All offers",
  displayCount = true,
  productSelectionEventData,
  tracker,
}: CategoryProductListProps) => {
  const [offset, setOffset] = useState(0);

  const productLength = categoryProducts.length;
  const viewedProducts = categoryProducts.slice(0, offset + limit);

  return (
    <div className={className}>
      {displayCount && (
        <span className={`${className}-total-count`}>
          {`${title} (${productLength})`}
        </span>
      )}
      <div className={`${className}-product-list`}>
        {viewedProducts.map((cat, idx) => (
          <CategoryWidget
            {...cat}
            index={idx}
            key={idx}
            productSelectionEventData={productSelectionEventData}
            tracker={tracker}
          />
        ))}
      </div>
      <div className={`${className}-progress-count`}>
        <span>
          You are viewing {viewedProducts.length} of {productLength} offers
        </span>
        <ProgressBar
          width={Math.round((viewedProducts.length / productLength) * 100)}
        />
      </div>
      {viewedProducts.length < productLength && (
        <div className={`${className}-load-more-button`}>
          <Button
            onClick={() => {
              setOffset((currOffset) => limit + currOffset);
              window.setTimeout(
                () =>
                  window.scrollBy({
                    top: document.querySelector(
                      'div[class$="product-list"] > div'
                    )?.clientHeight,
                    behavior: "smooth",
                  }),
                0
              );
            }}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export const CategoryProductList = styled(unstyledCategoryProductList)`
  span[class$="total-count"] {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    color: #1a1a1a;
  }

  div[class$="product-list"] {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  div[class$="product-list"] > div {
    box-sizing: border-box;
    justify-content: flex-start;
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  div[class$="progress-count"] {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    align-items: center;
  }

  div[class$="progress-count"] > span {
    margin-bottom: 10px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #7d7d7d;
  }

  div[class$="load-more-button"] {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
  }

  button {
    width: 220px;
    height: 50px;
    padding: 13px 30.5px;
    border: solid 2px #1a1a1a;
    background-color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
    text-align: center;
    color: #1a1a1a;
    cursor: pointer;
  }

  @media (min-width: 450px) {
    div[class$="product-list"] > div {
      flex-basis: 50%;
    }
  }

  @media (min-width: 768px) {
    div[class$="product-list"] {
      flex-direction: row;
      row-gap: 80px;
    }

    span[class$="total-count"] {
      display: flex;
      justify-content: center;
    }

    div[class$="product-list"] > div {
      flex-basis: 33.3%;
      margin-bottom: 0px;
    }
  }

  @media (min-width: 1024px) {
    div[class$="product-list"] > div {
      flex-basis: 25%;
    }
  }
`;
