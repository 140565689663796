import styled from "styled-components";
import { Button } from "../button/button";

export interface Props {
  className?: string;
  onClick: () => void;
  privacyNoticeUrl: string;
  shopTermsUrl: string;
  termsUrl: string;
  totalPrice: string;
}

const UnstyledOrderSummaryFooter = ({
  className,
  onClick,
  privacyNoticeUrl,
  shopTermsUrl,
  termsUrl,
  totalPrice = "0.00",
}: Props) => {
  const buttonTitle =
    totalPrice === "Free" ? "Claim tickets" : "Complete order";

  return (
    <div className={className}>
      <div className={`${className}-order-button`}>
        <div className={`${className}-order-summary--text`}>
          <span>Total</span>
          <h2>{totalPrice}</h2>
        </div>
        <Button onClick={onClick} qa={"order-summary-footer"}>
          {buttonTitle}
        </Button>
        <p className={`${className}-terms`}>
          By completing this order I agree to the{" "}
          <a href={termsUrl} target={"_blank"}>
            Time Out terms of use
          </a>
          ,{" "}
          <a href={privacyNoticeUrl} target={"_blank"}>
            privacy notice
          </a>{" "}
          and{" "}
          <a href={shopTermsUrl} target={"_blank"}>
            Time Out Shop’s T&Cs
          </a>
        </p>
      </div>
    </div>
  );
};

export const OrderSummaryFooter = styled(UnstyledOrderSummaryFooter)`
  background-color: #ffffff;

  a {
    color: #d31a22;
    text-decoration: none;
  }

  p[class$="terms"] {
    font-family: Roboto;
    font-size: 11px;
    line-height: 1.55;
    color: #7d7d7d;
    margin-top: 15px;
    margin-bottom: 0;
  }

  button {
    width: 100%;
    height: 60px;
    background-color: #1a1a1a;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  button:hover,
  button:focus {
    background-color: #d31a22;
  }

  div[class$="order-summary--text"] h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 32px;
    line-height: 1.13;
    color: #1a1a1a;
  }

  div[class$="order-summary--text"] {
    height: 80px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
