import { EventPageMetaData } from "cf-types";
import React from "react";

export const metaDataInitialState = {
  images: [],
  overrideMetaDescription: "",
  overrideMetaTitle: "",
  socialDescription: "",
  url: "",
};

export const MetaDataContext = React.createContext<
  [EventPageMetaData, React.Dispatch<React.SetStateAction<EventPageMetaData>>]
>([metaDataInitialState, () => {}]);
