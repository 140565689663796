import { useEffect } from "react";
import { TCFData } from "../../../types";
import { initCMP } from "./utils";

interface Props {
  onConsent: (e: TCFData) => void;
}

export const CookieBanner: React.FC<Props> = ({ onConsent }: Props) => {
  useEffect(() => {
    initCMP(onConsent);
  }, []);

  return null;
};
