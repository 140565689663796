import {
  client as braintree,
  Client,
  HostedFieldFieldOptions,
  HostedFieldsTokenizePayload,
  HostedFields,
  hostedFields,
  ThreeDSecure,
  threeDSecure,
  ThreeDSecureVerifyPayload,
} from "braintree-web";
import { Configuration } from "braintree-web/modules/client";
import {
  HostedFieldsEvent,
  HostedFieldsState,
  HostedFieldEventType,
  HostedFieldsEventTypeMap,
} from "braintree-web/modules/hosted-fields";

export let hostedFieldInstance: HostedFields,
  threeDSecInstance: ThreeDSecure,
  client: Client;

const _perform3DSec = async (
  amount: number,
  secure3dsChallengeRequested: boolean,
  { details, nonce }: HostedFieldsTokenizePayload
): Promise<ThreeDSecureVerifyPayload> => {
  return await threeDSecInstance.verifyCard({
    amount,
    bin: details.bin,
    challengeRequested: secure3dsChallengeRequested,
    nonce,
  });
};

export type Token = Pick<
  ThreeDSecureVerifyPayload | HostedFieldsTokenizePayload,
  "details" | "nonce"
>;

type Options = {
  billingAddress?: {
    locality?: string;
    postalCode?: string;
    streetAddress?: string;
  };
  cardholderName?: string;
  vault?: boolean;
};

export type PaymentGatewayInstance = {
  configuration: Configuration;
  getFields: () => HostedFieldsState;
  offEvent: (
    event: HostedFieldEventType,
    callBack?: (e: HostedFieldsEvent) => void
  ) => void;
  onEvent: <T extends HostedFieldEventType>(
    event: T,
    callBack: (e: HostedFieldsEventTypeMap[T]) => void
  ) => void;
  tokenize: (amount: number, options?: Options) => Promise<Token>;
};

const tokenize =
  (is3dsSecEnabled: boolean, secure3dsChallengeRequested: boolean) =>
  async (amount: number, options?: Options): Promise<Token> => {
    const payload = await hostedFieldInstance.tokenize(options);

    if (threeDSecInstance && is3dsSecEnabled) {
      const { nonce } = await _perform3DSec(
        amount,
        secure3dsChallengeRequested,
        payload
      );

      return {
        details: payload.details,
        nonce,
      };
    }

    return {
      details: payload.details,
      nonce: payload.nonce,
    };
  };

const getFields = () => hostedFieldInstance.getState();

const onEvent = <T extends HostedFieldEventType>(
  event: T,
  callBack: (e: HostedFieldsEventTypeMap[T]) => void
) => {
  hostedFieldInstance.on(event, callBack);
};

const offEvent = (
  event: HostedFieldEventType,
  callBack?: (e: HostedFieldsEvent) => void
) => {
  hostedFieldInstance.off(event, callBack ?? console.log);
};

export const init = async (
  authorization: string,
  {
    fields,
    styles = {},
    is3dsSecEnabled,
    secure3dsChallengeRequested = true,
  }: {
    fields: HostedFieldFieldOptions;
    styles?: { [key: string]: { [key: string]: string } };
    is3dsSecEnabled: boolean;
    secure3dsChallengeRequested?: boolean;
  }
): Promise<PaymentGatewayInstance> => {
  try {
    client = await braintree.create({ authorization });
    hostedFieldInstance = await hostedFields.create({
      client,
      fields,
      styles,
    });

    if (is3dsSecEnabled) {
      threeDSecInstance = await threeDSecure.create({
        client,
        version: 2,
      });

      threeDSecInstance?.on &&
        threeDSecInstance.on("lookup-complete", (_, next) => {
          next && next();
        });
    }
  } catch (error) {
    throw error;
  } finally {
    return {
      configuration: client.getConfiguration(),
      getFields,
      offEvent,
      onEvent,
      tokenize: tokenize(is3dsSecEnabled, secure3dsChallengeRequested),
    };
  }
};
