import {
  DeliveryAddressFields,
  FormFields,
  PaymentFields,
  SecurePaymentFields,
} from "../types";

export enum CheckoutActions {
  BULK_UPDATE_BILLING = "bulk_update_billing",
  BULK_UPDATE_DELIVERY = "bulk_update_delivery",
  UPDATE_DELIVERY_ADDRESS = "update_delivery_address",
  UPDATE_BILLING_ADDRESS = "update_billing_address",
}

interface UpdateDeliveryAddress {
  type: CheckoutActions.UPDATE_DELIVERY_ADDRESS;
  payload: CheckoutPayload;
}

export interface UpdateBillingAddress {
  type: CheckoutActions.UPDATE_BILLING_ADDRESS;
  payload: CheckoutPayload;
}

export const updateDeliveryAddress = (
  payload: CheckoutPayload
): UpdateDeliveryAddress => {
  return {
    type: CheckoutActions.UPDATE_DELIVERY_ADDRESS,
    payload,
  };
};

type Delivery = {
  address: string;
  recipientName: string;
  state: string;
  townCity: string;
  zipcode: string;
};

type Payment = {
  billingAddress: string;
  cardholderName: string;
  cvv: null;
  expirationDate: null;
  number: null;
  postalCode: null;
  state: string;
  townCity: string;
};

export type CheckoutPayload = {
  validator?: () => boolean;
  value?: string;
  field?: DeliveryAddressFields | PaymentFields | SecurePaymentFields;
  fields?: Partial<FormFields<Payment | Delivery>>;
};

export type CheckoutState = {
  billing: FormFields<Payment>;
  delivery: FormFields<Delivery>;
};

export interface BulkUpdateAddress {
  type:
    | CheckoutActions.BULK_UPDATE_BILLING
    | CheckoutActions.BULK_UPDATE_DELIVERY;
  payload: {
    fields: Partial<FormFields<Payment>>;
  };
}

export const updateBulkBillingAddress = (payload: {
  fields: Partial<FormFields<Payment>>;
}): BulkUpdateAddress => {
  return {
    type: CheckoutActions.BULK_UPDATE_BILLING,
    payload,
  };
};

export const updateBulkDeliveryAddress = (payload: {
  fields: Partial<FormFields<Payment>>;
}): BulkUpdateAddress => {
  return {
    type: CheckoutActions.BULK_UPDATE_DELIVERY,
    payload,
  };
};

export const updateBillingAddress = (
  payload: CheckoutPayload
): UpdateBillingAddress => {
  return {
    type: CheckoutActions.UPDATE_BILLING_ADDRESS,
    payload,
  };
};
