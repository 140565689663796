import {
  ContactFields,
  DeliveryAddressFields,
  PaymentFields,
  SecurePaymentFields,
  Store,
} from "redux/types";
import { Field, HostedFieldsState } from "../../src/components/to-components";

const validEmailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const numberRegex = /^[0-9]+$/;
const alphabetRegex = /^[A-Za-z'\s-]+$/;

type RequiredUser = Pick<Store["User"], "email" | "firstName" | "lastName">;
type RequiredBilling = Pick<
  Store["Checkout"]["billing"],
  "billingAddress" | "cardholderName" | "townCity"
>;
type RequiredDelivery = Pick<
  Store["Checkout"]["delivery"],
  "address" | "recipientName" | "state" | "townCity" | "zipcode"
>;
type BillingOptions = { is3dsEnabled: boolean; renderExtraFields: boolean };

export const validateUser = (user: Store["User"]) => {
  return ["email", "firstName", "lastName"].reduce<Partial<RequiredUser>>(
    (acc, val) => {
      const field = user[val as ContactFields];
      const error = getFieldError(field, field.value);
      return (acc = {
        ...acc,
        [val]: { ...field, error },
      });
    },
    {}
  );
};

export const validateDelivery = (delivery: Store["Checkout"]["delivery"]) => {
  return ["recipientName", "address", "townCity", "state", "zipcode"].reduce<
    Partial<RequiredDelivery>
  >((acc, val) => {
    const field = delivery[val as DeliveryAddressFields];
    const error = getFieldError(field, field.value);
    return (acc = {
      ...acc,
      [val]: { ...field, error },
    });
  }, {});
};

export const validateBilling = (
  billing: Store["Checkout"]["billing"],
  options: BillingOptions
) => {
  return ["cardholderName", "billingAddress", "townCity"].reduce<
    Partial<RequiredBilling>
  >((acc, val) => {
    const name = val as PaymentFields;
    const field = billing[name];
    const { is3dsEnabled, renderExtraFields } = options;
    const error =
      (renderExtraFields &&
        (name === "billingAddress" || name === "townCity")) ||
      (!renderExtraFields && name === "cardholderName")
        ? getFieldError({ ...field, required: is3dsEnabled }, field.value)
        : field.error;

    return Object.assign(acc, { [val]: { ...field, error } });
  }, {});
};

export const validate = (
  is3dsSecEnabled: boolean,
  {
    billing,
    fieldState,
  }: Pick<Store["Checkout"], "billing"> & {
    fieldState: HostedFieldsState | undefined;
  }
) => {
  const field = fieldState?.fields;
  const postalCode = is3dsSecEnabled
    ? !field?.postalCode?.isEmpty && !billing.postalCode.error
    : true;

  return (
    ["cvv", "expirationDate", "number"].every((key) => {
      return (
        !field?.[key as SecurePaymentFields]?.isEmpty &&
        !billing[key as PaymentFields].error
      );
    }) && postalCode
  );
};

export const isValidEmail = (email: string) => validEmailRegex.test(email);

export const isValidName = (text: string) => alphabetRegex.test(text);

export const isValidPhone = (text: string) => numberRegex.test(text);

export const getFieldError = (
  {
    required,
    validator,
  }: Pick<Field<string | boolean>, "required" | "validator">,
  value: string | boolean | undefined
): boolean => {
  if (typeof value === "boolean" || (!required && !value)) {
    return false;
  }

  if (required && typeof value === "string" && !value) {
    return true;
  }

  return !!(validator && !validator(value));
};

export const validateInput = (
  value: string,
  validator: (text: string) => boolean
): boolean => !!value && !validator(value);
