import { TCFData } from "../../../../types";

/**
 * once CMP solution is loaded, it communicates with the TCF API via sendMessage
 * this method handles all those CMP messages and sends them to the right TCF
 * API handler
 * @param event
 * @constructor
 */

export const CMPEventHandler =
  (onConsent: (p: TCFData) => void) => (event: MessageEvent) => {
    let eventData;
    if (typeof event.data === "string") {
      try {
        eventData = JSON.parse(event.data);
      } catch (ignore) {
        //ignore
      }
    } else {
      eventData = event.data;
    }

    if (!eventData || !eventData.__tcfapiCall) {
      return;
    }

    const payload = eventData.__tcfapiCall;

    window?.__tcfapi(
      payload.command,
      payload.version,
      (retValue) => {
        onConsent(retValue);
      },
      payload.parameter
    );
  };
