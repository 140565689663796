import { Token } from "../../src/components/to-components";

export enum CardActions {
  SET_TOKEN = "set_token",
}

interface SetToken {
  type: CardActions.SET_TOKEN;
  payload: Token | string;
}

export const setPaymentToken = (payload: Token | string): SetToken => {
  return {
    type: CardActions.SET_TOKEN,
    payload,
  };
};
