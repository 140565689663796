import { createPopper } from "@popperjs/core";
import type { Instance, Placement } from "@popperjs/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Arrow } from "./arrow";

type Trigger = "hover" | "click" | "default";

const id = { id: "target-element" };
export interface Props {
  children?: React.ReactNode;
  className?: string;
  message?: React.ReactNode;
  onSetToolTip?: (toolTip: Instance) => void;
  placement?: Placement;
  targetElemRef: React.RefObject<HTMLElement>;
  trigger?: Trigger;
}

const UnstyledToolTip: React.FC<Props> = ({
  children,
  className,
  message,
  placement = "top",
  onSetToolTip,
  targetElemRef,
  trigger = "default",
}: Props) => {
  const toolTipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const [popperInstance, setPopperInstance] = useState<Instance>();
  const [attr, setAttr] = useState({});

  useEffect(() => {
    if (targetElemRef.current && toolTipRef?.current) {
      setPopperInstance(
        createPopper(targetElemRef?.current, toolTipRef?.current, {
          placement,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        })
      );
    }
  }, [targetElemRef]);

  useEffect(() => {
    popperInstance && onSetToolTip && onSetToolTip(popperInstance);
  }, [popperInstance]);

  const onShow = () => {
    setAttr((state) => {
      return "data-show" in state ? {} : { "data-show": "" };
    });

    popperInstance?.update();
  };

  const onHide = () => {
    setAttr({});
  };

  const propsMap: { [k in Trigger]: { [k in string]: (() => void) | string } } =
    {
      click: {
        ...id,
        onClick: onShow,
      },
      default: id,
      hover: {
        ...id,
        onMouseEnter: onShow,
        onMouseLeave: onHide,
      },
    };

  return (
    <React.Fragment>
      {React.createElement("div", propsMap[trigger], children)}
      <div {...attr} className={className} ref={toolTipRef} role={"tooltip"}>
        {message}
        <Arrow ref={arrowRef} />
      </div>
    </React.Fragment>
  );
};

export const ToolTip = styled(UnstyledToolTip)`
  &[data-show] {
    display: block;
  }

  display: ${({ trigger }) => {
    if (trigger === "hover" || trigger === "click") {
      return "none";
    }

    return "block";
  }};

  font-family: Roboto;

  box-shadow: 0 2px 20px 8px rgb(0 0 0 / 12%);

  &[data-popper-placement^="top"] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^="left"] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^="right"] > ${Arrow} {
    left: -4px;
  }
`;
