import { useState } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../global/iconography/search/search-icon";
import { InputField } from "../input-field/input-field";

export interface SearchBarProps {
  className?: string;
  placeholderText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  searchTerm?: string;
}

const UnstyledSearchBarMobile = ({
  className,
  placeholderText = "Search by event, area or venue",
  onChange,
  onSubmit,
  searchTerm,
}: SearchBarProps) => {
  const [isFocused, setFocus] = useState(false);

  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };

  const style = `${isFocused ? "focused" : ""}`;
  return (
    <div className={className}>
      <form onSubmit={onSubmit}>
        <SearchIcon />
        <InputField
          className={style}
          placeholder={placeholderText}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={searchTerm}
        />
      </form>
    </div>
  );
};

export const SearchBarMobile = styled(UnstyledSearchBarMobile)`
  form {
    display: flex;
    align-items: center;
    padding: 15px;

    border-radius: 5px;
    background-color: #ffffff;
  }

  input {
    flex: 1;
    padding-left: 20px;

    font-family: Roboto;
    font-size: 16px;
    color: #1a1a1a;

    border: none;
  }

  input::placeholder {
    color: #7d7d7d;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
`;
