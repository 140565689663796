import { MetaDataContext } from "cf-context";
import Head from "next/head";
import { useContext } from "react";
import { siteMetaData } from "static-data";

const MetaData = () => {
  const [metaData] = useContext(MetaDataContext);

  return (
    <Head>
      <title>{metaData.overrideMetaTitle}</title>
      {siteMetaData.map(({ content, name, property }) => {
        const attr = {
          content: typeof content === "function" ? content(metaData) : content,
          ...(name ? { name } : { property }),
        };

        return <meta {...attr} key={name || property} />;
      })}
      <link
        rel="icon"
        type="image/x-icon"
        href="https://d2v0kxdivqelbo.cloudfront.net/static/release-15311/website/images/favicon.ico"
      />
      <link
        rel="search"
        type="application/opensearchdescription+xml"
        href="/opensearch.xml"
        title="Time Out Search"
      />
    </Head>
  );
};

export default MetaData;
