export const DropdownIcon = () => (
  <svg
    width={23}
    height={18}
    viewBox="0 0 23 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Atoms/Icons/Menu"}</title>
    <path
      d="M21.61 15.545c.642 0 1.162.55 1.162 1.228 0 .678-.52 1.227-1.161 1.227H1.16C.522 18 0 17.45 0 16.773c0-.678.52-1.228 1.162-1.228zm0-7.772c.642 0 1.162.55 1.162 1.227 0 .678-.52 1.227-1.161 1.227H1.16C.522 10.227 0 9.677 0 9c0-.678.52-1.227 1.162-1.227zm0-7.773c.642 0 1.162.55 1.162 1.227 0 .678-.52 1.228-1.161 1.228H1.16C.522 2.455 0 1.905 0 1.227 0 .55.52 0 1.162 0z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
