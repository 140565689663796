import styled from "styled-components";

export interface Item {
  title: string;
  unit: number;
  price: string;
}

export interface Props extends Item {
  className?: string;
}

const UnstyledRendererHOC =
  (currency: string) =>
  ({ className, price, title, unit }: Props) => {
    const content = `${unit} x tickets (${currency}${price})`;
    return (
      <div className={className}>
        <div className={`${className}-text`}>
          <span className={`${className}-title`}>{title}</span>
          <span className={`${className}-content`}>{content}</span>
        </div>
      </div>
    );
  };

export const RendererHOC = (currency: string) => styled(
  UnstyledRendererHOC(currency)
)`
  display: flex;
  align-items: center;

  div[class$="text"] {
    display: flex;
    flex-direction: column;
    padding: 20px;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.63;

    @media (max-width: 425px) {
      padding: 0 16px 16px;
    }
  }

  span[class$="title"] {
    font-weight: 700;
  }

  @media (max-width: 425px) {
    align-items: flex-start;
  }
`;
