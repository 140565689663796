import { Analytics } from "cf-constants";
import { GoogleTagManager, Market } from "cf-types";
import { cookieUtils } from "cf-utils";
import { getPageName } from "./get-page-name";
import { getPageType } from "./get-page-type";
import { getSubSection } from "./get-sub-section";
import { normaliseQueryParam } from "./normalise-query-param";

export const dimensionsFacade = (
  menu: Market["categories"],
  { url, query }: GoogleTagManager.Route
): GoogleTagManager.Dimension => {
  const navMenu = menu.reduce<{ [key: string]: string }>(
    (acc, { slug, title }) => {
      acc[slug] = title;
      return acc;
    },
    {}
  );
  const { event, market } = query;
  const cleanRoute = url.split(/\?/g)?.[0].split("/").filter(Boolean);
  const searchParams = new URLSearchParams(window.location.search);
  const subSection = getSubSection(cleanRoute, navMenu).toLowerCase();
  const pageType = getPageType(subSection, market, cleanRoute);
  const pageName = getPageName({
    cleanRoute,
    event: normaliseQueryParam(event),
    market,
    navMenu,
  }).toLowerCase();

  return {
    platform: Analytics.platform,
    section: Analytics.section,
    subSection,
    pageType,
    pageName: `${Analytics.section} : ${pageName}`,
    siteCity: market,
    campaign:
      searchParams
        .get("cid")
        ?.split(/~crmid/g)?.[0]
        ?.toLowerCase() ?? "",
    canaryId: cookieUtils.getCookie(Analytics.canaryCookieName),
  };
};
