import { gql } from "@apollo/client";

export const GetCategoryEventGroupsQuery = gql`
  query CategoryEventsQuery($categoryData: String!) {
    getCategoryEvents(categoryData: $categoryData) {
      error {
        fields
        message
        name
        stack
        status
      }
      heroImage
      headerTitle
      headerSubtitle
      items {
        websitePath
        images {
          url
          webpUrl
        }
        name
        events {
          initialPrice
          discountPrice
          startTime
        }
        venues {
          latitude
          longitude
          venueName
          venueAddress
          venueLocality
          venueCity
          venueCountry
          postalCode
        }
        currency
        currencyCode
        purchaseType
        category
        eventGroupID
      }
      overrideMetaDescription
      overrideMetaName
      overrideMetaTitle
      url
      images {
        url
      }
    }
  }
`;
