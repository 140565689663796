import { gql } from "@apollo/client";

export const PostWaitingListQuery = gql`
  query PostWaitingListQuery($eventData: String!) {
    postWaitingList(eventData: $eventData) {
      error {
        fields
        message
        name
        stack
        status
      }
      _extra {
        message
      }
    }
  }
`;
