import React from "react";
import styled from "styled-components";

interface SubscriptionSuccessProps {
  darkMode?: boolean;
}

export const SubscriptionSuccess = ({
  darkMode = true,
}: SubscriptionSuccessProps) =>
  darkMode ? (
    <div data-track="newsletter-signup">
      <H3>Awesome, you're subscribed!</H3>
      <SubText>Look out for your first newsletter in your inbox soon.</SubText>
    </div>
  ) : (
    <div data-track="newsletter-signup">
      <H3Light>Awesome, you're subscribed!</H3Light>
      <SubTextLight>
        Look out for your first newsletter in your inbox soon.
      </SubTextLight>
    </div>
  );

const H3 = styled.h3`
  height: 72px;
  margin: 0 0 15px;
  font-family: FranklinGothicLTPro-DmCm;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  @media (max-width: 999px) {
    text-align: center;
  }
  color: #fff;
`;

const H3Light = styled.h3`
  height: 72px;
  margin: 0 0 15px;
  font-family: FranklinGothicLTPro-DmCm;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  @media (max-width: 999px) {
    text-align: center;
  }
  color: #1a1a1a;
`;

const SubText = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin-top: -20px;
  color: #ffffff;
  font-family: "Roboto";
  text-align: center;
`;

const SubTextLight = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin-top: -20px;
  color: #1a1a1a;
  font-family: "Roboto";
  text-align: center;
`;
