import { IconsProps } from "../icons-types";

export const CardIcon = ({ darkMode = false }: IconsProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={16}>
    <path
      d="M23.096 2.2c0-1.213-.985-2.2-2.201-2.2L2.2.02C1.611.02 1.058.25.642.665c-.416.416-.644.97-.642 1.555L.011 13.8A2.2 2.2 0 0 0 2.207 16h.005l18.694-.018c1.214 0 2.2-.989 2.2-2.205l-.01-11.576zM2.009 2.03c.03-.031.094-.081.192-.081l18.699-.02c.147 0 .265.122.265.273l.002.792H1.93l-.001-.776c0-.09.043-.154.079-.189zm18.896 12.022L2.21 14.07l-.003.965v-.965a.266.266 0 0 1-.266-.27l-.007-7.975H21.17l.008 7.954a.27.27 0 0 1-.272.273z"
      fill={darkMode ? "#ffffff" : "#aaa"}
      fillRule="nonzero"
    />
  </svg>
);
