import { gql } from "@apollo/client";

export const GetEventGroupSimilarQuery = gql`
  query EventGroupSimilarQuery($eventGroupData: String!) {
    getEventGroupDetailsSimilar(eventGroupData: $eventGroupData) {
      items {
        action
        actionLink
        ctaText
        ctaLink
        category
        eventGroupID
        currency
        currencyCode
        images {
          url
        }
        name
        events {
          initialPrice
          discountPrice
          startTime
        }
        previewOnsaleTime
        purchaseType
        venues {
          latitude
          longitude
          venueName
          venueAddress
          venueLocality
          venueCity
          venueCountry
          postalCode
        }
        shippable
        websitePath
      }
    }
  }
`;
