import React from "react";
import parse from "html-react-parser";

type Image = {
  url: string;
};

type VenueLocation = {
  latitude: number;
  longitude: number;
  venueName: string;
  venueAddress: string;
  venueLocality: string;
  venueCity: string;
  venueCountry: string;
  postalCode: string;
};

type Event = {
  availableTickets: number;
  bookingFee: number;
  discountPrice: number;
  hasInitialPrice: boolean;
  initialPrice: number;
  initialPriceVisible: boolean;
  offsaleTime: number;
  onsaleTime: number;
  performanceId: number;
  priceWithoutFee: number;
  resourceUri: string;
  seatDescription: string;
  sellableItemId: number;
  startTime: number;
  stockLevel: number;
  ticketIncrement: number;
};

enum EventSchemaTypes {
  cinema = "ScreeningEvent",
  comedy = "ComedyEvent",
  culture = "SocialEvent",
  music = "MusicEvent",
  nightlife = "DanceEvent",
  performance = "VisualArtsEvent",
  sport = "SportsEvent",
  tasting = "FoodEvent",
  theatre = "TheaterEvent",
  venue_hire = "VenueHireEvent",
}

type EventType = {
  title: string;
  slug: string;
  resourceUri: string;
  categorySlug: keyof typeof EventSchemaTypes;
};

interface EventGroup {
  name: string;
  description: string;
  eventType: EventType;
  location?: VenueLocation;
  images: Array<Image>;
  url: string;
  events: Array<Event>;
  currencyCode: string;
  overrideMetaTitle: string;
  marketSlug: string;
}

const formatStartDate = (dateTimestamp: number): string => {
  const date = new Date(dateTimestamp * 1000);
  const isoDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
  return isoDateTime;
};

const isExpired = (event: Event) =>
  !event || event.offsaleTime * 1000 < Date.now();

const isSoldOut = (events: Array<Event>) =>
  events && events.every((evt) => evt.availableTickets === 0);

const eventTimeInWindow = (
  event: Event,
  start: number,
  end: number
): boolean => {
  const dateInMs = event.startTime * 1000;
  return dateInMs > start && dateInMs < end;
};

const commonOffers = (data: any, startDate: string) => ({
  category: data["@type"],
  availability: "https://schema.org/InStock",
  url: `${data.url}?performance=${startDate}`,
});

const multiEvent = (events: [Event], props: EventGroup, data: any) => {
  const eligibleQuantity = events[0].ticketIncrement;
  const inventoryLevel = events.reduce(
    (memo, event) => memo + event.availableTickets,
    0
  );
  const { currencyCode: priceCurrency } = props;
  const startDate = formatStartDate(events[0].startTime);
  const prices = events.map(
    (event: Event) => event.discountPrice * event.ticketIncrement
  );
  const highPrice = Math.max(...prices);
  const lowPrice = Math.min(...prices);
  const offerCount = events.length;

  data.startDate = startDate;
  data.offers = commonOffers(data, startDate);

  Object.assign(data.offers, {
    "@type": "AggregateOffer",
    eligibleQuantity,
    inventoryLevel,
    highPrice,
    lowPrice,
    offerCount,
    priceCurrency,
  });

  return data;
};

const event = (event: Event, eventGroup: EventGroup, data: any) => {
  const eligibleQuantity = event.ticketIncrement;
  const inventoryLevel = event.availableTickets;
  const name = event.ticketIncrement;
  const price = event.discountPrice * event.ticketIncrement;
  const { currencyCode: priceCurrency } = eventGroup;
  const startDate = formatStartDate(event.startTime);

  data.startDate = startDate;
  data.offers = commonOffers(data, startDate);

  Object.assign(data.offers, {
    "@type": "Offer",
    eligibleQuantity,
    inventoryLevel,
    name,
    price,
    priceCurrency,
  });

  return data;
};

export const EventGroupStructuredData: React.FC<EventGroup> = (props) => {
  if (!props.events) {
    return null;
  }

  const place = {
    name: props.location?.venueName,
    address: {
      "@type": "PostalAddress",
      streetAddress: props.location?.venueAddress,
      addressLocality: props.location?.venueLocality,
      addressRegion: props.location?.venueCity,
      postalCode: props.location?.postalCode,
      addressCountry: props.location?.venueCountry,
    },
  };

  const image = `${props.images[0].url}?width=960`;
  const name = props.name;
  const type = EventSchemaTypes[props.eventType.categorySlug];
  const url = props.url;

  const data = {
    "@context": "https://schema.org",
    "@type": type,
    name: name,
    image: image,
    url: url,
    location: {
      "@type": "Place",
      name: place.name,
      address: {
        streetAddress: place.address.streetAddress,
        addressLocality: place.address.addressLocality,
        addressRegion: place.address.addressRegion,
        postalCode: place.address.postalCode,
        addressCountry: place.address.addressCountry,
      },
    },
  };

  // Google recommends rolling 60 day window, filter out all events in past
  // or greater than 60 days in future
  const now = Date.now();
  const sixtyDays = now + 1000 * 60 * 60 * 24 * 60;

  const eventsInWindow = props.events.filter((event: any) =>
    eventTimeInWindow(event, now, sixtyDays)
  );

  // group by start time
  const startTimeToEvents = {} as any;
  eventsInWindow.forEach((event: Event) => {
    if (!(event.startTime in startTimeToEvents)) {
      startTimeToEvents[event.startTime] = [];
    }
    startTimeToEvents[event.startTime].push(event);
  });

  const completedStructuredData = Object.keys(startTimeToEvents).map((key) => {
    const events = startTimeToEvents[key];
    const dataCopy = Object.assign({}, data);
    if (events.length > 1) {
      return multiEvent(events, props, dataCopy);
    }
    return event(events[0], props, dataCopy);
  });

  return (
    <script type="application/ld+json">
      {parse(JSON.stringify(completedStructuredData))}
    </script>
  );
};

export const EventArticleStructuredData = (props: EventGroup) => {
  const data = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: props.overrideMetaTitle,
    articleSection:
      props.marketSlug.charAt(0).toUpperCase() + props.marketSlug.slice(1),
    keywords: ["Checkout", "Product"],
    thumbnailUrl: `${props.images[0].url}?width=320`,
    url: props.url,
    datePublished: props.events?.length
      ? formatStartDate(props.events[0].onsaleTime)
      : "",
  };

  if (props.eventType?.categorySlug && props.eventType?.slug) {
    data.keywords = [
      ...data.keywords,
      props.eventType.categorySlug,
      props.eventType.slug,
    ];
  }

  if (isExpired(props.events[0])) {
    data.keywords.push("expired");
  }

  if (isSoldOut(props.events)) {
    data.keywords.push("SoldOut");
  }

  if (!isExpired(props.events[0]) && !isSoldOut(props.events)) {
    data.keywords.push("onsale");
  }

  return (
    <script type="application/ld+json">{parse(JSON.stringify(data))}</script>
  );
};
