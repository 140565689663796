import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
}

const UnstyledMarketRenderer: React.FC<Props> = ({ children, className }) => {
  return <span className={className}>{children}</span>;
};

export const MarketRenderer = styled(UnstyledMarketRenderer)`
  color: #000000;

  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
`;
