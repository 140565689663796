export const setEncryptedItem = (key: string, data: any) => {
  const basket = Buffer.from(JSON.stringify(data)).toString("base64");
  window.localStorage.setItem(key, basket);
};

export const getDecryptedItem = <T = string>(key: string): T => {
  const item = window.localStorage.getItem(key);

  if (!item) {
    throw `[${key}] does not map to any item in localStorage`;
  }

  return JSON.parse(Buffer.from(item, "base64").toString("utf8"));
};
