import { cookieUtils } from "cf-utils";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const name = "device_id";

export const useDeviceId = () => {
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    const device = cookieUtils.getCookie(name);

    if (!device) {
      const value = uuidv4();
      cookieUtils.setCookie({ name, value });

      setDeviceId(value);
    } else {
      setDeviceId(device);
    }
  }, []);

  return [deviceId];
};
