import React from "react";
import { RTGTransition } from "../transition/transition";
import styled from "styled-components";
import { ImplementedStatus, Overlay, TransitionStyles } from "../../../types";

const offset = -24;
const duration = 300;
const overlayBgMap: { [k in Overlay]: string } = {
  dark: "#1a1a1a",
  light: "#ffffff",
};

export interface Props {
  children: React.ReactNode | null;
  className?: string;
  closeable?: boolean;
  onClose?: () => void;
  onEntered?: () => void;
  onExited?: () => void;
  overlay?: Overlay;
  render: boolean;
  transitionStyles?: TransitionStyles;
}

const defaultTransitions: TransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 0.9 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const containerTransitions: TransitionStyles = {
  entering: { opacity: 0, transform: `translateY(${offset}px)` },
  entered: { opacity: 1, transform: `translateY(0px)` },
  exiting: { opacity: 0, transform: `translateY(${offset}px)` },
  exited: { opacity: 0, transform: `translateY(${offset}px)` },
};

const noop = () => {};

const renderChildren =
  (
    {
      children,
      className,
      closeable = true,
      onClose,
      transitionStyles = defaultTransitions,
    }: Omit<Props, "render">,
    nodeRef: React.RefObject<HTMLDivElement>
  ) =>
  (state: ImplementedStatus) => {
    const onClick = closeable && onClose ? onClose : noop;

    return (
      <div className={className} ref={nodeRef}>
        <div
          className={`${className}-mask`}
          onClick={onClick}
          style={{
            ...(transitionStyles && transitionStyles[state]),
          }}
        />
        <div
          className={`${className}-container`}
          style={{ ...containerTransitions[state] }}
        >
          {children}
        </div>
      </div>
    );
  };

const UnstyledMask: React.FC<Props> = ({
  onEntered = noop,
  onExited = noop,
  render,
  ...props
}) => {
  const nodeRef = React.useRef<HTMLDivElement>(null);
  return (
    <RTGTransition
      duration={duration}
      handlers={{ onEntered, onExited }}
      inProp={render}
      nodeRef={nodeRef}
    >
      {renderChildren(props, nodeRef)}
    </RTGTransition>
  );
};

export const Mask = styled(UnstyledMask)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: all;

  div[class$="mask"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ${({ overlay = "dark" }) => overlayBgMap[overlay]};
    transition: all ${duration}ms ease-in-out;
  }

  div[class$="container"] {
    position: relative;

    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;

    pointer-events: none;

    transition: all ${duration}ms ease-out;
    transition-property: transform, opacity;
  }
`;
