import { BasketItem } from "../../../../types";

export const calculateTotal = (itemQuantities: {
  [key: string]: BasketItem;
}) => {
  return Object.values(itemQuantities)
    .reduce((total, { item, quantity }) => {
      const { bookingFee, priceWithoutFee } = item;
      const price = priceWithoutFee * quantity;

      if (bookingFee) {
        total += price + bookingFee * quantity;
        return total;
      }

      total += price;
      return total;
    }, 0)
    .toFixed(2);
};
