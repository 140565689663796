import { BasketItem } from "../../../../types";

export const updatedSelections = (
  items: BasketItem[],
  updatedItem: BasketItem
) => {
  const updatedList = [...items];
  const indexOfNewItem = updatedList.findIndex(
    ({ item }) => item.sellableItemId === updatedItem.item.sellableItemId
  );

  if (indexOfNewItem < 0) {
    updatedList.push(updatedItem);
  }

  if (indexOfNewItem > -1) {
    updatedList.splice(indexOfNewItem, 1, updatedItem);
  }

  if (indexOfNewItem > -1 && updatedItem.quantity < 1) {
    updatedList.splice(indexOfNewItem, 1);
  }

  return updatedList;
};
