import styled from "styled-components";

export interface PageProps {
  className?: string;
  options: Array<string>;
  selectId?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selected?: string;
}

const renderOptions = (option: string, index: number) => (
  <option key={index} value={option}>
    {option}
  </option>
);

const UnstyledDropdown: React.FC<PageProps> = ({
  className,
  options,
  onChange,
  selected,
}) => (
  <select className={className} onChange={onChange} value={selected}>
    {options?.map(renderOptions)}
  </select>
);

export const Dropdown = styled(UnstyledDropdown)`
  border: 1px solid #aaaaaa;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='30' viewBox='5 3 20 20' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border-radius: 0px;
  padding: 12px;
  padding-right: 2rem;
  font-family: Roboto;
  font-size: 16px;
`;
