/**
 * surface privacy manager link.
 */
export const initPrivacyManager = (messageType: string) => {
  const manageCookiesFooterLink = document.querySelectorAll<HTMLAnchorElement>(
    'a[data-track="cookie-consent"]'
  );

  manageCookiesFooterLink.forEach((elem) => {
    elem.addEventListener("click", (evt) => {
      evt.preventDefault();

      if (messageType === "usnat") {
        window._sp_?.usnat?.loadPrivacyManagerModal(1124869);
      } else if (messageType === "gdpr") {
        window._sp_?.gdpr?.loadPrivacyManagerModal(964488, "purposes");
      }
    });
  });
};

/**
 * setup sourcepoint configuration
 */
export const setupSourcepointConfiguration = () => {
  window._sp_queue = [];
  window._sp_ = {
    config: {
      accountId: 1940,
      baseEndpoint: "https://cdn.privacy-mgmt.com",
      propertyHref: "https://checkout.timeout.com",
      ccpa: {},
      usnat: {
        includeUspApi: true,
      },
      gdpr: {},
      events: {
        onMessageChoiceSelect: function (...args) {
          console.log("[event] onMessageChoiceSelect", args);
        },
        onMessageReady: function (...args) {
          console.log("[event] onMessageReady", args);
        },
        onMessageChoiceError: function (...args) {
          console.log("[event] onMessageChoiceError", args);
        },
        onPrivacyManagerAction: function (...args) {
          console.log("[event] onPrivacyManagerAction", args);
        },
        onPMCancel: function (...args) {
          console.log("[event] onPMCancel", args);
        },
        onMessageReceiveData: function (...args) {
          console.log("[event] onMessageReceiveData", args);
        },
        onSPPMObjectReady: function (...args) {
          console.log("[event] onSPPMObjectReady", args);
        },
        onConsentReady: function (...args) {
          console.log("[event] onConsentReady", args);

          const [message_type, , , info] = args;

          if (["usnat", "gdpr"].includes(message_type) && info?.applies) {
            initPrivacyManager(message_type);
          }
        },
        onError: function (...args) {
          console.log("[event] onError", args);
        },
      },
    },
  };
};
