import { IconsProps } from "../icons-types";

export const AccountIcon = ({ darkMode = false }: IconsProps) => (
  <svg
    width={20}
    height={22}
    viewBox="0 0 28 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/User"}</title>
    <path
      d="M13.516.5a6.923 6.923 0 013.218 13.055c5.014 1.604 10.299 6.95 10.299 16.945H0c0-9.933 5.29-15.322 10.307-16.942A6.923 6.923 0 0113.517.5zm0 15.165c-2.284 0-4.917 1.188-6.95 3.358-2.022 2.158-3.358 5.128-3.783 8.84h21.469c-.422-3.74-1.759-6.715-3.782-8.867-2.027-2.155-4.656-3.331-6.954-3.331zm0-12.528a4.286 4.286 0 100 8.572 4.286 4.286 0 000-8.572z"
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
