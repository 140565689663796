import { useLazyQuery } from "@apollo/client";
import { getAuthBaseURL } from "cf-utils";
import { AuthSession, Market, UserSession } from "cf-types";
import {
  GetAuthUserProfileQuery,
  PostSecureSessionQuery,
} from "pages/api/queries";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_REGISTER, USER_LOGIN_ERROR, Store } from "redux/types";
import { logIn } from "redux/actions";
import { useDeviceId } from "src/hooks";

import styles from "./auth-modal.module.scss";

const getUrlParams = ({ localeName, siteId }: Market) => {
  const searchParams = new URLSearchParams({
    site: siteId,
    locale: localeName.replace("_", "-"),
    platform: "checkout",
  });

  return `/?${searchParams.toString()}`;
};

type AuthData = {
  actionType: "analytics" | "login" | "register";
};

type LoginData = AuthData & {
  access_token: string;
  expires_at: number;
  expires_in: number;
  loginMethod: "google" | "facebook" | "twitter" | "email";
  refresh_token: string;
  scope: string;
  token_type: "Bearer";
};

type AnalyticsData = AuthData & {
  clientId: string;
  event: string;
  origin: string;
  timestamp: number;
  userAction: "login error" | "sign in" | "sign out" | "sign up";
};

type Props = {
  param?: string;
};

const authHost = getAuthBaseURL() || "https://auth.timeout.com";

const AuthModal: React.FC<Props> = ({
  param = "/?site=uk-london&locale=en-GB&platform=checkout",
}) => {
  const dispatch = useDispatch();
  const [deviceId] = useDeviceId();
  const selectedMarket = useSelector(
    ({ Market }: Store) => Market?.selectedMarket[0]
  );
  const urlParams = param ?? getUrlParams(selectedMarket);

  const [fetchSecureSession] = useLazyQuery<{
    postSecureSession: UserSession;
  }>(PostSecureSessionQuery, {
    fetchPolicy: "no-cache",
    onCompleted: ({ postSecureSession }) => {
      localStorage.setItem("login", JSON.stringify(postSecureSession));
      dispatch(logIn(postSecureSession));
    },
    onError: console.error,
  });

  const [fetchUserAuth] = useLazyQuery<{
    getAuthUserProfile: AuthSession;
  }>(GetAuthUserProfileQuery, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getAuthUserProfile }) => {
      if (getAuthUserProfile.access_token) {
        const { access_token } = getAuthUserProfile;
        window.localStorage.setItem("access_token", access_token);
        fetchSecureSession({
          variables: {
            tokens: JSON.stringify({
              access_token,
              deviceId,
              method: "access_token",
            }),
          },
        });
      }
    },
    onError: console.error,
  });

  const onMessage = ({ data }: MessageEvent<AnalyticsData & LoginData>) => {
    if (data?.actionType === "login") {
      fetchUserAuth({
        variables: {
          authData: JSON.stringify({
            access_token: data.access_token,
            deviceId,
          }),
        },
      });
    }
    if (data?.actionType === "analytics") {
      if (data?.userAction === "login error") {
        dispatch({
          type: USER_LOGIN_ERROR,
          payload: { loggedInError: true },
        });
      }

      if (data?.userAction === "sign up" && data?.origin) {
        dispatch({
          type: USER_REGISTER,
          payload: { registered: true, registeredOrigin: data.origin },
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [fetchSecureSession]);

  return (
    <div className={styles.root}>
      <iframe
        className={styles.iframe}
        data-test-id={"modal-auth"}
        src={`${authHost}${urlParams}`}
      />
    </div>
  );
};

export default AuthModal;
