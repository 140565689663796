import { gql } from "@apollo/client";

export const PostBasketQuery = gql`
  query BasketQuery($basket: String!) {
    postBasket(basket: $basket) {
      chargeAmount
      countTickets
      creditCharge
      discount
      fee
      paymentAmount
      promoCode {
        amount
        error
        name
      }
      ticketBookingFee
      ticketGroups {
        event {
          resourceUri
          initialPrice
          initialPriceVisible
          hasInitialPrice
          discountPrice
          bookingFee
          priceWithoutFee
          startTime
          seatDescription
          availableTickets
          ticketIncrement
          performanceId
          stockLevel
          onsaleTime
          offsaleTime
          sellableItemId
        }
        tickets {
          discountPrice
          initialPrice
          ticketCount
          ticketType
        }
      }
    }
  }
`;
