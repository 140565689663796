import { Options } from "../types";

export const getReadableDate = (timestamp: number, options: Options) => {
  const dateInMilliseconds = new Date(timestamp * 1000);
  const formatter = getDateFormat(dateInMilliseconds, options);
  const day = formatter({ weekday: "short" });
  const month = formatter({ month: "short" });

  const date = dateInMilliseconds.getDate();
  const time = dateInMilliseconds.toLocaleTimeString(options.locale, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: options.timeZone,
  });
  return {
    date: `${day} ${date} ${month}`,
    time,
  };
};

const getDateFormat =
  (date: Date, { locale, timeZone }: Options) =>
  (options: Intl.DateTimeFormatOptions) => {
    return Intl.DateTimeFormat(locale, Object.assign(options, timeZone)).format(
      date
    );
  };
