export const getCookie = (
  name: string,
  document: Document | { cookie: string } = window.document
): string => {
  return document.cookie.match(new RegExp(`(?:^| )${name}=([^;]+)`))?.[1] ?? "";
};

export const setCookie = ({
  expiry = 365,
  name,
  value,
}: {
  expiry?: number;
  name: string;
  value: string;
}): string => {
  if (!name || !value) {
    throw `Cookie name or value cannot be empty. name: '${name}', value: '${value}'`;
  }

  const today = new Date();
  today.setTime(today.getTime() + expiry * 24 * 60 * 60 * 1000);

  return (document.cookie = `${name}=${value};expires=${today.toUTCString()}; path=/`);
};
