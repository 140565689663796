import styled from "styled-components";
import { CloseButtonIcon } from "../../../../global/iconography/close-button-icon";

export interface QRCodeModalProps {
  className?: string;
  ticketNumber: number;
  description: string;
  barcodeUrl: string;
  onCloseCB: (e: React.MouseEvent | React.KeyboardEvent) => void;
}

const unstyledQRCodeModal = ({
  className,
  ticketNumber,
  description,
  barcodeUrl,
  onCloseCB,
}: QRCodeModalProps) => (
  <div className={className}>
    <CloseButtonIcon
      height={15}
      width={15}
      darkMode={false}
      onClickCB={onCloseCB}
    />
    <div className={`${className}-text`}>
      <span className={`${className}-ticket-number`}>
        Ticket {ticketNumber}
      </span>
      <span className={`${className}-ticket-description`}>{description}</span>
    </div>
    <img src={barcodeUrl} />
  </div>
);

export const QRCodeModal = styled(unstyledQRCodeModal)`
  background-color: #fff;
  width: 375px;
  height: 721px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);

  svg {
    display: flex;
    margin: 15px 20px 0 auto;
    cursor: pointer;
  }

  div[class$="text"] {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-bottom: 118px;
  }

  span[class$="number"] {
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: normal;
    color: #1a1a1a;
  }

  span[class$="description"] {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1a1a1a;
  }

  img {
    width: 315px;
    height: 315px;
    align-self: center;
  }
`;
