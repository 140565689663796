import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ErrorMessages } from "cf-constants";
import { UserSession } from "cf-types";
import { PostSecureSessionQuery } from "pages/api/queries";

export const getSecureSession = async (
  client: ApolloClient<NormalizedCacheObject>,
  { access_token, deviceId }: { access_token: string; deviceId: string }
): Promise<UserSession> => {
  try {
    const {
      data: { postSecureSession },
    } = await client.query<{
      postSecureSession: UserSession;
    }>({
      query: PostSecureSessionQuery,
      variables: {
        tokens: JSON.stringify({
          access_token,
          deviceId,
          method: "access_token",
        }),
      },
    });

    return postSecureSession;
  } catch (e) {
    throw new Error(ErrorMessages.Checkout.GET_SECURE_SESSION);
  }
};
