import styled from "styled-components";
import parse from "html-react-parser";
import { Colour } from "../../../types";

export interface OffsiteBookingWidgetProps {
  bookingTitle: string;
  className?: string;
  content: string;
  ctaText: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  purchaseLink: string;
}

const UnstyledOffsiteBookingWidget = ({
  className,
  ctaText,
  bookingTitle,
  purchaseLink,
  onClick,
  content,
}: Partial<OffsiteBookingWidgetProps>) => {
  return (
    <div className={className}>
      <h2>{bookingTitle}</h2>
      <hr />
      <form>
        <div>{parse(content ?? "")}</div>
        <a
          href={purchaseLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {ctaText}
        </a>
      </form>
    </div>
  );
};

export const OffsiteBookingWidget = styled(UnstyledOffsiteBookingWidget)`
  form {
    margin: 20px;
  }

  border: solid 1px #aaa;
  background-color: #fff;

  h2 {
    display: none;
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #1a1a1a;
    margin: 0;
    margin-top: 15px;
  }

  p {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 10px 0;
  }

  hr {
    border: 0px solid #d8d8d8;
    border-bottom-width: 1px;
    margin: 8px 20px 20px;
    display: none;
  }

  a {
    height: 50px;
    background-color: #1a1a1a;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    border: none;
    cursor: pointer;
    display: flex;
    text-decoration: none;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  a:hover,
  a:focus {
    background-color: ${Colour.ERROR};
  }

  @media (min-width: 768px) {
    width: 298px;
    h2 {
      display: inherit;
    }

    hr {
      display: inherit;
    }
  }
`;
