import { UPDATE_PAGE_DATA } from "../types";

type Action = {
  type: string;
  payload?: { pageName: string };
};

export const initialState = {
  pageName: "",
};

const PageReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_PAGE_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default PageReducer;
