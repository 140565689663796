import styled from "styled-components";
import { CalendarIcon, MapPinIcon } from "../../../../global/iconography";
import {
  getReadableDate,
  getCheapestEvent,
  checkForDifferentPrices,
} from "../../../../utils";
import { ImageComponent } from "../../image-component-product/image-component";

type Event = {
  initialPrice: number;
  discountPrice: number;
  startTime?: number;
};

type Venue = {
  venueName: string;
  venueAddress: string;
};

type Image = {
  url: string;
  webpUrl?: string;
};

export interface CategoryWidgetProps {
  className?: string;
  currency: string;
  websitePath: string;
  images: Image[];
  index: number;
  name: string;
  events: Event[];
  venues: Venue[];
  purchaseType: string;
  tracker?: {
    onProductClick: (index: number) => void;
  };
  category: string;
  eventGroupID: string;
}

const UnstyledCategoryWidget = ({
  className,
  websitePath,
  images,
  index,
  name,
  events,
  venues,
  currency,
  purchaseType,
  tracker,
  category,
  eventGroupID,
}: CategoryWidgetProps) => {
  const firstEvent = events[0];

  const date = firstEvent?.startTime
    ? getReadableDate(firstEvent.startTime)
    : null;

  const venueName =
    venues.length && venues[0].venueAddress ? venues[0].venueName : null;
  const multiEventText = `+ ${events.length - 1} more`;
  const cheapestEvent = getCheapestEvent(events);

  const differentPrices = events.length
    ? checkForDifferentPrices(events, cheapestEvent)
    : null;
  const isDiscounted =
    cheapestEvent && cheapestEvent.discountPrice < cheapestEvent.initialPrice;

  const discountPercentage =
    cheapestEvent &&
    cheapestEvent.initialPrice &&
    cheapestEvent.initialPrice > cheapestEvent.discountPrice
      ? Math.trunc(
          (Math.round(
            cheapestEvent.initialPrice - cheapestEvent.discountPrice
          ) /
            cheapestEvent.initialPrice) *
            100
        )
      : null;

  const image = images[0];

  return (
    <div className={className} data-track-product-index={index}>
      <a
        href={websitePath}
        onClick={() => {
          tracker?.onProductClick(index);
        }}
      >
        <div className={`${className}-image-wrapper`}>
          <ImageComponent href={image.url} webpUrl={image.webpUrl} />
        </div>
        <h2>{name}</h2>
        {venueName && (
          <div className={`${className}-location-text`}>
            <MapPinIcon width={13} height={18} />
            <span>
              {venueName}
              {venues.length > 1 && ", Various locations"}
            </span>
          </div>
        )}
        {purchaseType === "attendance" && date && (
          <div className={`${className}-date-text`}>
            <CalendarIcon width={15} height={14} />
            <span>
              {events.length > 1 ? `${date} ${multiEventText}` : date}
            </span>
          </div>
        )}
        {cheapestEvent && (
          <div className={`${className}-price-wrapper`}>
            {differentPrices && (
              <span className={`${className}-from-prefix`}>From</span>
            )}
            <div className={`${className}-price-text`}>
              <span className={`${className}-price`}>
                {currency}
                {cheapestEvent.discountPrice.toFixed(2)}
              </span>
              {isDiscounted && (
                <span
                  className={`${className}-discount-percentage`}
                >{`${discountPercentage}% OFF`}</span>
              )}
            </div>
          </div>
        )}
      </a>
    </div>
  );
};

export const CategoryWidget = styled(UnstyledCategoryWidget)`
  transition: 0.3s;

  :hover div[class$="image-wrapper"] img {
    transform: scale(1.06);
  }

  a {
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 1.14;
    color: #1a1a1a;
    margin: 10px 0 15px;
  }

  h2:hover {
    color: #d31a22;
  }

  div[class$="image-wrapper"] {
    aspect-ratio: 5/3;
    overflow: hidden;
  }

  div[class$="image-wrapper"] img {
    max-width: 100%;
    transition: all 0.3s ease-in-out;
  }

  div[class$="location-text"] {
    display: flex;
    flex-direction: row;
    margin: 16px 0 10px 0;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    align-items: center;
  }

  svg {
    margin-right: 6px;
  }

  div[class$="date-text"] {
    display: flex;
    flex-direction: row;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    align-items: center;
  }

  span[class$="from-prefix"] {
    font-family: Roboto;
    font-size: 14px;
    color: #7d7d7d;
    bottom: 36px;
  }

  div[class$="price-text"] {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div[class$="price-wrapper"] {
    padding-top: 32px;
    margin-top: auto;
  }

  span[class$="price"] {
    margin-right: 10px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.15;
    color: #1a1a1a;
  }

  span[class$="discount-percentage"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69px;
    height: 23px;
    border-radius: 4px;
    border: solid 1.5px #38ae19;
    background-color: #fff;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    line-height: 23px;
    color: #35981b;
    text-align: center;
  }

  @media (min-width: 768px) {
    h2 {
      margin-bottom: 0;
    }

    div[class$="location-text"] {
      line-height: 1;
    }
  }
`;
