import React from "react";
import styled from "styled-components";

type Props = {
  Component?: keyof JSX.IntrinsicElements;
  eventTitle: string;
  includeSchema?: boolean;
  className?: string;
};

const UnstyledEventTitle: React.FunctionComponent<Props> = ({
  Component: Wrapper = "h1",
  eventTitle,
  includeSchema,
  className,
}) => {
  const title: string = eventTitle ? eventTitle : "Event Title";
  return (
    <Wrapper data-test="event-title-component" className={className}>
      {title}
    </Wrapper>
  );
};

const EventTitle = styled(UnstyledEventTitle)`
  font-size: 38px;
  font-family: FranklinGothicLTPro-DmCm;
  line-height: 1.11;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;
export default EventTitle;
