import styled from "styled-components";

export interface DividerProps {
  className?: string;
  text?: string;
}

const UnstyledDivider = ({ className }: DividerProps) => (
  <hr className={className}></hr>
);

const unStyledDividerWithText = ({ className, text }: DividerProps) => (
  <div className={className}>
    <div />
    <p>{text}</p>
    <div />
  </div>
);

export const Divider = styled(UnstyledDivider)`
  border-color: #4a4a4a;
  border-style: solid;
  margin: 0;
  height: 0;
  border-width: 1px 0 0;
`;

export const DividerWithText = styled(unStyledDividerWithText)`
  display: flex;
  align-items: center;

  p {
    padding: 0 5px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
  }
  div {
    flex: 1;
    height: 1px;
    background-color: #d8d8d8;
  }
`;
