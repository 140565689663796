import React from "react";
import styled from "styled-components";
import { Error } from "../../error/error";
import { InputField } from "../../input-field/input-field";

interface Props {
  className?: string;
  error: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const UnstyledLastName: React.FC<Props> = ({
  className,
  error,
  onChange,
  value,
}: Props) => {
  return (
    <div className={className}>
      <label htmlFor={"last-name"}>Last name</label>
      <InputField
        error={error}
        id={"last-name"}
        name={"lastName"}
        onChange={onChange}
        type={"text"}
        value={value}
      />
      {error && <Error>Enter your last name</Error>}
    </div>
  );
};

export const LastName = styled(UnstyledLastName)`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-width: 0;
  margin-left: 30px;

  label {
    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  input {
    height: 44px;

    background-color: #fff;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
