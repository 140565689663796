type Event = {
  initialPrice: number;
  discountPrice: number;
  startTime?: number;
};

// Returns date in '22:00, Fri 24 Aug' format.
export const getReadableDate = (
  timestamp: number | undefined,
  reverseOrder = false
): string | null => {
  if (!timestamp) return null;
  const dateInMilliseconds = new Date(timestamp * 1000);
  const time = dateInMilliseconds.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const day = Intl.DateTimeFormat("en-GB", { weekday: "short" }).format(
    dateInMilliseconds
  );
  const date = dateInMilliseconds.getDate();
  const month = Intl.DateTimeFormat("en-GB", { month: "short" }).format(
    dateInMilliseconds
  );

  return reverseOrder
    ? `${day} ${date} ${month}, ${time}`
    : `${time}, ${day} ${date} ${month}`;
};

export const getCheapestEvent = (events: Event[]): Event | null => {
  if (!events.length) return null;
  const eventsCopy = [...events];
  return [...events].sort((a, b) => a.discountPrice - b.discountPrice)[0];
};

// Checks if different dates have a different price
export const checkForDifferentPrices = (
  events: Event[],
  cheapestEvent: Event | null
): boolean => {
  if (!cheapestEvent) return false;
  return (
    events.length > 1 &&
    events.some(
      ({ discountPrice }) => cheapestEvent.discountPrice !== discountPrice
    )
  );
};
