import styled from "styled-components";
import { Colour } from "../../../types";

export const Error = styled.p`
  margin-top: 5px;
  margin-bottom: 0;

  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colour.ERROR};
`;
