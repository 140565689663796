import React, { useRef } from "react";
import styled from "styled-components";
import { ToolTip } from "../../tool-tip/tool-tip";
import { BookingFeeToolTip } from "./booking-fee-tool-tip";

export interface Props {
  bookingFee: string;
  bookingFeeNumber: number;
  bookingFeeTotal: string;
  className?: string;
  termsUrl?: string;
}

const UnstyledBookingFee: React.FC<Props> = ({
  bookingFee,
  bookingFeeNumber,
  bookingFeeTotal,
  className,
  termsUrl = "https://google.com",
}) => {
  const ref = useRef<HTMLSpanElement>(null);

  return (
    <div className={className}>
      <div className={`${className}-booking-fee-wrapper`}>
        <span className={`${className}-booking-fee-number`}>
          {bookingFeeNumber}
        </span>
        <span className={`${className}-booking-fee-value`}>{bookingFee}</span>
        <ToolTip
          trigger={"click"}
          targetElemRef={ref}
          message={<BookingFeeToolTip termsUrl={termsUrl} />}
        >
          <span ref={ref} className={`${className}-tool-tip`}>
            Booking Fee
          </span>
        </ToolTip>
      </div>
      <span>{bookingFeeTotal}</span>
    </div>
  );
};

export const BookingFee = styled(UnstyledBookingFee)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  font-size: 14px;
  font-family: Roboto;
  color: #1a1a1a;

  div[class$="booking-fee-wrapper"] {
    display: flex;
  }

  span[class$="booking-fee-number"]::after {
    margin-left: 4px;
    margin-right: 4px;

    content: " x";
  }

  span[class$="booking-fee-value"]::before {
    margin-left: 4px;

    content: "";
  }

  span[class$="booking-fee-value"]::after {
    margin-right: 8px;

    content: " ";
  }

  span[class$="tool-tip"] {
    cursor: default;

    text-decoration: underline;
  }
`;
