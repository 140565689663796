import React from "react";
import styled from "styled-components";
import { Field, OptIn } from "../../../../types";
import { OptInRenderer } from "../../view";
import { Email } from "./email";
import { FirstName } from "./first-name";
import { LastName } from "./last-name";
import { Phone } from "./phone";

type FormFields = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export type Props<T = FormFields> = {
  [k in keyof T]: Field<T[k]>;
} & {
  className?: string;
  displayHeader?: boolean;
  loggedIn?: boolean;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFirstName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLastName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOptIn?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePhone: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditDetails?: (e: React.FormEvent<HTMLAnchorElement>) => void;
  optInData?: OptIn[];
  signInCB?: (e: React.FormEvent<HTMLAnchorElement>) => void;
};

const UnstyledContactForm: React.FC<Props> = ({
  className,
  displayHeader = true,
  email,
  firstName,
  lastName,
  phone,
  loggedIn = false,
  onChangeEmail,
  onChangeFirstName,
  onChangeLastName,
  onChangeOptIn,
  onChangePhone,
  onEditDetails,
  optInData = [],
  signInCB,
}: Props) => {
  return (
    <form className={className}>
      <div className={`${className}-sign-in-container`}>
        {displayHeader && (
          <div className={`${className}-sign-in-link`}>
            <Span>
              <a onClick={signInCB}>ALREADY HAVE AN ACCOUNT? LOG IN</a>
            </Span>
          </div>
        )}
        <Div>
          <H3>Contact details</H3>
        </Div>
      </div>
      <div className={`${className}-email-container`}>
        <Email
          disabled={loggedIn}
          error={email?.error}
          onChange={onChangeEmail}
          value={email?.value}
        />
        <div className={`${className}-opt-in-container`}>
          {optInData.map(({ field, text, value }) => {
            return (
              <div key={field} className={`${className}-opt-in-item`}>
                <OptInRenderer
                  name={field}
                  onChange={onChangeOptIn}
                  text={text}
                  value={value}
                />
              </div>
            );
          })}
        </div>
      </div>
      {!loggedIn && (
        <div className={`${className}-name-container`}>
          <FirstName
            error={firstName?.error}
            onChange={onChangeFirstName}
            value={firstName?.value}
          />
          <LastName
            error={lastName?.error}
            onChange={onChangeLastName}
            value={lastName?.value}
          />
        </div>
      )}
      <Phone
        disabled={loggedIn}
        error={phone?.error}
        onChange={onChangePhone}
        value={phone?.value}
      />
      {loggedIn && (
        <Span>
          Your details have changed? <a onClick={onEditDetails}>Edit</a>
        </Span>
      )}
    </form>
  );
};

const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 0;

  font-family: "FranklinGothicLTPro-DmCm";
  font-size: 28px;
  line-height: 1.14;
  color: #1a1a1a;
`;

const Span = styled.span`
  line-height: 1.5;

  font-family: "Roboto";
  font-size: 14px;
  color: #7d7d7d;
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContactForm = styled(UnstyledContactForm)`
  display: flex;
  flex-direction: column;
  padding: 25px 30px 40px;

  background-color: #fff;

  div[class$="sign-in-container"] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;

    border-bottom: 1px solid #d8d8d8;
  }

  div[class$="sign-in-link"] {
    margin-bottom: 15px;
  }

  div[class$="sign-in-link"] a {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 700;
    font-family: Roboto;
  }

  a {
    color: #7d7d7d;

    text-decoration: underline;
    cursor: pointer;
  }

  div[class$="email-container"] {
    margin-bottom: 25px;
  }

  div[class$="name-container"] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  div[class$="opt-in-container"] {
    padding-top: 10px;
  }

  div[class$="opt-in-item"] {
    &:not(:first-of-type) {
      margin-top: 10px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    padding: 22px 15px 30px;
  }
`;
