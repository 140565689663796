import { IconsProps } from "../icons-types";

export const CalendarIcon = ({
  darkMode = false,
  width = 29,
  height = 27,
}: IconsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/Calendar"}</title>
    <path
      d="M23.887 3.217h-1.456V1.852c0-.747-.673-1.352-1.42-1.352-.746 0-1.419.605-1.419 1.352v1.365H8.802V1.852C8.802 1.105 8.13.5 7.382.5c-.746 0-1.42.605-1.42 1.352v1.365H4.508C2.022 3.217 0 5 0 7.487v14.421C0 24.394 2.022 26.5 4.507 26.5h19.38c2.486 0 4.507-2.106 4.507-4.592V7.486c0-2.485-2.021-4.27-4.507-4.27zM4.507 5.772h1.456V6.81c0 .747.673 1.352 1.42 1.352.746 0 1.42-.605 1.42-1.352V5.772h10.789V6.81c0 .747.673 1.352 1.42 1.352.746 0 1.42-.605 1.42-1.352V5.772h1.455c.994 0 1.668.72 1.668 1.714v2.261H2.839v-2.26c0-.995.674-1.715 1.668-1.715zm19.38 18.173H4.507c-.994 0-1.668-1.043-1.668-2.037v-9.321h22.716v9.321c0 .994-.674 2.037-1.668 2.037z"
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
