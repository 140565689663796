import React from "react";
import styled from "styled-components";

type Props = {
  onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isSelected: boolean;
  index: number;
  label?: string;
  size?: number;
};

const SliderIndicator: React.FC<Props> = ({
  onClickHandler,
  isSelected,
  index,
  label,
  size = 8,
}) => {
  if (isSelected) {
    return (
      <ListPip
        size={size}
        style={{ background: "#7d7d7d" }}
        aria-label={`Selected: ${label} ${index + 1}`}
        title={`Selected: ${label} ${index + 1}`}
      />
    );
  }
  return (
    <ListPip
      size={size}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      value={index}
      key={index}
      role="button"
      tabIndex={0}
      title={`${label} ${index + 1}`}
      aria-label={`${label} ${index + 1}`}
    />
  );
};

const ListPip = styled.li<{ size: number }>`
  display: inline-block;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: ${(props) => `${props.size * 0.5}px`};

  background: #d8d8d8;
`;

export default SliderIndicator;
