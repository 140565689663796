import { useRouter } from "next/router";
import { normaliseQueryParam } from "src/utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux/types";

export const useLocationRedirect = () => {
  const router = useRouter();
  const selectedMarket = useSelector(({ Market }: Store) => {
    return Market?.selectedMarket[0];
  });

  useEffect(() => {
    const location = normaliseQueryParam(router.query.location);
    if (selectedMarket.slug && selectedMarket.slug !== location) {
      router.push(`/${selectedMarket.slug}`, "", {
        shallow: true,
      });
    }
  }, [selectedMarket.slug]);
};
