import { AppActions } from "../actions";

type Action = {
  type: string;
  payload: {
    authModalOpen?: boolean;
    deviceId?: string;
    supportEmail?: string;
    isLoading?: boolean;
  };
};

export const initialState = {
  authModalOpen: false,
  device: { deviceId: "", supportEmail: "" },
  featureFlags: {
    guestCheckout: true,
  },
  isLoading: false,
};

const AppReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case AppActions.SET_DEVICE_DATA: {
      return {
        ...state,
        device: {
          ...state.device,
          deviceId: payload.deviceId,
          supportEmail: payload.supportEmail,
        },
      };
    }
    case AppActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    default:
      return { ...state };
  }
};

export default AppReducer;
