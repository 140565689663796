// @ts-nocheck
// request queue for messages that get sent before Quantcast gets loaded
const CMPRequestQueue: Array<unknown> = [];

/**
 * acts as a mock to the real __tcfapi function that will be loaded when the CMP
 * solution loads; this allows any entity that asks for consent API to send messages
 * to a responsive method; this method also populates the queue of messages that
 * will be handled once the CMP solution loads;
 * general information here: https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#what-required-api-commands-must-a-cmp-support
 * this method might also support other types of arguments dependant on the CMP
 * solution being used
 */

export const tcfAPIMock = (...args: unknown[]): unknown[] | undefined => {
  let gdprApplies;

  if (!args.length) {
    return CMPRequestQueue;
  }

  if (args[0] === "setGdprApplies") {
    if (args.length > 3 && typeof args[3] === "boolean") {
      [, , , gdprApplies] = args; // args[3]
      if (typeof args[2] === "function") {
        const thirdParamFunction = args[2] as (
          command: string,
          value: boolean
        ) => void;
        thirdParamFunction("set", true);
      }
    }
  } else if (args[0] === "ping") {
    const retr = {
      gdprApplies,
      cmpLoaded: false,
      cmpStatus: "stub",
    };

    if (typeof args[2] === "function") {
      args[2](retr);
    }
  } else {
    if (args[0] === "init") {
      args[3]["coreConfig"]["showSummaryView"] = true;
      args[3]["coreConfig"]["publisherPurposeLegitimateInterestIds"] = [1];
      args[3]["coreConfig"]["softOptInEnabled"] = true;
      args[3]["coreConfig"]["uiLayout"] = "banner";
    }
    CMPRequestQueue.push(args);
  }
};
