import React from "react";
import styled from "styled-components";

export type PageProps = {
  alt?: string;
  href?: string;
  lazyload?: boolean;
  className?: string;
  webpUrl?: string;
};

const placeholderImage = "https://i.stack.imgur.com/y9DpT.jpg";

const unStyledImageComponent: React.FC<PageProps> = ({
  alt = "my image",
  className,
  href,
  lazyload = false,
  webpUrl,
}) => {
  if (webpUrl) {
    return (
      <picture>
        <source type="image/webp" srcSet={webpUrl} className={className} />
        <img
          alt={alt}
          className={className}
          loading={lazyload ? "lazy" : "eager"}
          src={href || placeholderImage}
        />
      </picture>
    );
  }

  return (
    <img
      alt={alt}
      className={className}
      loading={lazyload ? "lazy" : "eager"}
      src={href || placeholderImage}
    />
  );
};

export const ImageComponent = styled(unStyledImageComponent)`
  width: 100%;
  height: 100%;

  object-fit: cover;
`;
