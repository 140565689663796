import { clsx } from "clsx";
import React from "react";
import styled from "styled-components";

export type SelectedBackground = "solid" | "none";

export interface Props {
  backgroundColor?: SelectedBackground | string;
  bottomSpacing?: `${number}px`;
  className?: string;
  disabled?: boolean;
  disableGutters?: boolean;
  disablePadding?: boolean;
  divider?: boolean;
  isSelected?: boolean;
  marginTop?: string;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  uid: string;
}

const UnstyledListItem: React.FC<Props> = ({
  children,
  className,
  isSelected,
  onClick,
}) => {
  return (
    <li className={clsx(className, isSelected && "selected")} onClick={onClick}>
      {children}
    </li>
  );
};

export const ListItem = styled(UnstyledListItem)`
  position: relative;

  display: flex;
  flex-direction: column;
  padding: ${({ disablePadding = false }) =>
    `${disablePadding ? "0px" : "12px"}`};
  ${({ disableGutters = false }) => {
    return (
      !disableGutters && {
        "padding-left": "12px",
        "padding-right": "12px",
      }
    );
  }};

  &:not(:last-of-type) {
    padding-bottom: ${({ bottomSpacing }) => bottomSpacing};
  }
  margin-top: ${({ marginTop }) => marginTop ?? "0px"};

  border-bottom-color: #ececec;
  border-bottom-style: solid;
  border-bottom-width: ${({ divider = true }) => `${divider ? "1px" : "0"}`};

  background-color: ${({
    backgroundColor = "transparent",
    disabled,
    isSelected,
  }) => {
    return disabled || isSelected ? "#d8d8d8" : backgroundColor;
  }};
  list-style: none;

  cursor: ${({ disabled, onClick }) => {
    if (disabled) {
      return "not-allowed";
    }

    return onClick ? "pointer" : "default";
  }};
`;
