import styled from "styled-components";
import { Colour } from "../../../types";
import * as Icons from "../../../global/iconography";
import { List, ListItem } from "../list";
import { MarketDropdown } from "./market-dropdown";
import { useProfileDropdown } from "./hooks";
import { Props } from "./mast-header";
import { ListRenderer } from "./list-renderer";

const gutters = "80px";

const UnstyledDesktop = ({
  className,
  customerName,
  displayMarkets,
  isLoggedIn,
  markets,
  onLoginClick,
  onSelectMarket,
  portal,
  profileLinks,
  selectedMarket,
}: Omit<Props, "navLinks">) => {
  const [profileDropdown, onProfileDropdownOpen, onClose] = useProfileDropdown(
    isLoggedIn,
    onLoginClick
  );

  return (
    <div className={className}>
      {displayMarkets && (
        <div className={`${className}-market-list`}>
          <MarketDropdown
            anchor={gutters}
            markets={markets}
            portal={portal}
            onSelectMarket={onSelectMarket}
            selectedMarket={selectedMarket}
          />
        </div>
      )}
      <a className={"logo"} href="/">
        <Icons.TimeOutOfferLogo />
      </a>
      <div className={"masthead-desktop-icons"}>
        <a
          className={`${className}-email`}
          data-track="subscribe-icon"
          href="#footer-newsletter"
        >
          <Icons.EmailIcon darkMode={true} />
          <span>Subscribe</span>
        </a>
        <a onClick={onProfileDropdownOpen}>
          <Icons.AccountIcon darkMode={true} />
          <span>{isLoggedIn ? `Hi ${customerName}` : "Login"}</span>
        </a>
        <List
          backgroundColor={"#1a1a1a"}
          id={"profile-menu-desktop"}
          onClose={onClose}
          portal={portal}
          render={profileDropdown}
          tether={{
            right: gutters,
            top: "65px",
          }}
          width={"188px"}
        >
          {profileLinks.map((children, index) => {
            const uid = `link-${index}`;
            return (
              <ListItem key={uid} uid={uid}>
                <ListRenderer color={Colour.WHITE}>{children}</ListRenderer>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export const Desktop = styled(UnstyledDesktop)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px ${gutters};

  div[class$="market-list"] {
    position: relative;
  }

  a[class$="email"] {
    margin-right: 30px;
  }

  div[class$="masthead-desktop-icons"] {
    display: flex;
  }

  div[class$="masthead-desktop-icons"] a {
    display: flex;
    align-items: center;

    font-family: Roboto;
    line-height: 1.63;
    color: ${Colour.WHITE};
    text-decoration: none;
  }

  a span {
    white-space: nowrap;
  }

  a span:hover {
    font-weight: bold;
  }

  a {
    cursor: pointer;
  }

  a svg {
    margin-right: 9px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
