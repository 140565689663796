import { gql } from "@apollo/client";

export const GetAuthQuery = gql`
  query AuthQuery {
    getAuth {
      client_id
      client_secret
    }
  }
`;
