import { TCFData } from "../../../../types";
import { addCMPIframe } from "./add-cmp-iframe";
import { CMPEventHandler } from "./cmp-event-handler";
import { getCMPFrameContainer } from "./get-cmp-frame-container";
import { loadCMP } from "./load-cmp";
import { setupSourcepointConfiguration } from "./sp-configuration";
import { tcfAPIMock } from "./tcf-api-mock";

export const initCMP = async (
  onConsent: (p: TCFData) => void
): Promise<void> => {
  const cmpFrameContainer = getCMPFrameContainer(window);

  if (cmpFrameContainer === null) {
    addCMPIframe(window);
    window.__tcfapi = tcfAPIMock;
    window.addEventListener("message", CMPEventHandler(onConsent), false);
  }

  setupSourcepointConfiguration();

  await loadCMP(onConsent);
};
