import { useState } from "react";
import styled from "styled-components";
import { Market } from "../../../types";
import { Divider } from "../divider";
import { Desktop } from "./desktop";
import { Mobile } from "./mobile";

export interface Props {
  className?: string;
  customerName?: string;
  displayMarkets: boolean;
  isLoggedIn: boolean;
  markets: Market[];
  navLinks: React.ReactElement[];
  onLoginClick: () => void;
  onSelectMarket: (selected: { value: Array<string> }) => void;
  portal?: HTMLElement | null;
  profileLinks: React.ReactElement[];
  selectedMarket: string[];
}

const UnstyledMasthead = ({
  className,
  customerName,
  displayMarkets,
  isLoggedIn,
  markets,
  navLinks,
  onLoginClick,
  onSelectMarket,
  portal,
  profileLinks,
  selectedMarket = [],
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<Array<string>>([]);

  const handleMenuSelect = ({ value }: { value: Array<string> }) => {
    setSelectedMenu(value);
  };

  const handleNavMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <div className={className}>
      <Desktop
        {...{
          customerName,
          displayMarkets,
          isLoggedIn,
          markets,
          onLoginClick,
          onSelectMarket,
          portal,
          profileLinks,
          selectedMarket,
        }}
      />
      <Mobile
        {...{
          displayMarkets,
          isLoggedIn,
          isMenuOpen,
          markets,
          navLinks,
          onLoginClick,
          onNavMenu: handleNavMenu,
          onMenuSelect: handleMenuSelect,
          onSelectMarket,
          portal,
          profileLinks,
          selectedMenu,
          selectedMarket,
        }}
      />
      <Divider />
    </div>
  );
};

export const Mastheader = styled(UnstyledMasthead)`
  width: 100%;

  background-color: #1a1a1a;
`;
