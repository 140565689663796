import { Currency, TimeZone } from "cf-types";

export const london = {
  categories: [],
  currencyCode: Currency.GBP,
  localeName: "en_GB",
  name: "",
  pages: {
    checkoutTerms: "",
    cookiePolicy: "",
    privacyPolicy: "",
    termsAndConditions: "",
  },
  resourceUri: "",
  siteId: "uk-london",
  slug: "",
  supportEmail: "events@timeout.com",
  timezone: TimeZone.EUROPE_LONDON,
};
