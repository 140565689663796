import { UserSession } from "cf-types";
import { Store } from "../types";
import { dismissModal, ModalType } from "./modal";

export enum AuthActions {
  BULK_UPDATE_USER_DATA = "bulk_update_user_data",
  SET_API_KEY = "set_api_key",
  LOGOUT_USER = "logout_user",
  LOGIN_USER = "login_user",
}

interface SetApiKey {
  type: AuthActions.SET_API_KEY;
  payload: {
    apiKey: string;
  };
}

interface UpdateUser {
  type: AuthActions.BULK_UPDATE_USER_DATA;
  payload: {
    api_key?: UserSession["api_key"];
    customer: UserSession["customer"];
  };
}

export interface UserLogOut {
  type: AuthActions.LOGOUT_USER;
}

export interface UserLogIn {
  type: AuthActions.LOGIN_USER;
  payload: UserSession;
}

export const setApiKey = (payload: SetApiKey["payload"]): SetApiKey => {
  return {
    type: AuthActions.SET_API_KEY,
    payload,
  };
};

export const updateUserData = (payload: UpdateUser["payload"]): UpdateUser => {
  return {
    type: AuthActions.BULK_UPDATE_USER_DATA,
    payload,
  };
};

export const logOut = () => (dispatch: (p: UserLogOut) => void) => {
  window.localStorage.removeItem("login");
  window.localStorage.removeItem("access_token");

  dispatch({ type: AuthActions.LOGOUT_USER });
};

type Dispatch = (
  p: UserLogIn | ((d: (c: ModalType) => void, s: () => Store) => void)
) => void;

export const logIn = (payload: UserSession) => (dispatch: Dispatch) => {
  dispatch({ type: AuthActions.LOGIN_USER, payload });
  dispatch(dismissModal());
};
