import { UPDATE_EVENT_DATA } from "../types";

type Action = {
  type: string;
  payload?: any;
};

export const initialState = {
  title: "",
  imageSrc: "",
  currencyCode: "",
  purchaseType: "",
  eventGroupID: null,
  shippable: false,
};

const EventReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_EVENT_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default EventReducer;
