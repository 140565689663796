import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import thunk from "redux-thunk";
import { createWrapper } from "next-redux-wrapper";
import logger from "redux-logger";
import rootReducer from "./reducers";

const middleware = [thunk];

const enhancer = compose(applyMiddleware(...middleware));

const makeStore = () => createStore(rootReducer, enhancer);
export const wrapper = createWrapper(makeStore);
