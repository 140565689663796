// @ts-nocheck
import { CMP_FRAME_NAME } from "../constants";

/**
 * search iframe tree for a window object that contains a CMP iframe
 */
export const getCMPFrameContainer = (win: Window): Window | null => {
  if (!win || win === window.top) {
    return null;
  }

  return win.frames[CMP_FRAME_NAME] ? window : (win = win.parent);
};
