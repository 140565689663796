import React from "react";
import styled from "styled-components";
import { ArrowLeftIcon } from "../../../global/iconography/arrows/arrow-icons";
import { device } from "../newsletter-signup-widget/device-size";
import { INDICATOR_HEIGHT } from "./constants";

type Props = {
  clickHandler: () => void;
  hasPrev: boolean;
  label?: string;
};

const SliderLeftArrow: React.FC<Props> = ({ clickHandler, hasPrev }) => (
  <>
    {hasPrev && (
      <Nav type="prev" onClick={clickHandler}>
        <ArrowLeftIcon darkMode={true} />
      </Nav>
    )}
  </>
);

const Nav = styled.div`
  display: flex;
  position: absolute;
  width: 55px;
  height: 55px;
  background-color: rgba(0, 0, 0, 0.6);
  top: calc(50% - ${INDICATOR_HEIGHT * 0.5}px);
  cursor: pointer;
  z-index: 1;
  left: ${({ type }: { type: string }) => (type === "next" ? "auto" : "5px")};
  right: ${({ type }: { type: string }) => (type === "next" ? "5px" : "auto")};
  align-items: center;
  justify-content: center;

  transform: translateY(-50%);

  @media ${device.mobileL} {
    display: none;
  }
`;

export default SliderLeftArrow;
