import { gql } from "@apollo/client";

export const GetPaymentGatewayQuery = gql`
  query PaymentGatewayQuery($paymentData: String!) {
    getPaymentGateway(paymentData: $paymentData) {
      clientToken
      environment
      paymentHostedFields
      vemoActive
      applePayActive
      type
    }
  }
`;
