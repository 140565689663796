import React from "react";
import styled from "styled-components";
import { Mask } from "../mask/mask";
import { Overlay } from "../../../types";
import { TicketIcon } from "../../../global/iconography";

const overlayColorMap: { [k in Overlay]: string } = {
  dark: "#ffffff",
  light: "#1a1a1a",
};

export interface Props {
  children?: JSX.Element | React.ReactElement;
  className?: string;
  fixed?: boolean;
  Icon?: JSX.Element | React.ReactElement;
  message?: string;
  overlay?: Overlay;
  render: boolean;
}

const UnstyledPendingSpinner: React.FC<Props> = ({
  children,
  className,
  Icon,
  message = "Processing...",
  overlay = "light",
  render,
}) => {
  return (
    <Mask overlay={overlay} render={render}>
      <div className={className}>
        {Icon || children || <TicketIcon greyed={true} />}
        <span>{message}</span>
      </div>
    </Mask>
  );
};

export const PendingSpinner = styled(UnstyledPendingSpinner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    animation: anim-preloader 1.25s infinite cubic-bezier(0.77, 0, 0.175, 1);

    width: 48px;
    height: 48px;
  }

  span {
    margin-top: 8px;

    color: ${({ overlay = "light" }) => overlayColorMap[overlay]};
    font-size: 28px;
    font-family: "Roboto";
  }

  @keyframes anim-preloader {
    0% {
      transform: rotate(0deg);
    }

    66.6%,
    100% {
      transform: rotate(-180deg) translate(0, -1px);
    }
  }
`;
