import React from "react";
import styled from "styled-components";
import { Field } from "../../../types";
import { Button } from "../button/button";
import { Divider } from "../divider";
import { Error } from "../error/error";
import { InputField } from "../input-field/input-field";
import { SubscriptionSuccess } from "./subscription-success";

export interface Props {
  className?: string;
  emailField: Field;
  isSubscribed: boolean;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubscribe: (e: React.FormEvent<HTMLButtonElement>) => void;
}

const UnstyledNewsletterWidgetLite: React.FC<Props> = ({
  className,
  emailField,
  isSubscribed,
  onChangeEmail,
  onSubscribe,
}: Props) => {
  if (isSubscribed) {
    return <SubscriptionSuccess darkMode={false} />;
  }

  const prefix = className?.split(" ")[0];
  return (
    <div className={className}>
      <h3 className={`${prefix}-header`}>Don't miss out again!</h3>
      <Divider />
      <span className={`${prefix}-sign-up`}>
        Sign up to our super awesome newsletter to be the first to hear about
        Time Out’s sick offers.
      </span>
      <div className={`${prefix}-form-container`}>
        <form className={`${prefix}-form`}>
          <InputField
            error={emailField.error}
            id={"email"}
            name={"email"}
            onChange={onChangeEmail}
            placeholder={"Email"}
            type={"email"}
            value={emailField.value}
          />
          {emailField.error && (
            <Error
              className={`${prefix}-desktop-error`}
              children={"Please enter a valid email!"}
            />
          )}
          <Button onClick={onSubscribe} children={"Sign up"} />
        </form>
        {emailField.error && (
          <Error
            className={`${prefix}-mobile-error`}
            children={"Please enter a valid email!"}
          />
        )}
      </div>
    </div>
  );
};

export const NewsletterWidgetLite = styled(UnstyledNewsletterWidgetLite)`
  display: flex;
  flex-direction: column;
  width: 298px;
  padding: 15px 20px 25px;

  border: solid 1px #aaa;

  form[class$="form"] {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      flex-direction: row;
    }
  }

  h3[class$="header"] {
    height: 32px;
    margin-top: 15px;
    margin-bottom: 15px;

    color: #1a1a1a;

    font-family: "FranklinGothicLTPro-DmCm";
    font-size: 28px;
    line-height: 1.14;
    text-align: center;

    @media (max-width: 768px) {
      margin-bottom: 0;
      text-align: left;
    }
  }

  span[class$="sign-up"] {
    margin-top: 20px;
    margin-bottom: 20px;

    color: #1a1a1a;

    font-family: "Roboto";
    font-size: 14px;

    @media (max-width: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;

      text-align: left;
    }
  }

  input {
    padding-top: 10px;
    padding-bottom: 10px;

    border: solid 1px #aaa;
    background-color: #fff;

    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.6;

    @media (max-width: 768px) {
      flex: 1;
    }
  }

  button {
    margin-top: 10px;

    background-color: #1a1a1a;

    color: #fff;

    font-weight: bold;
    font-size: 16px;

    @media (max-width: 768px) {
      margin-top: 0;
      width: 105px;
    }
  }

  hr {
    border-color: #d8d8d8;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div[class$="form-container"] {
    display: flex;
    flex-direction: column;
  }

  [class$="mobile-error"] {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  [class$="desktop-error"] {
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;

    border-color: transparent;
  }
`;
