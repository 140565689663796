import { NotificationActions, NotificationType } from "../actions";

type Action = NotificationType;

export const initialState = {
  notify: () => {},
};

const NotificationReducer = (
  state = initialState,
  { payload, type }: Action
) => {
  switch (type) {
    case NotificationActions.SET_NOTIFIER:
      return {
        ...state,
        notify: payload.notify,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
