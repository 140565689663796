import { StringLiteral } from "@babel/types";
import React from "react";
import styled from "styled-components";

export interface CollectionWidgetProps {
  className?: string;
  websitePath: string;
  headerImageUrl: string;
  title: string;
  description: string;
}

const UnstyledCollectionWidget = ({
  className,
  websitePath,
  headerImageUrl,
  title,
  description,
}: CollectionWidgetProps) => (
  <div className={className}>
    <a href={websitePath}>
      <img src={headerImageUrl} alt={title} />
      <div className={`${className}-text-area`}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </a>
  </div>
);

export const CollectionWidget = styled(UnstyledCollectionWidget)`
  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }

  div[class$="text-area"] {
    background-color: #1a1a1a;
    padding: 25px;
  }

  h1,
  p {
    color: #fff;
  }

  h1 {
    margin: 0 0 10px 0;
    font-family: FranklinGothicLTPro-DmCm;
    line-height: 1.13;
    font-size: 32px;
  }

  p {
    font-family: Roboto;
    line-height: 1.5;
    letter-spacing: normal;
    font-size: 16px;
    margin: 0;
  }

  @media (min-width: 700px) {
    a {
      max-width: 776px;
      height: 223px;
      flex-direction: row-reverse;
    }

    img {
      width: 325px;
    }

    div[class$="text-area"] {
      padding: 45px 25px;
    }
  }
`;
