import React from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { ImageRatio } from "../../../types";
import { ImageComponent } from "../image-component-product/image-component";
import { INDICATOR_HEIGHT } from "./constants";
import SliderIndicator from "./slider-indicator";
import SliderLeftArrow from "./slider-left-arrow";
import SliderRightArrow from "./slider-right-arrow";

import "react-responsive-carousel/lib/styles/carousel.css";

type PageProps = {
  images: Array<{ url?: string; webpUrl?: string }>;
  ratio?: ImageRatio;
  className?: string;
};

const renderImageCarousel = (
  imageData: Array<{ url?: string; webpUrl?: string }>,
  ratio?: ImageRatio
) =>
  imageData.map((image, index) => (
    <Content key={index} aspectRatio={ratio}>
      <ImageComponent href={image.url} webpUrl={image.webpUrl} />
    </Content>
  ));

const renderPrevArrow = (
  clickHandler: () => void,
  hasPrev: boolean,
  label: string
) => (
  <SliderLeftArrow
    clickHandler={clickHandler}
    hasPrev={hasPrev}
    label={label}
  />
);

const renderNextArrow = (
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) => (
  <SliderRightArrow
    clickHandler={clickHandler}
    hasNext={hasNext}
    label={label}
  />
);

const renderIndicator = (
  onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
  isSelected: boolean,
  index: number,
  label: string
) => (
  <SliderIndicator
    onClickHandler={onClickHandler}
    isSelected={isSelected}
    index={index}
    label={label}
  />
);

const unStyledImageCarousel: React.FC<PageProps> = ({
  images = [],
  className,
}) => {
  return (
    <div className={className}>
      <Carousel
        emulateTouch={true}
        renderArrowPrev={renderPrevArrow}
        renderArrowNext={renderNextArrow}
        renderIndicator={renderIndicator}
        showThumbs={false}
        useKeyboardArrows={true}
        statusFormatter={(current, total) => `${current} / ${total}`}
      >
        {renderImageCarousel(images)}
      </Carousel>
    </div>
  );
};

export const ImageCarousel = styled(unStyledImageCarousel)`
  height: 100%;

  .carousel-root,
  .carousel,
  .slider,
  .slider-wrapper {
    height: 100%;
  }

  .carousel-status {
    top: unset;
    right: 15px;
    bottom: ${INDICATOR_HEIGHT}px;
    padding: 3px 6px;
    text-shadow: none;
    background-color: rgba(26, 26, 26, 0.5);
  }

  .carousel-slider {
    overflow: visible;
  }

  .control-dots {
    bottom: 12px;

    display: flex;
    justify-content: center;

    margin: 0;

    @media (max-width: 428px) {
      display: none;
    }
  }

  .control-dots li:not(:first-of-type) {
    margin-left: 5px;
  }
`;

const Content = styled.div<{ aspectRatio?: ImageRatio }>`
  height: calc(100% - ${INDICATOR_HEIGHT}px);
  width: 100%;

  @media (max-width: 428px) {
    height: 100%;
  }
`;
