import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Analytics, ErrorMessages, OptInDataSource } from "cf-constants";
import {
  Basket,
  Currency,
  CustomError,
  EventGroup,
  EventUI,
  GoogleTagManager,
  ThirdPartyOptIn,
  Ticket,
  UserSessionResponse,
} from "cf-types";
import { gtmDataLayerPush, metaTrack } from "cf-utils";
import { PatchCustomerDataQuery, PostOutingQuery } from "pages/api/queries";
import { getClient } from "apollo-client";
import { getAuth, getGuestAccessToken, getSecureSession } from "src/utils";
import { AuthModal } from "src/components";
import { Store } from "../types";
import { initialState } from "../reducers/user-reducer";
import { AppParams, setIsLoading } from "./app";
import { logOut, UserLogOut } from "./auth";
import { dismissModal, ModalType, renderModal } from "./modal";
import { BulkUpdate, updateBulkUser } from "./user";

const { latestOffers } = OptInDataSource;

export enum BookingActions {
  BOOKING_CONFIRMED = "booking_confirmed",
  BOOKING_ERROR = "booking_error",
  RESET_BOOKING = "reset_booking",
  RESET_ERROR = "reset_error",
  SET_TICKET_GROUPS = "set_ticket_groups",
  SET_BASKET = "set_basket",
  SET_THIRD_PARTY_DATA_SHARE_CONSENT = "set_third_party_data_share_consent",
  RESET_THIRD_PARTY_DATA_SHARE_CONSENT = "reset_third_party_data_share_consent",
  SET_THIRD_PARTY_OPT_IN = "set_third_party_opt_in",
}

export enum BookingStages {
  BOOKING_ERROR = "booking_error",
  BOOKING_INITIAL = "booking_initial",
  BOOKING_SUCCESS = "booking_success",
}

export interface BookingConfirmed {
  _extra: {
    purchaseId: number;
  };
  error?: CustomError;
  eventGroup: {
    currencyCode: Currency;
    name: string;
  };
  ticketCount: number;
  ticketGroups: Array<{
    event: Pick<
      EventUI,
      "discountPrice" | "seatDescription" | "ticketIncrement"
    >;
    tickets: Array<Pick<Ticket, "price" | "currencyCode" | "reference">>;
  }>;
}

export type Dispatch = (
  p:
    | SetConfirmed
    | SetError
    | BulkUpdate
    | AppParams
    | ModalType
    | ((
        d: (p: ModalType | UserLogOut | SetConfirmed) => void,
        s: () => Store
      ) => void)
) => void;
{
  type: BookingActions.SET_THIRD_PARTY_DATA_SHARE_CONSENT;
}

interface SetThirdPartyDataShareConsent {
  type: BookingActions.SET_THIRD_PARTY_DATA_SHARE_CONSENT;
}

export const setThirdPartyDataShareConsent =
  (): SetThirdPartyDataShareConsent => {
    return {
      type: BookingActions.SET_THIRD_PARTY_DATA_SHARE_CONSENT,
    };
  };

interface ResetThirdPartyDataShareConsent {
  type: BookingActions.RESET_THIRD_PARTY_DATA_SHARE_CONSENT;
}

export const resetThirdPartyDataShareConsent =
  (): ResetThirdPartyDataShareConsent => {
    return {
      type: BookingActions.RESET_THIRD_PARTY_DATA_SHARE_CONSENT,
    };
  };

interface SetThirdPartyDataOptIn {
  type: BookingActions.SET_THIRD_PARTY_OPT_IN;
  payload: {
    thirdPartyOptIn: ThirdPartyOptIn;
  };
}

export const setThirdPartyDataOptIn = (
  payload: SetThirdPartyDataOptIn["payload"]
): SetThirdPartyDataOptIn => {
  return {
    type: BookingActions.SET_THIRD_PARTY_OPT_IN,
    payload: {
      thirdPartyOptIn: payload["thirdPartyOptIn"],
    },
  };
};

export interface SetConfirmed {
  type: BookingActions.BOOKING_CONFIRMED;
  payload: {
    confirmed: BookingConfirmed;
  };
}

const setConfirmed =
  (confirmed: BookingConfirmed, config?: BookingrocessConfig) =>
  (dispatch: (p: SetConfirmed) => void, getState: () => Store) => {
    const { BasketItems, Booking, EventData, Market } = getState();
    const { currencyCode } = EventData;
    const { basket } = Booking;
    const { items } = BasketItems;
    const { selectedMarket } = Market;
    const { _extra } = confirmed;
    const { totalPrice, tracking } = config ?? {};
    const { eventData, route } = tracking ?? {};
    const [{ categories }] = selectedMarket;

    dispatch({
      payload: { confirmed },
      type: BookingActions.BOOKING_CONFIRMED,
    });

    const getCategory = ({ seatDescription }: EventUI) => {
      const eventTypeTitle = eventData?.eventType?.title.toLowerCase();
      const purchaseType = eventData?.purchaseType;
      const name = eventData?.title?.toLowerCase();

      return `${eventTypeTitle}/${purchaseType}/${name}/${seatDescription.toLowerCase()}`;
    };

    const name = eventData?.title?.toLowerCase() ?? "";

    gtmDataLayerPush({ ecommerce: null });
    gtmDataLayerPush(
      {
        event: Analytics.CustomGTMEvent.EC_PURCHASE,
        ecommerce: {
          currencyCode,
          [Analytics.GTMEcommerceAction.PURCHASE]: {
            actionField: {
              coupon: basket.promoCode?.name,
              id: `${_extra.purchaseId}`,
              revenue: `${basket.chargeAmount}`,
            },
            products: items.map(({ item, quantity }) => {
              return {
                category: getCategory(item),
                id: `${eventData?.eventGroupID}`,
                name,
                price: item.priceWithoutFee.toFixed(2),
                quantity,
                variant: eventData?.purchaseType,
              };
            }),
          },
        },
        payload: {
          pageName: `${Analytics.section} : ${Analytics.PageName.BOOKING_SUCCESS}`,
          pageType: Analytics.PageType.CHECKOUT,
        },
      },
      categories,
      route
    );

    metaTrack([
      Analytics.MetaPixelEvent.PURCHASE,
      {
        content_type: "product",
        contents: items.map(({ item, quantity }) => {
          return {
            content_category: getCategory(item),
            currency: currencyCode,
            id: `${_extra.purchaseId}`,
            content_name: name,
            value: item.priceWithoutFee,
            quantity,
          };
        }),
        currency: currencyCode,
        num_items: items.length,
        value: totalPrice,
      },
    ]);
  };

interface SetBasket {
  type: BookingActions.SET_BASKET;
  payload: { basket: Basket };
}

export const setBasket = (payload: { basket: Basket }): SetBasket => {
  return {
    type: BookingActions.SET_BASKET,
    payload,
  };
};

interface ResetBooking {
  type: BookingActions.RESET_BOOKING | BookingActions.RESET_ERROR;
}

export const resetBooking = (): ResetBooking => {
  gtmDataLayerPush({ ecommerce: null });
  return {
    type: BookingActions.RESET_BOOKING,
  };
};

export const resetError = (): ResetBooking => {
  return {
    type: BookingActions.RESET_ERROR,
  };
};

interface SetError {
  payload: {
    error: CustomError | Error;
  };
  type: BookingActions.BOOKING_ERROR;
}

export const setError = (payload: { error: CustomError | Error }): SetError => {
  return {
    payload,
    type: BookingActions.BOOKING_ERROR,
  };
};

const updateCustomer = async (
  {
    api_key,
    deviceId,
    optInData,
    user,
  }: {
    api_key: string;
    deviceId: string;
    optInData: Store["OptIn"]["optInData"];
    user: Store["User"];
  },
  client: ApolloClient<NormalizedCacheObject>,
  dispatch: Dispatch
) => {
  try {
    const { data } = await client.query<{
      patchCustomerData: Omit<
        UserSessionResponse["customer"],
        "setting_receive_newsletter_emails"
      >;
    }>({
      query: PatchCustomerDataQuery,
      variables: {
        user: JSON.stringify({
          api_key,
          deviceId,
          email: user.email.value,
          first_name: user.firstName.value,
          last_name: user.lastName.value,
          phone_number: user.phone.value,
          setting_receive_newsletter_emails: optInData.find(
            ({ field }) => field === latestOffers.field
          )?.value,
        }),
      },
    });
    const { email, first_name, last_name, phone_number } =
      data.patchCustomerData;

    dispatch(
      updateBulkUser({
        fields: {
          email: { ...initialState.email, value: email },
          firstName: { ...initialState.firstName, value: first_name },
          lastName: { ...initialState.lastName, value: last_name },
          phone: { ...initialState.phone, value: phone_number },
        },
      })
    );
  } catch (error) {
    /**
     * For now will treat as Fire and Forget as it should not interfere with actual payment.
     * We dont really care if the patch does not succeed as it can be done in profile page
     *
     * Implementing a try catch here so the error does not bubble further up the tree.
     */
  }
};

const getUserApiKey = async (
  { deviceId }: { deviceId: string },
  client: ApolloClient<NormalizedCacheObject>,
  dispatch: Dispatch
) => {
  try {
    const access_token = window.localStorage.getItem("access_token") ?? "";
    return await getSecureSession(client, {
      access_token,
      deviceId,
    });
  } catch (e) {
    const err = e as Error;
    const error = {
      buttonTitle: "LOG IN",
      message: ErrorMessages.Checkout.GET_AUTH_USER_PROFILE,
      name: err.name,
      onClose: () => {
        dispatch(dismissModal());
        dispatch(logOut());
        dispatch(
          renderModal({
            component: <AuthModal />,
            render: true,
          })
        );
      },
    };

    dispatch(setIsLoading({ isLoading: false }));
    dispatch(setError({ error }));
    return { api_key: null };
  }
};

const getGuestApiKey = async (
  store: Store["OptIn"] & Store["User"] & { deviceId: string },
  client: ApolloClient<NormalizedCacheObject>,
  dispatch: Dispatch
) => {
  try {
    const auth = await getAuth(client);
    const { access_token } = await getGuestAccessToken(store, auth, client);

    return await getSecureSession(client, {
      access_token,
      deviceId: store.deviceId,
    });
  } catch (e) {
    const error = e as Error;

    dispatch(setIsLoading({ isLoading: false }));
    dispatch(setError({ error }));
    return { api_key: null };
  }
};

type BookingrocessConfig = {
  applePayNonce?: string;
  correlation_id: string;
  device_session_id: string;
  fraud_merchant_id: string;
  totalPrice: number;
  tracking: {
    eventData: EventGroup;
    route: GoogleTagManager.Route;
  };
};

export const startBookingProcess =
  (devURL: string, config?: BookingrocessConfig) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    const {
      AppState,
      AuthData,
      Booking,
      Checkout,
      Card,
      EventData,
      OptIn,
      User,
    } = getState();
    const { basket, thirdPartyDataShareConsent } = Booking;
    const { delivery } = Checkout;
    const { shippable } = EventData;
    const { nonce } = Card;
    const { featureFlags, device } = AppState;
    const { loggedIn } = AuthData;
    const { optInData } = OptIn;

    const applePayNonce = config?.applePayNonce;

    const client = getClient(devURL);

    const { api_key } = loggedIn
      ? await getUserApiKey({ deviceId: device.deviceId }, client, dispatch)
      : await getGuestApiKey(
          { deviceId: device.deviceId, optInData, ...User },
          client,
          dispatch
        );

    if (api_key) {
      loggedIn &&
        updateCustomer(
          {
            api_key,
            deviceId: device.deviceId,
            user: User,
            optInData,
          },
          client,
          dispatch
        );

      try {
        const { data: event } = await client.query<{
          postOuting: BookingConfirmed;
        }>({
          query: PostOutingQuery,
          variables: {
            ticketData: JSON.stringify({
              apiKey: api_key,
              deviceId: device.deviceId,
              device_data: config,
              nonce: applePayNonce ? applePayNonce : nonce,
              payment_amount: basket.chargeAmount,
              save_credit_card: false,
              third_party_data_share_consent: thirdPartyDataShareConsent,
              shipping: shippable && {
                address: {
                  full_name: delivery.recipientName.value,
                  address_line_1: delivery.address.value,
                  city: delivery.townCity.value,
                  region: delivery.state.value,
                  postal_code: delivery.zipcode.value,
                },
              },
              ticket_groups: basket.ticketGroups.map(({ event, tickets }) => {
                return {
                  event: event.resourceUri,
                  ticket_count: tickets?.[0].ticketCount,
                };
              }),
              ...(featureFlags.guestCheckout ? { credit_amount: 0 } : {}),
              ...(basket.promoCode
                ? {
                    promo_code: basket.promoCode.name,
                    promo_code_amount: basket.promoCode.amount,
                  }
                : {}),
            }),
          },
        });

        if (event.postOuting.error) {
          dispatch(
            setError({
              error: event.postOuting.error,
            })
          );
        } else {
          dispatch(setConfirmed(event.postOuting, config));
        }
      } catch (err) {
        const error = err as Error;
        dispatch(setError({ error }));
      } finally {
        dispatch(setIsLoading({ isLoading: false }));
      }
    }
  };
