import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  DefaultOptions,
} from "@apollo/client";

let client: ApolloClient<NormalizedCacheObject>;

export const getClient = (rootURL: string) => {
  if (client) {
    return client;
  }

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
  };

  client = new ApolloClient({
    uri: `${rootURL}/api/graphql`,
    cache: new InMemoryCache(),
    defaultOptions,
  });

  return client;
};
