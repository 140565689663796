import { useState } from "react";

type UseProfileDropdown = (
  a: boolean,
  b: () => void
) => [boolean, () => void, () => void];

export const useProfileDropdown: UseProfileDropdown = (isLoggedIn, onLogin) => {
  const [profileDropdown, setProfileDropdown] = useState(false);

  const handleProfileButtonClick = () => {
    if (isLoggedIn) {
      setProfileDropdown((prev) => !prev);
    } else {
      onLogin && onLogin();
    }
  };

  const handleClose = () => {
    if (isLoggedIn) {
      setProfileDropdown((prev) => !prev);
    }
  };

  return [profileDropdown, handleProfileButtonClick, handleClose];
};
