import { gql } from "@apollo/client";

export const PostSecureSessionQuery = gql`
  query SecureSessionQuery($tokens: String!) {
    postSecureSession(tokens: $tokens) {
      api_key
      customer {
        email
        first_name
        last_name
        phone_number
        setting_receive_newsletter_emails
      }
      device
      error {
        fields
        message
        name
        stack
        status
      }
      resource_uri
    }
  }
`;
