import { DeepLinks } from "cf-config";
import { Analytics } from "cf-constants";
import { MetaDataContext, metaDataInitialState } from "cf-context";
import { EventPageMetaData, Layers, Page } from "cf-types";
import { gtmDataLayerPush, throttleFunc } from "cf-utils";
import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "redux/types";
import {
  Button,
  Footer,
  Mastheader,
  Modal as AppModal,
  NavigationBar,
  OnToast,
  PendingSpinner,
  Toast,
} from "../src/components/to-components";
import { getClient } from "apollo-client";
import {
  footerLinks,
  legalTexts,
  profileLinks,
  sharingLinks,
} from "static-data";
import {
  dismissModal,
  logIn,
  logOut,
  renderModal,
  setMarkets,
  setNotifier,
  setSelectedMarket,
} from "redux/actions";
import { wrapper } from "redux/store";
import { AuthModal, MetaData, Tracking } from "src/components";
import {
  useDeepLink,
  useDeviceId,
  useElementInView,
  useNewsletterSubscription,
  useRouterEvent,
} from "src/hooks";
import { normaliseQueryParam } from "src/utils";
import { GetMarketsQuery, PatchDeviceDataQuery } from "./api/queries";
import { Market } from "src/components/to-components/types";
import LogRocket from "logrocket";

import styles from "./_app.module.scss";

import "../styles/globals.css";
import "../src/components/to-components/checkout/web/cookie-banner/cookie-banner.scss";

const throttleTime = 5000;

function Root({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const toastRef = useRef<OnToast>(null);
  const [isVisible, newsLetterRef] = useElementInView<HTMLDivElement>();
  const [deviceId] = useDeviceId();
  const [newletterField, newsletterHandler] = useNewsletterSubscription();
  const metaDataValue = useState<EventPageMetaData>(metaDataInitialState);
  const [portal, setPortal] = useState<HTMLElement | null>(null);
  const [isNewYorkMarket, setIsNewYorkMarket] = useState(false);
  const [visibility, setVisibility] = useState<"visible" | "hidden">("hidden");
  const [dropdwonPortal, setDropdownPortal] = useState<HTMLElement | null>(
    null
  );
  const { loggedIn } = useSelector(({ AuthData }: Store) => AuthData);
  const { loggedInError } = useSelector(({ AuthData }: Store) => AuthData);
  const { registered } = useSelector(({ AuthData }: Store) => AuthData);
  const { registeredOrigin } = useSelector(({ AuthData }: Store) => AuthData);
  const Modal = useSelector(({ Modal }: Store) => Modal);
  const { isLoading } = useSelector(({ AppState }: Store) => AppState);
  const { pageName } = useSelector(({ Page }: Store) => Page);
  const { first_name } = useSelector(
    ({ AuthData }: Store) => AuthData.customer
  );
  const selectedMarket = useSelector(
    ({ Market }: Store) => Market?.selectedMarket[0]
  );
  const { markets } = useSelector(({ Market }: Store) => Market);

  const [currentRoute, setCurrentRoute] = useState<string>(router.asPath);

  const onRouteChangeStart = (url: string) => {
    setCurrentRoute(url);
  };

  useRouterEvent("routeChangeStart", onRouteChangeStart, [pageName]);

  const onLoginClickCB = () => {
    dispatch(
      renderModal({
        component: <AuthModal />,
        render: true,
      })
    );
  };

  const onLogoutClickCB = () => {
    dispatch(logOut());
  };

  useDeepLink([
    {
      action: (url: string) => {
        dispatch(
          renderModal({
            component: <AuthModal param={url} />,
            onClose: () => {
              router.push("/", "", { shallow: true });
            },
            render: true,
          })
        );
      },
      matcher: DeepLinks.openAuthModal,
    },
    {
      action: () => {
        setIsNewYorkMarket(true);
      },
      matcher: DeepLinks.enableMarkets,
    },
    {
      action: () => {
        setIsNewYorkMarket(false);
      },
      matcher: DeepLinks.disableMarkets,
    },
  ]);

  useEffect(() => {
    const authData = localStorage.getItem("login");

    if (authData) {
      dispatch(logIn(JSON.parse(authData)));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === "login" && !!event.newValue) {
        // login on all tabs if user logs in on one
        dispatch(logIn(JSON.parse(event.newValue)));
      }

      if (event.key === "login" && event.newValue === null) {
        // logout from all tabs if user logs out in one
        onLogoutClickCB();
      }
    });
  }, []);

  useEffect(() => {
    setDropdownPortal(document.getElementById(Layers.DROPDOWN_LAYER));
  }, []);

  useEffect(() => {
    setPortal(document.getElementById(Modal.layer ?? ""));
  }, [Modal.layer]);

  useEffect(() => {
    setVisibility("visible");
  }, []);

  useEffect(() => {
    if (toastRef.current?.onToast) {
      dispatch(
        setNotifier({
          notify: toastRef.current?.onToast,
        })
      );
    }
  }, [toastRef]);

  const route = {
    query: {
      event: router.query?.event,
      market: selectedMarket.slug,
    },
    url: currentRoute,
  };

  const updateDataLayer = useMemo(
    () => throttleFunc(gtmDataLayerPush, throttleTime),
    []
  );

  useEffect(() => {
    isVisible &&
      updateDataLayer(
        {
          event: Analytics.CustomGTMEvent.TO_NEWSLETTER_IN_VIEW,
        },
        selectedMarket.categories,
        route
      );
  }, [isVisible]);

  const client = getClient("");

  const getApiKey = () => {
    const { api_key } = JSON.parse(localStorage.getItem("login") || "{}");
    return api_key;
  };

  useEffect(() => {
    (async () => {
      if (deviceId) {
        const { data } = await client.query({
          fetchPolicy: "cache-first",
          query: GetMarketsQuery,
          variables: {
            marketData: JSON.stringify({
              deviceId,
            }),
          },
        });

        dispatch(setMarkets(data.getMarkets.objects));
      }
    })();
  }, [deviceId]);

  useEffect(() => {
    const location = normaliseQueryParam(router.query.location);

    if (deviceId && location && selectedMarket.slug !== location) {
      (async () => {
        const {
          data: { getMarkets },
        } = await client.query({
          fetchPolicy: "cache-first",
          query: GetMarketsQuery,
          variables: {
            marketData: JSON.stringify({
              deviceId,
              market: location,
            }),
          },
        });

        dispatch(setSelectedMarket(getMarkets.objects));
      })();
    }
  }, [router.query.location, deviceId]);

  useEffect(() => {
    if (selectedMarket.slug === "london") {
      const script = document.createElement("script");
      script.src = "https://cdn.parsely.com/keys/timeout.com/p.js";
      script.id = "parsely-cfg";
      script.async = true;

      document.body.appendChild(script);
    }
  }, [selectedMarket.slug]);

  // TODO: Disable crawling on checkout NY until we have functional site map
  useEffect(() => {
    const name = "robots";
    const robots = document.head.querySelector<HTMLMetaElement>(
      `[name='${name}']`
    );
    const content = isNewYorkMarket ? "noindex" : "all";

    if (robots) {
      robots.content = content;
    } else {
      const meta = document.createElement("meta");
      meta.content = content;
      meta.name = name;

      document.head.insertBefore(meta, document.head.firstChild);
    }
  }, [isNewYorkMarket]);

  const onSelectMarket = ({ value }: { value: string[] }) => {
    const [market] = value;
    const selected = markets.find(({ name }) => name === market);

    if (selected) {
      (async () => {
        await client.query({
          query: PatchDeviceDataQuery,
          variables: {
            deviceData: JSON.stringify({
              authorization: loggedIn ? getApiKey() : "",
              deviceId,
              selected_market: selected?.resourceUri,
            }),
          },
        });

        router.push(`/${selected.slug}`, "", {
          shallow: true,
        });
        dispatch(setSelectedMarket([selected]));
      })();
    }
  };

  const handleDismissModal = () => {
    dispatch(dismissModal());
  };

  const getLink = (link: ((p: Page) => string) | string) => {
    return typeof link === "function" ? link(selectedMarket.pages) : link;
  };

  const renderFooterLinks = useMemo(() => {
    return footerLinks.reduce<JSX.Element[]>(
      (acc, { dataTrack, link, onClick, target, text }, index) => {
        if (text === "FAQ" && selectedMarket.slug === "new-york") {
          return acc;
        }

        const isTargetBlank = target === "_blank";
        const href = isTargetBlank ? getLink(link) : router.asPath;
        const key = `${index}-${text}`;

        const anchor = (
          <a key={key} data-track={dataTrack} onClick={onClick} target={target}>
            {text}
          </a>
        );

        if (!isTargetBlank) {
          acc.push(anchor);
          return acc;
        }

        acc.push(
          <Link key={key} href={href} passHref>
            {anchor}
          </Link>
        );

        return acc;
      },
      []
    );
  }, [router.asPath, selectedMarket.slug]);

  const renderProfileLinks = useMemo(() => {
    return profileLinks.map(({ link, title }, index) => {
      const key = `${index}-${title}`;
      if (link) {
        return (
          <Link key={key} href={link}>
            {title}
          </Link>
        );
      }

      return (
        <Button key={key} onClick={onLogoutClickCB}>
          {title}
        </Button>
      );
    });
  }, []);

  const renderLinks = useMemo(() => {
    return selectedMarket.categories.map(({ slug, title }, index) => {
      const collection = slug === "featured" ? "" : `/${slug}`;
      return (
        <Link
          key={`${index}-${title}`}
          href={`/${selectedMarket.slug}${collection}`}
        >
          {title}
        </Link>
      );
    });
  }, [selectedMarket.slug]);

  // Run method only once
  useEffect(() => {
    LogRocket.init("wmzjng/timeout_poc");
  }, []);

  return (
    <ApolloProvider client={client}>
      <MetaDataContext.Provider value={metaDataValue}>
        {/*
              TODO: Additional Div was introduced as a hotfix for 
              DRD-540 based https://cleverbeagle.com/blog/articles/look-for-simple-fixes-first        
        */}
        <div style={{ visibility }}>
          <Mastheader
            navLinks={renderLinks}
            displayMarkets={isNewYorkMarket}
            isLoggedIn={loggedIn}
            portal={dropdwonPortal}
            profileLinks={renderProfileLinks}
            onLoginClick={onLoginClickCB}
            onSelectMarket={onSelectMarket}
            customerName={first_name}
            selectedMarket={[selectedMarket.name]}
            markets={markets as Market<Page>[]}
          />
          <div className={styles.navigation}>
            <NavigationBar links={renderLinks} />
          </div>
          <Component {...pageProps} />
          <Footer
            footerLinksItems={renderFooterLinks}
            shareLinksItems={sharingLinks}
            legalText={legalTexts[selectedMarket.slug]}
            newsLetterRef={newsLetterRef}
            emailField={newletterField.email}
            isSubscribed={newletterField.isSubscribed}
            onChangeEmail={newsletterHandler.handleEmailInput}
            onSubscribe={newsletterHandler.handleSubscribe}
            termsLink={selectedMarket.pages?.termsAndConditions}
          />
        </div>
        <MetaData />
        <Tracking />
        <AppModal
          portal={portal}
          onClose={handleDismissModal}
          render={Modal.render}
        >
          {Modal.component ?? null}
        </AppModal>
        <PendingSpinner render={isLoading} />
        <Toast ref={toastRef} />
      </MetaDataContext.Provider>
    </ApolloProvider>
  );
}

const withRedux = wrapper.withRedux(Root);

export default withRedux;
