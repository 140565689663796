import React from "react";
import { CloseButtonIcon } from "../../../global/iconography/close-button-icon";
import styled from "styled-components";

export interface MobileHeaderProps {
  className?: string;
  name: string;
  type: string;
  onClickCB: (e: React.MouseEvent | React.KeyboardEvent) => void;
}
const unstyledBookingWidgetMobileHeader = ({
  className,
  type,
  name,
  onClickCB,
}: MobileHeaderProps) => (
  <div className={className}>
    <div className={`${className}-first-line`}>
      <span className={`${className}-title`}>{`Get ${type}`}</span>
      <CloseButtonIcon width={15} height={15} onClickCB={onClickCB} />
    </div>
    <span className={`${className}-description`}>{name}</span>
  </div>
);

export const BookingWidgetMobileHeader = styled(
  unstyledBookingWidgetMobileHeader
)`
  display: flex;
  flex-direction: column;
  background-color: black;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  padding: 15px;

  div[class$="-first-line"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  span[class$="title"] {
    font-weight: bold;
  }

  span[class$="description"] {
    max-width: 285px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
