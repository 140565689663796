import { gql } from "@apollo/client";

export const GetCollectionsQuery = gql`
  query CollectionsQuery($collectionData: String!) {
    getCollections(collectionData: $collectionData) {
      websitePath
      headerImageUrl
      title
      description
    }
  }
`;
