export const fieldConfig = {
  cvv: {
    selector: "#cvv",
    placeholder: "123",
  },
  expirationDate: {
    selector: "#expirationDate",
    placeholder: "MM / YY",
  },
  number: {
    selector: "#number",
    placeholder: "XXXX XXXX XXXX XXXX",
  },
  postalCode: {
    selector: "#postalCode",
    placeholder: "",
    type: "text",
  },
};

export const styles = {
  input: { "font-size": "16px" },
};
