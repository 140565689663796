import { gql } from "@apollo/client";

export const PostNewsletterQuery = gql`
  query PostNewsletterQuery($newsletterData: String!) {
    postNewsletter(newsletterData: $newsletterData) {
      hashedEmail
      success
      userId
    }
  }
`;
