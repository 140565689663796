import { gql } from "@apollo/client";

export const GetGuestAccessTokenQuery = gql`
  query GuestAccessTokenQuery($creds: String!) {
    getGuestAccessToken(creds: $creds) {
      UID
      UIDSignature
      access_token
      createdAt
      email
      expires_in
      firstName
      lastLoginAt
      lastName
      nickname
      photoUrl
      refresh_token
      scope
      signatureTimestamp
      token_type
      updatedAt
    }
  }
`;
