// @ts-nocheck
interface GetScriptParams {
  src: string;
  timeout: number;
  parent: "head" | "body";
  inline?: string;
  load?: string;
  attributes: Partial<{ [key in keyof HTMLScriptElement]: string }>;
}

const createScript = (
  src: string,
  inline?: string,
  attributes?: Partial<{ [key in keyof HTMLScriptElement]: string }>
): HTMLScriptElement => {
  let script = document.createElement("script");

  if (inline) {
    script.appendChild(document.createTextNode(inline));
  } else {
    script.src = src;
  }

  /* 
    Adds all specified attributes - e.g:  defer, async, charset="UTF-8", etc
  */

  for (let attrKey in attributes) {
    const attribute = attributes[attrKey];
    if (attribute === "true") {
      script[attrKey] = true;
    } else {
      script.setAttribute(attrKey, attribute);
    }
  }

  return script;
};

/* 
  Fallback for browsers that don"t yet support requestIdleCallBack
  e.g - Safari
*/
const fallbackRequestIdleCb = (
  cb: () => void,
  { timeout }: IdleRequestOptions
) => {
  setTimeout(cb, timeout);
};

export const loadScript = ({
  src,
  timeout,
  parent = "body",
  inline,
  load,
  attributes = {},
}: GetScriptParams): Promise<HTMLScriptElement> | PromiseRejectionEvent => {
  const requestIdleCallBack =
    window.requestIdleCallback || fallbackRequestIdleCb;

  return new Promise((resolve, reject) => {
    requestIdleCallBack(
      () => {
        const script = createScript(src, inline, attributes);
        script.addEventListener("error", () =>
          reject(new Error(`Failed to load script: "${src}"`))
        );

        const element = document?.[parent]?.appendChild(script);

        if (load) {
          script.addEventListener("load", () => {
            resolve(element);
          });
        } else {
          resolve(element);
        }
      },
      { timeout }
    );
  });
};
