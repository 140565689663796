export interface TicketIconProps {
  greyed?: boolean;
  width?: number;
  height?: number;
}
export const TicketIcon = ({
  greyed = false,
  width = 30,
  height = 31,
}: TicketIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 31"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/Ticket"}</title>
    <path
      d="M.41 19.903l2.746 2.747c.312.234.604.35.877.35.35 0 .642-.116.876-.35.39-.39.867-.585 1.432-.585s1.042.195 1.432.585c.39.35.584.818.584 1.402 0 .585-.194 1.072-.584 1.462-.546.545-.546 1.13 0 1.753l2.747 2.688c.662.663 1.285.721 1.87.175l17.24-17.24a1.19 1.19 0 00.351-.877c0-.311-.117-.604-.35-.876L26.883 8.39c-.624-.546-1.208-.546-1.754 0-.39.39-.867.584-1.431.584a1.947 1.947 0 01-1.432-.584c-.39-.35-.585-.818-.585-1.403 0-.584.195-1.071.585-1.46.545-.546.506-1.17-.117-1.871L19.403.909c-.623-.545-1.208-.545-1.753 0L.41 18.15c-.546.546-.546 1.13 0 1.754zm2.629-.877l6.896-6.896c.234.273.507.41.818.41.351 0 .643-.137.877-.41.624-.545.624-1.11 0-1.695l6.896-6.896 1.11 1.11c-.506.858-.69 1.812-.554 2.864.136 1.052.574 1.929 1.315 2.63.662.701 1.519 1.13 2.571 1.286 1.052.156 2.026-.02 2.922-.526l1.11 1.11-6.896 6.896c-.584-.623-1.15-.623-1.695 0-.623.546-.623 1.11 0 1.695l-6.896 6.897-1.11-1.11c.506-.858.691-1.813.555-2.865-.136-1.052-.575-1.928-1.315-2.63-.662-.7-1.52-1.13-2.571-1.285-1.052-.156-2.026.02-2.923.526l-1.11-1.11zm10.228-3.506l1.753 1.753c.234.234.526.35.876.35.351 0 .643-.116.877-.35.507-.584.507-1.169 0-1.753l-1.753-1.753c-.585-.507-1.17-.507-1.753 0-.507.584-.507 1.168 0 1.753z"
      fill={greyed ? "#aaaaaa" : "#1a1a1a"}
      fillRule="evenodd"
    />
  </svg>
);
