import { Market } from "cf-types";

export enum MarketActions {
  SET_CURRENT_MARKET = "set_current_market",
  SET_SELECTED_MARKET = "set_selected_market",
  SET_MARKETS = "set_markets",
}

interface MarketReturn {
  type: MarketActions;
  payload: Market[];
}

export const setMarkets = (payload: Market[]): MarketReturn => {
  return {
    type: MarketActions.SET_MARKETS,
    payload,
  };
};

export const setCurrentMarket = (payload: Market[]): MarketReturn => {
  return {
    type: MarketActions.SET_CURRENT_MARKET,
    payload,
  };
};

export const setSelectedMarket = (payload: Market[]): MarketReturn => {
  return {
    type: MarketActions.SET_SELECTED_MARKET,
    payload,
  };
};
