import { OptInDataSource } from "cf-constants";
import { OptIn } from "../../src/components/to-components";
import { OptInActions, OptInPayload } from "../actions";

type Action = {
  type: OptInActions;
  payload: OptInPayload;
};

export const initialState: {
  optInData: OptIn[];
} = {
  optInData: [OptInDataSource.latestOffers],
};

const OptInReducer = (state = initialState, { payload, type }: Action) => {
  switch (type) {
    case OptInActions.UPDATE_OPT_IN:
      const optInData = state.optInData.map((optIn) => {
        if (optIn.field === payload.field) {
          return { ...optIn, value: payload.value };
        }

        return optIn;
      });

      return { ...state, optInData };
    default:
      return state;
  }
};

export default OptInReducer;
