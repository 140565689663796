import { useState } from "react";
import styled from "styled-components";
import { TicketIcon } from "../../../../global/iconography/ticket/ticket-icon";
import { QRCodeModal } from "../qr-code-modal/qr-code-modal";

export interface OutingTicketProps {
  className?: string;
  ticketNumber: number;
  description: string;
  reference: string;
  barcodeUrl: string;
}

const unstyledModalBackground = ({ className, children }: any) => (
  <div className={className}>{children}</div>
);

const ModalBackGround = styled(unstyledModalBackground)`
  height: 100vh;
  width: 100vh;
  position: absolute;
  top: 182px;
  background-color: #fff;
`;

const unstyledOutingTicket = ({
  className,
  ticketNumber,
  description,
  reference,
  barcodeUrl,
}: OutingTicketProps) => {
  const [displayQRCodeModal, setDisplayQRCodeModal] = useState<boolean>(false);

  const onOpenModalCB = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    setDisplayQRCodeModal(true);
  };

  const onCloseModalCB = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    setDisplayQRCodeModal(false);
  };

  return (
    <>
      {displayQRCodeModal ? (
        <ModalBackGround>
          <QRCodeModal
            ticketNumber={ticketNumber}
            description={description}
            barcodeUrl={barcodeUrl}
            onCloseCB={onCloseModalCB}
          />{" "}
        </ModalBackGround>
      ) : (
        <div className={className}>
          <div className={`${className}-details`}>
            <div className={`${className}-ticket-number`}>
              <TicketIcon height={18} width={18} />
              <span>{`Ticket ${ticketNumber}`}</span>
            </div>
            <span className={`${className}-description`}>{description}</span>
            <span className={`${className}-reference`}>
              {`Reference: ${reference}`}
            </span>
          </div>
          <div className={`${className}-qr-code`}>
            <img src={barcodeUrl} />
            <a onClick={onOpenModalCB}>View</a>
          </div>
        </div>
      )}
    </>
  );
};

export const OutingTicket = styled(unstyledOutingTicket)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  div[class$="details"] {
    display: flex;
    flex-direction: column;
  }

  div[class$="ticket-number"] {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }

  div[class$="ticket-number"] > svg {
    display: none;
  }

  div[class$="ticket-number"] > span {
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: normal;
    color: #1a1a1a;
  }

  span[class$="description"] {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1a1a1a;
    margin-bottom: 15px;
  }

  span[class$="reference"] {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1a1a1a;
  }

  div[class$="qr-code"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: end;
  }

  div[class$="qr-code"] > a {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #d31a22;
    text-decoration: underline;
    cursor: pointer;
  }

  img {
    height: 50px;
    width: 50px;
  }

  @media (min-width: 1000px) {
    div[class$="ticket-number"] > svg {
      display: unset;
    }

    div[class$="ticket-number"] > span {
      margin-left: 10px;
    }

    span[class$="description"],
    span[class$="reference"] {
      margin-left: 28px;
    }

    img {
      height: 93px;
      width: 94px;
    }

    div[class$="qr-code"] > a {
      display: none;
    }
  }
`;
