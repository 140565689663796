import styled from "styled-components";

export interface FooterLinksItemsProps {
  text: string;
  link: string;
  omnitureTracking: { [key in string]: string | string[] };
  onClick?: () => void;
}

export interface FooterLinksProps {
  className?: string;
  footerLinksItems: Array<React.ReactElement>;
}

const footerLinksUnstyled = ({
  className,
  footerLinksItems,
}: FooterLinksProps) => <div className={className}>{footerLinksItems}</div>;

export const FooterLinks = styled(footerLinksUnstyled)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;

  a {
    text-decoration: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ececec;

    &:hover,
    &:focus {
      outline: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    column-gap: 131px;
    margin-right: 131px;
  }
`;
