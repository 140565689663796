import React from "react";
import classnames from "classnames";
import styled from "styled-components";

type Props = {
  className?: string;
  rotation?: string;
  size: "small" | "medium";
};

const sizeMap = {
  medium: {
    height: "3px",
    scale: 0.75,
    width: "calc(50% + 3px)",
  },
  small: {
    height: "4px",
    scale: 0.5,
    width: "calc(50% + 2.875px)",
  },
};

const UnstyledAnimatedChevron: React.FC<Props> = ({
  className,
  rotation,
  size,
}) => {
  return (
    <div className={className}>
      <span className={classnames(size, `${className}-caret`)} />
    </div>
  );
};

export const AnimatedChevron = styled(UnstyledAnimatedChevron)`
  span[class$="caret"] {
    cursor: pointer;
    display: inline-block;
    margin-left: 2px;
    padding: 10px;

    transform: ${({ rotation, size }) =>
      `scale(${sizeMap[size].scale}) rotate(${rotation})`};
  }

  span[class$="caret"]::before,
  span[class$="caret"]::after {
    position: absolute;
    bottom: 0;
    top: 0;

    content: "";
    display: block;
    margin: auto;
    height: ${({ size }) => sizeMap[size].height};
    width: ${({ size }) => sizeMap[size].width};

    background: #4a4a4a;

    transition: transform 0.2s cubic-bezier(0.8, 0.2, 0.5, 0.1);
  }

  span[class$="caret"]::before {
    left: 0;

    transform: rotate(45deg);
  }

  span[class$="caret"]::after {
    right: 0;

    transform: rotate(-45deg);
  }

  @media (min-width: 1025px) {
    span[class$="caret"]::before,
    span[class$="caret"]:after {
      background: #fff;
    }
  }
`;
