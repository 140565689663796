import { Analytics } from "cf-constants";
import { MetaDataContext } from "cf-context";
import { CollectionEvents } from "cf-types";
import { gtmDataLayerPush } from "cf-utils";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux/types";
import { useRouterEvent } from "src/hooks";
import {
  CategoryHeader,
  CategoryProductList,
} from "../../../src/components/to-components";
import CategoryPagePlaceholder from "./category-page-placeholder";

import styles from "styles/home.module.scss";

const limit = 40;
interface Props {
  adobePev2?: string;
  category: CollectionEvents["getCategoryEvents"];
  loading?: boolean;
  pageName?: string;
}

const CategoryPage: React.FC<Props> = ({
  adobePev2 = "selected ticket type",
  category,
  loading,
  pageName,
}) => {
  const router = useRouter();
  const [, setMetaData] = useContext(MetaDataContext);
  const [currentRoute, setCurrentRoute] = useState<string>(router.asPath);
  const [term, setSearchTerm] = useState<string>("");
  const pageTitle = useSelector(({ Page }: Store) => {
    return Page.pageName;
  });
  const selectedMarket = useSelector(({ Market }: Store) => {
    return Market?.selectedMarket[0];
  });

  const onRouteChangeStart = (url: string) => {
    setCurrentRoute(url);
  };

  useRouterEvent("routeChangeStart", onRouteChangeStart, [pageTitle]);

  const { items = [], url } = category;

  useEffect(() => {
    url && setMetaData(category);
  }, [url]);

  const {
    query: { event },
  } = router;

  const route = {
    query: {
      event,
      market: selectedMarket.slug,
    },
    url: currentRoute,
  };

  useEffect(() => {
    gtmDataLayerPush({ ecommerce: null });
    gtmDataLayerPush(
      {
        ecommerce: {
          [Analytics.GTMEcommerceAction.IMPRESSIONS]: items?.map(
            (item, idx) => ({
              id: item.eventGroupID,
              name: item.name.toLowerCase(),
              list: `${pageName ?? item.category} page`,
              category: `${item.category?.toLowerCase()}/${
                item.purchaseType
              }/${item.name.toLowerCase()}`,
              position: idx + 1,
            })
          ),
        },
      },
      selectedMarket.categories,
      route
    );
  }, [url, items]);

  const productSelectionEventData = {
    events: ["event235"],
    pev2: adobePev2,
  };

  const onProductClick = (index: number) => {
    const product = items?.[index] ?? {};

    gtmDataLayerPush({ ecommerce: null });
    gtmDataLayerPush(
      {
        event: Analytics.CustomGTMEvent.EC_PRODUCT_CLICK,
        ecommerce: {
          [Analytics.GTMEcommerceAction.CLICK]: {
            actionField: { list: `${pageName ?? product.category} page` },
            products: [
              {
                category: `${product.category.toLowerCase()}/${
                  product.purchaseType
                }/${product.name.toLowerCase()}`,
                id: product.eventGroupID,
                name: product.name.toLowerCase(),
                position: index + 1,
              },
            ],
          },
        },
      },
      selectedMarket.categories,
      route
    );
  };

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setSearchTerm(value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    router.push({
      pathname: "/search",
      query: { term },
    });
  };

  if (loading) {
    return <CategoryPagePlaceholder />;
  }

  return (
    <>
      <CategoryHeader
        title={category.headerTitle}
        subtitle={category.headerSubtitle}
        headerImageUrl={category.heroImage}
        placeholderText={"Search by event, area or venue"}
        searchTerm={term}
        onSubmit={onSubmit}
        onChange={onChange}
      />
      <div className={styles.products}>
        <CategoryProductList
          tracker={{ onProductClick }}
          limit={limit}
          categoryProducts={items}
          productSelectionEventData={productSelectionEventData}
        />
      </div>
    </>
  );
};

export default CategoryPage;
