export const TwitterLogo = () => (
  <svg
    width={19}
    height={15}
    viewBox="0 0 19 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"tw"}</title>
    <path
      d="M0 13.305C1.73 14.435 3.666 15 5.805 15c2.212 0 4.159-.559 5.842-1.677 1.682-1.117 2.926-2.482 3.732-4.092.805-1.61 1.208-3.281 1.208-5.012V3.75a7.74 7.74 0 001.875-1.983c-.601.288-1.323.493-2.164.613.817-.505 1.37-1.202 1.659-2.092-.673.41-1.479.71-2.416.902C14.796.397 13.87 0 12.764 0c-1.057 0-1.953.373-2.686 1.118-.733.745-1.1 1.634-1.1 2.668 0 .289.036.577.109.865C5.889 4.507 3.293 3.185 1.298.685c-.36.65-.54 1.286-.54 1.911 0 1.37.564 2.416 1.694 3.137-.577 0-1.142-.156-1.695-.469v.073c0 .913.289 1.712.866 2.397A3.851 3.851 0 003.786 9.05a4.484 4.484 0 01-1.01.109c-.312 0-.552-.024-.72-.072.264.769.72 1.4 1.37 1.893.649.492 1.382.739 2.199.739C4.279 12.8 2.705 13.34.901 13.34c-.408 0-.709-.012-.901-.036z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);
