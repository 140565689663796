import styled from "styled-components";
import { Colour } from "../../../types";

export interface PreviewTextProps {
  previewOnsaleTime: string;
}

export const PreviewText = ({ previewOnsaleTime }: PreviewTextProps) => (
  <DIV>
    <H3>Preview only</H3>
    <P>{`On sale from ${previewOnsaleTime}`}</P>
  </DIV>
);

const DIV = styled.div`
  text-align: center;
  font-family: FranklinGothicLTPro-DmCm;
  line-height: 10px;
`;

const H3 = styled.h3`
  font-size: 30px;
  color: ${Colour.ERROR};
`;

const P = styled.p`
  font-size: 18px;
`;
