import React, { useMemo } from "react";
import styled from "styled-components";
import { BasketItem, OptIn } from "../../../types";
import { calculateBookingFee, getReadableDate } from "../../../utils";
import { BookingFee, OptInRenderer } from "../view";
import { InputField } from "../input-field/input-field";
import { Button } from "../button/button";
import { OrderSummaryFooter } from "./order-summary-footer";

type PromocodeDetails = {
  name: string;
  discountPercentage: number;
  discountAmount: number;
};

const qa = "order-summary";

export interface Props {
  className?: string;
  currency: string;
  imageSrc: string | undefined;
  items: BasketItem[];
  onChangeOptIn: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditBasket: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onSubmit: () => void;
  onPromocode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPromocodeSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onResetPromocode: (e: React.MouseEvent<HTMLButtonElement>) => void;
  optInData: OptIn[];
  promocode: string;
  promocodeDetails: PromocodeDetails | null;
  promocodeError: boolean;
  purchaseType: string;
  privacyNoticeUrl: string;
  shopTermsUrl: string;
  termsUrl: string;
  title: string;
  totalPrice: string;
}

const UnstyledOrderSummaryWidget = ({
  className,
  currency,
  imageSrc,
  title,
  items,
  onChangeOptIn,
  onEditBasket,
  onPromocode,
  onPromocodeSubmit,
  onResetPromocode,
  onSubmit,
  promocode,
  optInData = [],
  promocodeDetails,
  promocodeError,
  privacyNoticeUrl,
  purchaseType,
  shopTermsUrl,
  termsUrl,
  totalPrice,
}: Props) => {
  const isPromoButtonActive = !promocodeError && !promocode;
  const { bookingFee, bookingFeeNumber, bookingFeeTotal } = useMemo(
    () => calculateBookingFee(items),
    [items]
  );

  return (
    <div className={className}>
      <div className={`${className}-header`}>
        <h2>Order Summary</h2>
        <a data-qa={`${qa}-edit-details`} onClick={onEditBasket}>
          Edit
        </a>
      </div>
      <div className={`${className}-vertical-scroll`}>
        <div className={`${className}-description`}>
          <img src={imageSrc} />
          <span className={`${className}-title`}>{title}</span>
        </div>
        <div className={`${className}-purchase-items`}>
          {items.map(({ item, quantity }, idx) => (
            <div className={`${className}-purchase-item--container`} key={idx}>
              {purchaseType === "attendance" && (
                <span className={`${className}-purchase-item--date`}>
                  {getReadableDate(item.startTime)}
                </span>
              )}
              <div className={`${className}-purchase-item`}>
                <div>
                  <span className={`${className}-purchase-item--price`}>
                    {`${quantity} x ${currency}${item.priceWithoutFee.toFixed(
                      2
                    )}`}
                  </span>
                  <span className={`${className}-purchase-item--description`}>
                    {item.seatDescription}
                  </span>
                </div>
                {`${currency}${(item.priceWithoutFee * quantity).toFixed(2)}`}
              </div>
            </div>
          ))}
          {!!bookingFeeNumber && (
            <div className={`${className}-booking-fee`}>
              <BookingFee
                bookingFee={`${currency}${bookingFee.toFixed(2)}`}
                bookingFeeNumber={bookingFeeNumber}
                bookingFeeTotal={`${currency}${bookingFeeTotal.toFixed(2)}`}
                termsUrl={termsUrl}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`${className}-promocode`}>
        <form>
          <InputField
            qa={`${qa}-promo`}
            onChange={onPromocode}
            placeholder={"Gift card or discount code"}
            disabled={!!promocodeDetails}
            value={promocode}
          />
          <Button
            className={
              !isPromoButtonActive && !promocodeDetails ? "active" : ""
            }
            onClick={onPromocodeSubmit}
            disabled={isPromoButtonActive}
            qa={`${qa}-promo`}
          >
            Apply
          </Button>
        </form>
        {promocodeError && promocode && (
          <span className={`${className}-promocode-error`}>
            Discount code is invalid or expired
          </span>
        )}
      </div>
      <div className={`${className}-opt-in-container`}>
        {optInData.map(({ field, text, value }) => {
          return (
            <div key={field} className={`${className}-opt-in-item`}>
              <OptInRenderer
                name={field}
                onChange={onChangeOptIn}
                text={text}
                value={value}
              />
            </div>
          );
        })}
      </div>
      <div className={`${className}-total-summary`}>
        {promocodeDetails && (
          <div className={`${className}-promocode-details`}>
            <span
              className={`${className}-promocode-details--name`}
            >{`${promocodeDetails.name} -${promocodeDetails.discountPercentage}%`}</span>
            <Button onClick={onResetPromocode} qa={`${qa}-remove`}>
              Remove
            </Button>
            <span
              className={`${className}-promocode-details--discounted`}
            >{`-${currency}${promocodeDetails?.discountAmount.toFixed(
              2
            )}`}</span>
          </div>
        )}
        <div className={`${className}-total-summary--text`}>
          <span>Total</span>
          <h2>{totalPrice}</h2>
        </div>
      </div>
      <OrderSummaryFooter
        onClick={onSubmit}
        privacyNoticeUrl={privacyNoticeUrl}
        shopTermsUrl={shopTermsUrl}
        termsUrl={termsUrl}
        totalPrice={totalPrice}
      />
    </div>
  );
};

export const OrderSummaryWidget = styled(UnstyledOrderSummaryWidget)`
  width: 100%;
  padding: 24px 30px;

  background-color: #ffffff;

  div[class$="header"] {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 20px;
  }

  h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: normal;
    color: #1a1a1a;
    margin: 0;
  }

  div[class$="header"] a,
  div[class$="promocode-details"] button {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    color: #7d7d7d;
    cursor: pointer;
    text-decoration: underline;
  }

  div[class$="promocode-details"] button {
    padding-bottom: 0;
    padding-top: 0;

    background-color: transparent;
  }

  div[class$="description"] {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  img {
    width: 75px;
    height: 56px;
    margin-right: 15px;
  }

  span[class$="title"] {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    color: #1a1a1a;
  }

  div[class$="vertical-scroll"] {
    max-height: 300px;

    overflow-y: auto;
  }

  div[class$="purchase-items"] {
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  div[class$="purchase-item--container"],
  div[class$="booking-fee"] {
    line-height: 32px;
  }

  div[class$="purchase-item--container"] {
    display: flex;
    flex-direction: column;
  }

  div[class$="purchase-item"] {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: Roboto;
    font-size: 14px;
  }

  span[class$="purchase-item--date"] {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: #1a1a1a;
    margin-bottom: 5px;
  }

  span[class$="purchase-item--description"] {
    color: #1a1a1a;
  }

  span[class$="purchase-item--price"] {
    word-spacing: 5px;
    min-width: 83px;
    margin-right: 16px;
  }

  div[class$="-promocode"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  div[class$="opt-in-container"] {
    padding-bottom: 15px;

    border-bottom: 1px solid #d8d8d8;
  }

  div[class$="opt-in-item"] {
    &:not(:first-of-type) {
      margin-top: 10px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  div[class$="-promocode"] form {
    height: 44px;
    display: flex;
    justify-content: space-between;
  }

  div[class$="-promocode"] input {
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    padding: 8px;
    border-width: 1px;
    border-color: #aaaaaa;
    text-transform: uppercase;
  }

  div[class$="-promocode"] button {
    height: 100%;
    width: 95px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.31;
    color: #fff;
    background-color: #aaa;
    border: none;
    cursor: not-allowed;
    margin-left: 10px;
  }

  div[class$="-promocode"] button.active {
    background-color: #4a4a4a;
    cursor: pointer;
  }

  span[class$="promocode-error"] {
    margin-top: 5px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    color: #d31a22;
  }

  div[class$="total-summary"] {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 15px;

    @media (max-width: 768px) {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }

  div[class$="promocode-details"] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  span[class$="promocode-details--name"] {
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.5;
    color: #1a1a1a;
  }

  span[class$="promocode-details--discounted"] {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #4a4a4a;
  }

  div[class$="total-summary--text"] {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1a1a1a;
    display: flex;
    justify-content: space-between;
  }

  div[class$="total-summary"] h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 32px;
    font-style: normal;
    line-height: 1.13;
    color: #1a1a1a;
  }

  div[class$="order-button"] {
    @media (max-width: 768px) {
      display: none;
    }
  }

  div[class$="order-summary--text"] {
    @media (min-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`;
