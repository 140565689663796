import styled from "styled-components";
import { Colour } from "../../../types";

interface Props {
  className?: string;
  children: React.ReactElement | string;
  color: Colour;
  isSelected?: boolean;
}

const UnstyledListRenderer: React.FC<Props> = ({ children, className }) => {
  return <span className={className}>{children}</span>;
};

export const ListRenderer = styled(UnstyledListRenderer)`
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;

  border-right-color: ${Colour.ERROR};
  border-right-width: 4px;
  border-right-style: ${({ isSelected = false }) =>
    isSelected ? "solid" : "none"};

  font-family: "Roboto";
  font-weight: ${({ isSelected = false }) => (isSelected ? "bold" : "normal")};
  font-size: 16px;

  a,
  button {
    color: ${({ color }) => color};

    text-decoration: none;
    line-height: 1.63;

    :hover {
      font-weight: bold;
    }
  }

  button {
    padding: 0;

    background-color: inherit;

    cursor: inherit;
  }
`;
