import styled from "styled-components";
import React, { useEffect } from "react";
import { Field } from "../../../../types";
import { PaymentGateway, PaymentGatewayInstance } from "../../../../utils";
import { Divider, DividerWithText } from "../../divider";
import { BillingAddress } from "./billing-address";
import { CardCVV } from "./card-cvv";
import { CardExpiry } from "./card-expiry";
import { CardName } from "./card-name";
import { CardNumber } from "./card-number";
import { fieldConfig, styles } from "./config/field-config";
import { State } from "./state";
import { TownCity } from "./town-city";
import { Postalcode } from "./postal-code";
import { ApplePayButton } from "../../../../index";

type FormFields = {
  billingAddress: string;
  cardholderName: string;
  cvv: null;
  expirationDate: null;
  number: null;
  postalCode: null;
  state: string;
  townCity: string;
};

export type Props<T = FormFields> = {
  [k in keyof T]: Field<T[k]>;
} & {
  authorization?: string;
  className?: string;
  is3dsSecEnabled?: boolean;
  isShippable?: boolean;
  onApplePaySubmit: any;
  onChangeCardholderName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTownCity: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeBillingAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPaymentGateway: (p: PaymentGatewayInstance) => void;
  staticTexts: { [k: string]: string };
  isApplePay: boolean;
};

const UnstyledPaymentForm: React.FC<Props> = ({
  authorization = "",
  billingAddress,
  cardholderName,
  className,
  cvv,
  expirationDate,
  is3dsSecEnabled = false,
  isApplePay = false,
  isShippable = false,
  number,
  onApplePaySubmit,
  onChangeCardholderName,
  onChangeTownCity,
  onChangeState,
  onChangeBillingAddress,
  onPaymentGateway,
  postalCode,
  staticTexts,
  state,
  townCity,
}) => {
  useEffect(() => {
    if (authorization) {
      (async () => {
        const paymentGateway = await PaymentGateway.init(authorization, {
          fields: fieldConfig,
          is3dsSecEnabled,
          styles,
        });
        onPaymentGateway(paymentGateway);
      })();
    }
  }, [authorization]);

  return (
    <form className={className}>
      <H3>Payment method</H3>
      {isApplePay ? (
        <>
          <Divider />
          <div className={`${className}-additional-payments`}>
            <ApplePayButton onClick={onApplePaySubmit} />
          </div>
          <DividerWithText text="Or buy with card" />
        </>
      ) : (
        <Divider />
      )}
      {is3dsSecEnabled && (
        <CardName
          error={cardholderName?.error}
          onChange={onChangeCardholderName}
          value={cardholderName?.value}
        />
      )}
      <CardNumber error={number?.error} />
      <div className={`${className}-horizontal`}>
        <CardExpiry error={expirationDate?.error} />
        <CardCVV error={cvv?.error} />
      </div>
      {is3dsSecEnabled && (
        <React.Fragment>
          {isShippable && (
            <React.Fragment>
              <BillingAddress
                error={billingAddress?.error}
                onChange={onChangeBillingAddress}
                value={billingAddress?.value}
              />
              <div className={`${className}-town-city-wrapper`}>
                <TownCity
                  error={townCity?.error}
                  onChange={onChangeTownCity}
                  value={townCity?.value}
                />
                <State
                  error={state?.error}
                  onChange={onChangeState}
                  value={state?.value}
                />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Postalcode
        error={postalCode?.error}
        postalCodeLabel={staticTexts.postalCodeLabel}
      />
    </form>
  );
};

const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 20px;

  font-family: "FranklinGothicLTPro-DmCm";
  font-size: 28px;
  line-height: 1.14;
  color: #1a1a1a;
`;

export const PaymentForm = styled(UnstyledPaymentForm)`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;

  background-color: #fff;

  hr {
    border-color: #d8d8d8;
  }

  div[class$="horizontal"],
  div[class$="town-city-wrapper"] {
    display: flex;
    margin-top: 30px;
  }

  div[class$="additional-payments"] {
    display: flex;
    justify-content: center;
    margin 40px; 0;
  }

  @media (max-width: 425px) {
    padding: 20px 15px;

    div[class$="town-city-wrapper"] {
      flex-direction: column;
    }
  }
`;
