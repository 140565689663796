import { Store } from "redux/types";
import { Notification, OnToast } from "../../src/components/to-components";

export enum NotificationActions {
  NOTIFY = "notify",
  SET_NOTIFIER = "set_notifier",
}

export type NotificationPayload = {
  notify: OnToast["onToast"];
};

export interface NotificationType {
  type: NotificationActions.NOTIFY | NotificationActions.SET_NOTIFIER;
  payload: NotificationPayload;
}

export const notify =
  (payload: Notification[]) => (_: unknown, getState: () => Store) => {
    const { Notification } = getState();
    Notification.notify(payload);
  };

export const setNotifier = (payload: NotificationPayload): NotificationType => {
  return {
    payload,
    type: NotificationActions.SET_NOTIFIER,
  };
};
