import React from "react";
import styled from "styled-components";

export interface Props {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  disablePadding?: boolean;
  onCollapse: (id: string) => void;
}

const UnstyledAccordion: React.FC<Props> = ({
  children,
  className,
  onCollapse,
}) => {
  const onToggle = (id: string) => (_: React.MouseEvent<HTMLButtonElement>) => {
    onCollapse && onCollapse(id);
  };

  return (
    <ul className={className}>
      {React.Children.map(children, (child: React.ReactElement) => {
        return React.cloneElement(child, {
          onToggle: onToggle(child.props.id),
        });
      })}
    </ul>
  );
};

export const Accordion = styled(UnstyledAccordion)`
  display: flex;
  flex-direction: column;
  padding-inline-start: ${({ disablePadding }) =>
    disablePadding ? "0" : "40px"};
`;
