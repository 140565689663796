import styled from "styled-components";
import { ArrowLeftIcon } from "../../../../global/iconography/arrows/arrow-icons";
import { MapPinIcon } from "../../../../global/iconography/map/map-pin-icon";
import { CalendarIcon } from "../../../../global/iconography/calendar/calendar-icon";
import { getReadableDate } from "../../../../utils/category-widget-utils";
import { OutingTicket } from "../outing-ticket/outing-ticket";

type Ticket = {
  description: string;
  reference: string;
  barcodeUrl: string;
};

export interface OutingDetailsProps {
  className?: string;
  name?: string;
  location?: string;
  date?: number;
  tickets?: Array<Partial<Ticket>>;
  onMyOrdersClickCB: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const unstyledOutingDetails = ({
  className,
  name,
  location,
  date,
  tickets = [],
  onMyOrdersClickCB,
}: OutingDetailsProps) => (
  <div className={className}>
    <div className={`${className}-return-link`}>
      <ArrowLeftIcon height={13} width={8} />
      <a onClick={onMyOrdersClickCB}>My orders</a>
    </div>
    <h2>{name}</h2>
    <div className={`${className}-location`}>
      <MapPinIcon height={18} width={13} />
      <span>{location}</span>
    </div>
    <div className={`${className}-date`}>
      <CalendarIcon height={14} width={16} />
      <span>{getReadableDate(date, true)}</span>
    </div>
    <hr />
    <div className={`${className}-tickets`}>
      {tickets?.map((ticket, idx) => {
        return (
          <div key={idx}>
            <OutingTicket
              key={idx}
              ticketNumber={idx + 1}
              barcodeUrl={ticket?.barcodeUrl ?? ""}
              description={ticket?.description ?? ""}
              reference={ticket?.reference ?? ""}
            />
            <hr />
          </div>
        );
      })}
    </div>
  </div>
);

export const OutingDetails = styled(unstyledOutingDetails)`
  display: flex;
  flex-direction: column;

  div[class$="return-link"] {
    display: flex;
    align-items: center;
  }

  div[class$="return-link"] path {
    fill: #d31a22;
  }

  div[class$="return-link"] a {
    margin-left: 11px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #d31a22;
    text-decoration: underline;
    cursor: pointer;
  }

  h2 {
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: normal;
    color: #1a1a1a;
    margin-top: 20px;
  }

  span {
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a;
  }

  div[class$="location"] > span {
    margin-left: 12px;
  }

  div[class$="date"] > span {
    margin-left: 10px;
  }

  div[class$="location"],
  div[class$="date"] {
    display: flex;
    align-items: center;
  }

  div[class$="location"] {
    margin-bottom: 10px;
  }

  hr {
    background-color: #d8d8d8;
    height: 1px;
    width: 100%;
    margin: 20px 0 0 0;
  }

  @media (min-width: 1000px) {
    h2 {
      margin-top: 30px;
    }

    hr {
      margin-top: 30px;
    }
  }
`;
