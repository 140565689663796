import React from "react";
import styled from "styled-components";
import { Divider } from "../../divider";
import { RecipientName } from "./recipient-name";
import { Address } from "./address";
import { TownCity } from "./town-city";
import { State } from "./state";
import { Zipcode } from "./zipcode";

type FormFields = {
  address: string;
  recipientName: string;
  state: string;
  townCity: string;
  zipcode: string;
};

type Field<T = string> = {
  error: boolean;
  value: T;
};

export type Props<T = FormFields> = {
  [k in keyof T]: Field<T[k]>;
} & {
  className?: string;
  onChangeAddress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRecipientName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTownCity: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeZipcode: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const UnstyledDeliveryAddressForm: React.FC<Props> = ({
  address,
  className,
  onChangeAddress,
  onChangeRecipientName,
  onChangeState,
  onChangeTownCity,
  onChangeZipcode,
  recipientName,
  state,
  townCity,
  zipcode,
}: Props) => {
  return (
    <form className={className}>
      <H3>Delivery address</H3>
      <Divider />
      <RecipientName
        error={recipientName?.error}
        onChange={onChangeRecipientName}
        value={recipientName?.value}
      />
      <Address
        error={address?.error}
        onChange={onChangeAddress}
        value={address?.value}
      />
      <div className={`${className}-horizontal`}>
        <TownCity
          error={townCity?.error}
          onChange={onChangeTownCity}
          value={townCity?.value}
        />
        <State
          error={state?.error}
          onChange={onChangeState}
          value={state?.value}
        />
      </div>
      <Zipcode
        error={zipcode?.error}
        onChange={onChangeZipcode}
        value={zipcode?.value}
      />
    </form>
  );
};

const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 20px;

  font-family: "FranklinGothicLTPro-DmCm";
  font-size: 28px;
  line-height: 1.14;
  color: #1a1a1a;
`;

export const DeliveryAddressForm = styled(UnstyledDeliveryAddressForm)`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;

  background-color: #fff;

  hr {
    border-color: #d8d8d8;
  }

  div[class$="horizontal"] {
    display: flex;
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    padding: 20px 15px;

    div[class$="horizontal"] {
      flex-direction: column;
    }
  }
`;
