import { Layers } from "cf-types";
import { Store } from "../types";

export enum ModalActions {
  DISMISS_MODAL = "dismiss_modal",
  RENDER_MODAL = "render_modal",
}

export type ModalPayload = {
  component?: React.ReactElement;
  layer?: Layers;
  onClose?: (store?: Store) => void;
  render: boolean;
};

export interface ModalType {
  type: ModalActions.RENDER_MODAL | ModalActions.DISMISS_MODAL;
  payload?: ModalPayload;
}

export const renderModal = (payload: ModalPayload): ModalType => {
  return {
    payload,
    type: ModalActions.RENDER_MODAL,
  };
};

export const dismissModal =
  () => (dispatch: (p: ModalType) => void, getState: () => Store) => {
    const store = getState();

    store.Modal.onClose && store.Modal.onClose(store);
    dispatch({ type: ModalActions.DISMISS_MODAL });
  };
