import { gql } from "@apollo/client";

export const GetOutingsQuery = gql`
  query OutingsQuery($outingsData: String!) {
    getOutings(outingsData: $outingsData) {
      error {
        fields
        message
        name
        stack
        status
      }
      data {
        name
        ticketCount
        purchaseType
        startTime
        resourceID
        location {
          venueName
          address1
          address2
          area
          postCode
        }
        ticketGroups {
          startTime
          tickets {
            barcodeUrl
            reference
            description
          }
        }
      }
    }
  }
`;
