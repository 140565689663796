import { Analytics } from "cf-constants";
import { MetaDataContext } from "cf-context";
import { GoogleTagManager, HttpStatusCode, Market } from "cf-types";
import {
  AWINtracker,
  cookieUtils,
  debounceFunc,
  gtmDataLayerPush,
} from "cf-utils";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux/types";
import { CookieBanner, TCFData } from "../../../src/components/to-components";
import { useRouterEvent } from "../../hooks";

const errorRouteRegex = new RegExp(
  `${HttpStatusCode.NOT_FOUND}|${HttpStatusCode.INTERNAL_SERVER_ERROR}`
);
const debounceTime = 1500;

const isCheckoutPage = (url: string) => url.match(/checkout/);
const runAWIN = (url: string) => {
  try {
    const gaveConsent = JSON.parse(cookieUtils.getCookie("consent") || "false");
    if (gaveConsent && !isCheckoutPage(url)) {
      AWINtracker.run();
    }
  } catch (error) {
    // Do Nothing
  }
};

type SendPageView = [string, Market, GoogleTagManager.Route];

const sendPageView = debounceFunc<SendPageView>(
  (pageTitle, { categories }, { url, query }) => {
    gtmDataLayerPush(
      {
        event: Analytics.CustomGTMEvent.TO_PAGE_VIEW,
        payload: {
          pageTitle: pageTitle.toLowerCase(),
        },
      },
      categories,
      { url, query }
    );
  },
  debounceTime
);

const getConsentStatus = ({ purpose }: TCFData): string => {
  const consents = Object.values(purpose?.consents ?? {});
  return String(consents.some((value) => value));
};

const Tracking: React.FC = () => {
  const router = useRouter();
  const [{ overrideMetaTitle }] = useContext(MetaDataContext);
  const [consent, setConsent] = useState<TCFData>({});
  const [currentRoute, setCurrentRoute] = useState<string>(router.asPath);
  const { pageName } = useSelector(({ Page }: Store) => {
    return Page;
  });
  const selectedMarket = useSelector(({ Market }: Store) => {
    return Market?.selectedMarket[0];
  });

  const route = {
    query: {
      event: router.query?.event,
      market: selectedMarket.slug,
    },
    url: currentRoute,
  };

  const onRouteChangeStart = (url: string) => {
    runAWIN(url);
    setCurrentRoute(url);
  };

  // Send Page view data when available
  useEffect(() => {
    overrideMetaTitle &&
      currentRoute &&
      sendPageView(overrideMetaTitle, selectedMarket, route);
  }, [currentRoute, overrideMetaTitle]);

  // Send quantcast consent status only if user has interacted with modal
  useEffect(() => {
    const consentCookieModal = cookieUtils.getCookie("euconsent-v2");
    consentCookieModal &&
      gtmDataLayerPush(
        {
          payload: {
            quantcastConsent: getConsentStatus(consent),
          },
        },
        selectedMarket.categories,
        route
      );
  }, [consent]);

  // Send custom GA event if consent modal is in view.
  useEffect(() => {
    const consentCookie = cookieUtils.getCookie("euconsent-v2");
    !consentCookie &&
      gtmDataLayerPush(
        {
          event: Analytics.CustomGTMEvent.TO_COOKIE_CONSENT,
        },
        selectedMarket.categories,
        route
      );
  }, []);

  useEffect(() => {
    const value = getConsentStatus(consent);
    cookieUtils.setCookie({ name: "consent", value });

    if (value === "true" && !router.pathname.match(errorRouteRegex)) {
      router.push(router.asPath, "", { shallow: true });
    }
  }, [consent]);

  useRouterEvent("routeChangeStart", onRouteChangeStart, []);

  return <CookieBanner onConsent={setConsent} />;
};

export default Tracking;
