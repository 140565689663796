import React from "react";
import { IconsProps } from "../icons-types";
import styled from "styled-components";

export const ArrowDownIcon = ({
  darkMode = false,
  height = 18,
  width = 11,
}: IconsProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 13 18"
  >
    <title>{"Icons/Arrows/Down"}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-181.000000, -165.000000)"
        fill={darkMode ? "#ffffff" : "#1a1a1a"}
      >
        <g
          id="Atoms/Icons/Arrows/Down"
          transform="translate(181.000000, 165.000000)"
        >
          <polygon
            id="Icons/Arrow/Down"
            points="5.34099026 13.0340097 5.34099026 0 7.59099026 0 7.59099026 13.0340097 11.3409903 9.28400974 12.9319805 10.875 6.46599026 17.3409903 0 10.875 1.59099026 9.28400974"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const ArrowLeftIcon = ({
  darkMode = false,
  height = 18,
  width = 11,
}: IconsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/Chevron/Left"}</title>
    <path
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      d="M10.743 15.121L8.62 17.243 0 8.62 8.621 0l2.122 2.121-6.5 6.5z"
      fillRule="evenodd"
    />
  </svg>
);

export const ArrowRightIcon = ({
  darkMode,
  height = 18,
  width = 11,
}: IconsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/Chevron/Right"}</title>
    <path
      fill={darkMode ? "#ffffff" : "#1a1a1a"}
      d="M0 15.121l2.121 2.122 8.622-8.622L2.12 0 0 2.121l6.5 6.5z"
      fillRule="evenodd"
    />
  </svg>
);

export const SelectionArrowIconUp = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #4a4a4a;
  right: 3px;
  top: 22px;
`;
