import React from "react";
import styled from "styled-components";

export interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  form?: string;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  qa?: string;
  title?: string;
  tracker?: { [key: string]: string };
  type?: "button" | "submit" | "reset";
}

const UnstyledButton: React.FC<Props> = ({
  children,
  className,
  disabled,
  form,
  id,
  onClick,
  qa,
  title,
  tracker,
  type,
}: Props) => {
  const props = {
    ...tracker,
    children: children || title,
    className,
    "data-qa": `${qa}-Button`,
    disabled,
    form,
    id,
    onClick,
    type,
  };

  return <button {...props} />;
};

export const Button = styled(UnstyledButton)`
  padding-top: 12px;
  padding-bottom: 12px;

  line-height: 1.5;
  border: transparent;

  font-family: "Roboto";

  :disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;
